/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */

import React from "react";
import { CacheKey, IOrgGroup, useHapAuth, UserManagementSvc } from "@nuance/hap-components";
import { Field, FieldTypes, FormPanel } from "../components/FormPanel";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { getLogger } from "@nuance/hux-diagnostics";
import { EventTypes } from "../EventTypes";
import * as Yup from "yup";

const logger = getLogger();

/**
 * Interface for EditOrgGroupBasicInfoPanel
 */
export interface IEditOrgGroupBasicInfoPanelProps {
    orgGroup: IOrgGroup;
    onDismiss: () => void;
}

/**
 *
 * @param props IEditOrgGroupBasicInfoPanelProps
 * @returns JSX.Element
 */
export const EditOrgGroupBasicInfoPanel = (props: IEditOrgGroupBasicInfoPanelProps) => {
    const { t } = useTranslation();
    const { userContext } = useHapAuth();
    const userManagementSvc = new UserManagementSvc(userContext);
    const queryClient = useQueryClient();

    /** orgGroup name field */
    const fields: Field<{ groupName: string }>[] = [
        {
            id: "groupName",
            label: t("Groups.Group_Name.Label"),
            type: FieldTypes.Text,
            required: true
        }
    ];

    /**
     * Validation Schema for formik
     */
    const validationSchema = Yup.object({
        groupName: Yup.string()
            .required(t("Error.Validation.Field_Required"))
            .max(25, t("Error.Validation.Exceed_Max_Length_Characters", { maxCharacters: 25 }))
            .matches(
                /^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$/,
                t("Error.Validation.Has_Leading_Or_Trailing_Spaces", {
                    inputName: t("Groups.Group_Name.Label")
                })
            )
    });

    return (
        <FormPanel
            saveAction={async data => {
                return userManagementSvc
                    .updateOrgGroup(props.orgGroup.OrgGroupId, data.groupName)
                    .execute2();
            }}
            onSuccess={(_, variables) => {
                if (variables) {
                    queryClient.setQueryData(["orgGroup", props.orgGroup.OrgGroupId], {
                        ...props.orgGroup,
                        OrgGroupName: variables.groupName
                    });
                }
                /** Invalidate all get orgGroups calls so if user navigates back to user page changes to orgGroup name will be displayed */
                queryClient.invalidateQueries({ queryKey: [CacheKey.getOrgGroups] });
                queryClient.invalidateQueries({ queryKey: [CacheKey.getOrgGroupsByOrg] });
                logger.trackEvent(EventTypes.OrgGroupNameChanged, { ...props.orgGroup });
            }}
            onDismiss={props.onDismiss}
            header={t("Groups.BasicInfo.Edit_Basic_Info_Title")}
            validationSchema={validationSchema}
            initialValues={{ groupName: props.orgGroup.OrgGroupName }}
            fields={fields}
        />
    );
};
