/**
 * @copyright (C) Microsoft Corporation.
 * All Rights Reserved.
 */
import { IHapUserContext, NmsGrant } from "@nuance/hap-components";

export function doesUserHaveGrantsToViewUserImport(userContext: IHapUserContext) {
    // (SuperUser OR SuperUserReadOnly) OR (Create user AND Modify user)
    return (
        userContext.isSuperUser() ||
        userContext.hasGrant(NmsGrant.SuperUserReadOnly) ||
        ((userContext.hasGrant(NmsGrant.CreateUsersInAllGroupsInAllOrganizations) ||
            userContext.hasGrant(NmsGrant.CreateUserInAllGroups) ||
            userContext.hasGrant(NmsGrant.CreateUser)) &&
            (userContext.hasGrant(NmsGrant.ModifyUsersInAllGroupsInAllOrganizations) ||
                userContext.hasGrant(NmsGrant.ModifyUserInAllGroups) ||
                userContext.hasGrant(NmsGrant.ModifyUser)))
    );
}
