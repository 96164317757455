/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import {
    Text,
    DefaultButton,
    Stack,
    FontWeights,
    IStackTokens,
    Toggle,
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { NmsPrivilege, useHapAuth } from "@nuance/hap-components";
import { IUserInfo } from "@nuance/hap-components/lib/components/auth/HapUserContext/IUserInfo";
import { formatUserName, HuxFeatureSet, UserNameFormat } from "@nuance/hux-components";
import { isPreviewModeOn } from "../common/NccFeature/appFeatureSet";
import { useTranslation } from "react-i18next";
import { PREVIEW_MODE_STORAGE_KEY } from "../NCCConstants";
import { useAppConfig } from "../services/AppConfigSvc";

/**
 * Profile callout props
 */
export interface ProfileCalloutProps {
    /**
     * Callback on preview change
     */
    onPreviewChange: () => void;
}

const stackTokens: IStackTokens = {
    padding: "10px 20px",
    childrenGap: "16px"
};

const modalProps = {
    isBlocking: true
};

export const ProfileCallout = (props: ProfileCalloutProps): JSX.Element => {
    const { userContext, logout } = useHapAuth();
    const { t } = useTranslation();
    const { data: appConfig } = useAppConfig();
    const [isConfirmOpen, { toggle: toggleIsConfirmOpen }] = useBoolean(true);
    const previewModeOn = isPreviewModeOn(appConfig?.featureSet);

    async function signOutClick() {
        logout(`${window.location.origin}/logout`);
    }

    const user: IUserInfo = userContext.user;
    const userName = { FirstName: user.firstName, LastName: user.lastName };

    const closeConfirmDialog = () => {
        toggleIsConfirmOpen();
        props.onPreviewChange();
    };

    // only show preview mode toggle if user has privileges use
    const shouldShowPreviewModeToggle =
        userContext.isNuanceOrg ||
        userContext.hasPrivilege(NmsPrivilege.NCCBetaUser) ||
        userContext.hasPrivilege(NmsPrivilege.NCCEarlyAdopter);

    return (
        <>
            <Stack tokens={stackTokens} role="dialog">
                <Stack.Item>
                    <Text
                        block
                        variant="large"
                        style={{ fontSize: "20px", fontWeight: FontWeights.bold }}
                    >
                        {formatUserName(userName, UserNameFormat.FL)}
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <Text block variant="small" style={{ fontSize: "14px" }}>
                        {userContext.user.login}
                    </Text>
                </Stack.Item>
                {appConfig?.featureSet !== HuxFeatureSet.Developer &&
                    shouldShowPreviewModeToggle && (
                        <Stack.Item>
                            <Toggle
                                label={t("Profile.Preview.New_Features_Button")}
                                onText={t("Profile.Preview.On_Text")}
                                offText={t("Profile.Preview.Off_Text")}
                                defaultChecked={previewModeOn}
                                onChange={() => {
                                    if (previewModeOn) {
                                        localStorage.removeItem(PREVIEW_MODE_STORAGE_KEY);
                                    } else {
                                        localStorage.setItem(
                                            PREVIEW_MODE_STORAGE_KEY,
                                            true.toString()
                                        );
                                    }
                                    toggleIsConfirmOpen();
                                }}
                            />
                        </Stack.Item>
                    )}
                <Stack.Item style={{ alignSelf: "center" }}>
                    <DefaultButton onClick={signOutClick}>
                        {t("Profile.Sign_Out_Button")}
                    </DefaultButton>
                </Stack.Item>
            </Stack>
            <Dialog
                hidden={isConfirmOpen}
                onDismiss={() => {
                    closeConfirmDialog();
                }}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: previewModeOn
                        ? t("Profile.Preview.Turning_On_Title")
                        : t("Profile.Preview.Turning_Off_Title"),
                    subText: previewModeOn
                        ? t("Profile.Preview.Turning_On_Text")
                        : t("Profile.Preview.Turning_Off_Text")
                }}
                modalProps={modalProps}
                minWidth={600}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            closeConfirmDialog();
                        }}
                        text={t("Form.OK_Button")}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
};
