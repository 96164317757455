/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */

import React from "react";
import { TwoColumnLayout } from "../layouts/TwoColumnLayout";
import { IGetUsersSearchParameters, IOrganization, IOrgGroup } from "@nuance/hap-components";
import { UserListComponent } from "../components/UserListComponent";
import { OrgGroupBasicInfo } from "./OrgGroupBasicInfo";
import { useTranslation } from "react-i18next";
import { HuxSection } from "@nuance/hux-components";

/**
 * Interface for OrgGroupOverviewTabProps
 */
export interface IOrgGroupOverviewTabProps {
    orgGroup: IOrgGroup;
    org: IOrganization;
}

/**
 *
 * @param props IOrgGroupOverviewTabProps
 * @returns JSX.Element
 */
export const OrgGroupOverviewTab = (props: IOrgGroupOverviewTabProps) => {
    const { t } = useTranslation();
    const include: IGetUsersSearchParameters = {
        groupUID: props.orgGroup.OrgGroupId,
        skip: 0,
        take: 100
    };

    return (
        <TwoColumnLayout
            MainContent={
                <HuxSection heading={t("Groups.MembersTable.Pagination_Label")}>
                    <UserListComponent
                        userRequestParameters={include}
                        label={t("Groups.MembersTable.Pagination_Label")}
                        showEnrollmentCommandsForGroup={props.orgGroup}
                        includeSelectionCount
                    />
                </HuxSection>
            }
            RightRail={<OrgGroupBasicInfo orgGroup={props.orgGroup} org={props.org} />}
        ></TwoColumnLayout>
    );
};
