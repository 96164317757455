/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

export enum provisioningTypes {
    auto = "auto",
    user = "user",
    all = "all"
}
