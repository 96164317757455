/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import { useEffect, useState } from "react";
const getReturnValues = (count: number) => {
    const minutes = Math.floor(count / 60);
    const seconds = count % 60;

    return [minutes, seconds];
};
export function useTimer(start: boolean, seconds: number) {
    const [count, setCount] = useState(seconds);

    useEffect(() => {
        if (start) {
            const timerInterval = setInterval(() => {
                setCount(c => (c > 0 ? c - 1 : 0));
            }, 1000);
            return () => clearInterval(timerInterval);
        }
    }, [start]);

    return getReturnValues(count);
}
