/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import React, { useContext, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { useTranslation } from "react-i18next";
import { Callout, DirectionalHint } from "@fluentui/react";
import {
    HuxFeatureContext,
    HuxTitleBar,
    HuxTitleBarButtonType,
    IHuxTitleBarProps
} from "@nuance/hux-components";
import { useNavigate } from "react-router-dom";
import { ProfileCallout } from "./ProfileCallout";
import { getLogger } from "@nuance/hux-diagnostics";
import { EventTypes } from "../EventTypes";
import { useHapAuth } from "@nuance/hap-components";
import { NccFeatures } from "../common/NccFeature/NccFeatures";

/**
 * HuxTitleBar setup for the NCC application
 *
 * For every new button added:
 * - add a useBoolean toggle for the button
 * - add the button to the HuxTitleBarProps using the toggle hook
 * - add the case inside the titleBarButtonClickHandler
 * - if needed, add the returned callout contents in getCalloutContent
 * - if needed, add the case inside the onDismiss function
 * @returns JSX Element
 */
export interface NCCTitleBarProps {
    /**
     * true if user has been authenticated
     */
    isAuthenticated?: boolean;
    onClickHandler?: () => void;
}
export const NCCTitleBar = (props: NCCTitleBarProps): JSX.Element => {
    const navigate = useNavigate();
    const { userContext } = useHapAuth();
    const { t } = useTranslation();
    const logger = getLogger();

    const { settings: huxFeatureSettings } = useContext(HuxFeatureContext);

    /**
     * Toggle for Profile button, each button needs a toggle hooked to HuxTitleBarProps
     */
    const [
        isProfileChecked,
        { toggle: toggleIsProfileChecked, setFalse: turnOffIsProfileChecked }
    ] = useBoolean(false);

    /**
     * Hook containing the info for the currently visible callout. Key val and targetId val
     */
    const [currentlyCheckedInfo, setCurrentlyCheckedInfo] = useState({
        key: "",
        targetId: ""
    });

    /**
     * Returns the contents of the callout for the selected button
     *
     * @param key the currently selected button
     * @returns JSX.Element
     */
    const getCalloutContent = (key: HuxTitleBarButtonType | string): JSX.Element | undefined => {
        switch (key) {
            case HuxTitleBarButtonType.Profile:
                return (
                    <ProfileCallout
                        onPreviewChange={() => {
                            setCurrentlyCheckedInfo(val => {
                                val.key = "";
                                return { ...val };
                            });
                            turnOffIsProfileChecked();
                            // replacing navigate with window.location.assign to avoid too many hooks error caused by HuxTitleBar
                            window.location.assign("/");
                        }}
                    />
                );
        }
    };

    /**
     * Handles what should be done based on which button was clicked
     *
     * @param key key of the button that was just clicked
     * @param buttonId the unique id for the clicked button
     */
    const titleBarButtonClickHandler = (
        key: HuxTitleBarButtonType | string,
        buttonId?: string
    ): void => {
        switch (key) {
            case HuxTitleBarButtonType.SkipToMainContent:
                if (props.onClickHandler !== undefined) {
                    props.onClickHandler();
                }
                break;
            case HuxTitleBarButtonType.Home:
                navigate("/");
                break;
            case HuxTitleBarButtonType.Profile:
                toggleIsProfileChecked();
                if (currentlyCheckedInfo.key === HuxTitleBarButtonType.Profile) {
                    setCurrentlyCheckedInfo(val => {
                        val.key = "";
                        return { ...val };
                    });
                } else
                    setCurrentlyCheckedInfo(val => {
                        val.key = key;
                        buttonId ? (val.targetId = buttonId) : (val.targetId = "");

                        return { ...val };
                    });

                break;
            case HuxTitleBarButtonType.Help:
                logger.trackEvent(EventTypes.OnlineHelpClicked, {
                    orgUid: userContext.organization.uid
                });
                window.open("/help/Default.htm", "help");
                break;
        }
    };

    const buttons = {
        Profile: {
            visible: true,
            isChecked: isProfileChecked
        },
        Help: {
            visible: huxFeatureSettings.isAllowed(NccFeatures.Help)
        }
    };

    const titleBarLogoTextKey = "Product.Nuance_Command_Center";
    const titleBarProps: IHuxTitleBarProps = {
        applicationName: t("Product.Nuance_Command_Center"),
        leftSection: {
            logo: {
                text: t(titleBarLogoTextKey) || titleBarLogoTextKey
            }
        },
        buttons: props.isAuthenticated ? buttons : undefined,
        onClickHandler: titleBarButtonClickHandler
    };

    /**
     * When the callout is visible and a mouse click occurs this function is called
     * it toggles that button's visible status and set's the currently visible hook to ""
     * @param key the button type of the currently visible callout
     */
    const onDismiss = (key: HuxTitleBarButtonType | string) => {
        switch (key) {
            case HuxTitleBarButtonType.Profile:
                toggleIsProfileChecked();
                break;
        }
        setCurrentlyCheckedInfo(val => {
            val.key = "";
            return { ...val };
        });
    };

    return (
        <div role="banner">
            <HuxTitleBar {...titleBarProps}></HuxTitleBar>
            {currentlyCheckedInfo.key !== "" && (
                <Callout
                    target={`#${currentlyCheckedInfo.targetId}`}
                    isBeakVisible={false}
                    directionalHint={DirectionalHint.bottomRightEdge}
                    dismissOnTargetClick={false}
                    onDismiss={() => {
                        onDismiss(currentlyCheckedInfo.key);
                    }}
                    setInitialFocus={true}
                >
                    {getCalloutContent(currentlyCheckedInfo.key)}
                </Callout>
            )}
        </div>
    );
};
