/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */

import React, { useRef, useState, useEffect, useContext } from "react";
import {
    useHapAuth,
    IGetUsersSearchParameters,
    HapOrganizationDropdown,
    NmsGrant,
    CacheKey,
    IUser,
    HapAuthType,
    NmsPrivilege
} from "@nuance/hap-components";
import {
    mergeStyles,
    Spinner,
    SpinnerSize,
    PrimaryButton,
    Text,
    IButton,
    CommandBarButton
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import {
    HuxPageHeader,
    IHuxPinListItem,
    HuxSearchWithOptions,
    IHuxQueryItem,
    StringTokenizerData,
    IHuxDetailsListColumn,
    IHuxDetailsListItem,
    HuxFeatureContext,
    IHuxPageActionProps
} from "@nuance/hux-components";
import { UserListComponent } from "../components/UserListComponent";
import { useQueryClient } from "@tanstack/react-query";
import { getLogger } from "@nuance/hux-diagnostics";
import { EventTypes } from "../EventTypes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { isValidGuid } from "@nuance/hux-diagnostics";
import { useBoolean } from "@fluentui/react-hooks";
import { AddUserPanel, IAddUserPanelProps } from "../groups/users/AddUserPanel";
import { AddUserConfirmDialog, NewUserAdded } from "../groups/users/AddUserConfirmDialog";
import UserGrantLicensesPanel from "./UserGrantLicensesPanel";
import { renderUserStatus } from "../components/UserStatus";
import { AddServicePrincipalPanel } from "./AddServicePrincipalPanel";
import { NccFeatures } from "../common/NccFeature/NccFeatures";
import { doesUserHaveGrantsToViewUserImport } from "../common/NccFeaturePermissionChecks";

const logger = getLogger();

interface IStoredUserSearchData {
    parameters: IGetUsersSearchParameters;
    string: string;
    organization?: IHuxPinListItem;
    tokenData: StringTokenizerData;
}

/**
 * Page allowing a user to search for other users based on certain parameters
 *
 * @returns JSX.Element
 */
export const UserSearchPage = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const searchUrlGuid = searchParams.get("searchParams");

    let searchUrlParams: null | string = null;
    let searchDataParsed: IStoredUserSearchData | undefined;

    /** check to see if url has searchParams and it is a valid Guid */
    if (searchUrlGuid !== null && isValidGuid(searchUrlGuid)) {
        searchUrlParams = sessionStorage.getItem(searchUrlGuid);

        /** If session storage returns null everything will render empty/undefined */
        if (searchUrlParams !== null) {
            searchDataParsed = JSON.parse(searchUrlParams);
        }
    }

    const { userContext, authType } = useHapAuth();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const searchButtonRef = useRef<IButton>(null);
    const { settings: huxFeatureSettings } = useContext(HuxFeatureContext);

    /** Selected org, if undefined searches all orgs */
    const [selectedOrg, setSelectedOrg] = useState<IHuxPinListItem | undefined>(
        searchDataParsed?.organization
    );
    /** isLoading for api call from UserListComponent callback */
    const [isLoading, setIsLoading] = useState(false);
    /** If Search button is disabled */
    const [searchDisabled, setSearchDisabled] = useState(true);
    /** Search input val */
    const [searchTokensVal, setSearchTokensVal] = useState<StringTokenizerData | undefined>(
        searchDataParsed?.tokenData
    );
    /** The string value currently in the searchBox */
    const [searchString, setSearchString] = useState(searchDataParsed?.string);
    const [isAddUserPanelOpen, { toggle: toggleIsAddUserPanelOpen }] = useBoolean(false);
    const [isAddServicePrincipalPanelOpen, { toggle: toggleIsAddServicePrincipalPanelOpen }] =
        useBoolean(false);
    const [isAddUserConfirmDialog, { toggle: toggleIsAddUserConfirmDialog }] = useBoolean(false);
    const [newUserAdded, setNewUserAdded] = useState<NewUserAdded>();
    const [userToAssignLicense, setUserToAssignLicense] = useState<IUser>();
    const [servicePrincipalAdded, setServicePrincipalAdded] = useState(false);
    const [
        isAddAnotherUser,
        { setTrue: setTrueIsAddAnotherUser, setFalse: setFalseIsAddAnotherUser }
    ] = useBoolean(false);
    const [isAssignLicensesPanelOpen, { toggle: toggleIsAssignLicensesPanelOpen }] =
        useBoolean(false);

    useEffect(() => {
        document.title = t("Users.User_Search_Page_Title");
    }, []);

    /** This updates the state variables values upon the user pressing the back button, otherwise, they retain the old values */
    useEffect(() => {
        if (searchDataParsed) {
            setSelectedOrg(searchDataParsed.organization);
            setSearchTokensVal(searchDataParsed.tokenData);
            setSearchString(searchDataParsed.string);
        } else {
            setSelectedOrg(undefined);
            setSearchTokensVal(undefined);
            setSearchString(undefined);
        }
    }, [searchUrlGuid]);

    /** Avoiding Fluent Stack since going away in Fluent 9 */
    const horizontalStackStyle = {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        gap: 16,
        flexWrap: "wrap"
    };

    /** Style for search components container */
    const usersSearchContainerStyles = mergeStyles(horizontalStackStyle, {
        margin: 24
    });

    /** Actions that take place when a user Searches */
    const onSearch = () => {
        /** remove focus from search box (if using enter key) */
        searchButtonRef.current?.focus();
        /** Disabled search button */
        setSearchDisabled(true);

        /** Set the search parameters */
        const getUsersSearchParameters = {
            ...searchTokensVal,
            organizationUID: selectedOrg?.uid,
            skip: 0 /** Initial value, UserListComponent will handle pagination */,
            take: 100
        };

        /** If the desired data is already cached the spinner won't show */
        if (
            queryClient.getQueriesData({ queryKey: [CacheKey.getUsers, getUsersSearchParameters] })
                .length === 0
        ) {
            setIsLoading(true);
        }

        /** create a Guid for the search */
        const searchGuid = uuidv4();

        /** set the search parameters to correlate with the Guid */
        sessionStorage.setItem(
            searchGuid,
            JSON.stringify({
                parameters: getUsersSearchParameters,
                string: searchString,
                organization: selectedOrg,
                tokenData: searchTokensVal
            })
        );

        /** set the searchParams Guid to the url */
        setSearchParams({ searchParams: searchGuid });

        logger.trackEvent(EventTypes.UserSearchInitiated, { ...getUsersSearchParameters });
    };

    const queryOptions: IHuxQueryItem[] = [
        {
            key: "login",
            prefix: t("Users.Search.Filter.Login_Key"),
            description: t("Users.Search.Filter.Login_Text")
        },
        {
            key: "emailAddress",
            prefix: t("Users.Search.Filter.Email_Key"),
            description: t("Users.Search.Filter.Email_Text")
        },
        {
            key: "lastName",
            prefix: t("Users.Search.Filter.Last_Key"),
            description: t("Users.Search.Filter.Last_Text")
        },
        {
            key: "firstname",
            prefix: t("Users.Search.Filter.First_Key"),
            description: t("Users.Search.Filter.First_Text")
        },
        {
            key: "npi",
            prefix: t("Users.Search.Filter.NPI_Key"),
            description: t("Users.Search.Filter.NPI_Text")
        },
        {
            key: "siteName",
            prefix: t("Users.Search.Filter.Site_Key"),
            description: t("Users.Search.Filter.Site_Text")
        },
        {
            key: "groupName",
            prefix: t("Users.Search.Filter.Group_Key"),
            description: t("Users.Search.Filter.Group_Text")
        }
    ];

    const addUserClickHandler = () => {
        logger.trackEvent(EventTypes.UserSearchPageAddUserAccountButtonClicked);
        toggleIsAddUserPanelOpen();
        setFalseIsAddAnotherUser();
    };

    const addServicePrincipalClickHandler = () => {
        logger.trackEvent(EventTypes.UserSearchPageAddServicePrincipleButtonClicked);
        toggleIsAddServicePrincipalPanelOpen();
    };

    const canAddServicePrincipal =
        authType === HapAuthType.entraid &&
        huxFeatureSettings.isAllowed(NccFeatures.AddServicePrincipal) &&
        userContext.hasPrivilege(NmsPrivilege.ManageServicePrinciple);

    const buttonMenuProps = canAddServicePrincipal
        ? {
              items: [
                  {
                      key: "addUser",
                      text: t("Users.Add.Add_User_Option"),
                      onClick: addUserClickHandler
                  },
                  {
                      key: "addServicePrincipal",
                      text: t("Users.Add.Add_Service_Principal_Option"),
                      onClick: addServicePrincipalClickHandler
                  }
              ],
              useTargetWidth: true
          }
        : undefined;

    const actions: IHuxPageActionProps = {
        otherActions: [],
        secondaryAction:
            huxFeatureSettings.isAllowed(NccFeatures.UserImport) &&
            doesUserHaveGrantsToViewUserImport(userContext)
                ? {
                      commandBarButtonAs: () => {
                          return (
                              <CommandBarButton
                                  iconProps={{ iconName: "Upload" }}
                                  onClick={() => navigate("/users/import")}
                                  ariaLabel={t("Users.Import.Import_Users_Button")}
                                  text={t("Users.Import.Import_Users_Button")}
                              />
                          );
                      },
                      key: "importUsers"
                  }
                : undefined,
        primaryAction:
            userContext.hasGrant(NmsGrant.SuperUser) ||
            userContext.hasGrant(NmsGrant.CreateUsersInAllGroupsInAllOrganizations) ||
            userContext.hasGrant(NmsGrant.CreateUserInAllGroups)
                ? {
                      commandBarButtonAs: () => {
                          return (
                              <PrimaryButton
                                  styles={{ root: { height: "44px" } }}
                                  iconProps={{ iconName: "AddFriend" }}
                                  onClick={addUserClickHandler}
                                  ariaLabel={t("Users.Add.Add_User_Button")}
                                  menuProps={buttonMenuProps}
                              >
                                  {t("Users.Add.Add_User_Button")}
                              </PrimaryButton>
                          );
                      },
                      key: "addUser"
                  }
                : undefined
    };

    const organizationUid = userContext.multiOrg
        ? userContext.hasGrant(NmsGrant.SuperUser) ||
          userContext.hasGrant(NmsGrant.CreateUsersInAllGroupsInAllOrganizations)
            ? selectedOrg
                ? selectedOrg.uid
                : userContext.getDefaultOrganization().uid
            : undefined
        : userContext.organization.uid;
    const addAnotherUser = isAddAnotherUser && newUserAdded !== undefined;
    const addUserPanelProps: IAddUserPanelProps = {
        organizationUid: addAnotherUser ? newUserAdded.organizationUID : organizationUid,
        onClosePanel: () => {
            setFalseIsAddAnotherUser();
            toggleIsAddUserPanelOpen();
        },
        groups: addAnotherUser ? newUserAdded.selectedGroups : [],
        onUserAdded: (newUser: NewUserAdded) => {
            toggleIsAddUserConfirmDialog();
            setNewUserAdded(newUser);
            setServicePrincipalAdded(false);
        },
        userType: addAnotherUser ? newUserAdded.userType : undefined,
        primarySpecialty: addAnotherUser ? newUserAdded.primarySpecialty : undefined,
        secondarySpecialty: addAnotherUser ? newUserAdded.secondarySpecialty : undefined
    };

    /** Columns to be shown in the userListComponent */
    const listColumns: IHuxDetailsListColumn[] = [
        {
            key: "nameCol",
            name: t("Groups.MembersTable.Name_Head"),
            fieldName: "id",
            minWidth: 200,
            isResizable: true
        },
        {
            key: "usernameCol",
            name: t("Groups.MembersTable.Username_Head"),
            fieldName: "login",
            minWidth: 200,
            isResizable: true
        },
        {
            key: "emailCol",
            name: t("Groups.MembersTable.Email_Head"),
            fieldName: "email",
            minWidth: 150,
            isResizable: true
        },
        {
            key: "statusCol",
            name: t("Groups.MembersTable.Status_Head"),
            fieldName: "status",
            minWidth: 120,
            isResizable: true,
            onRender: (item: IHuxDetailsListItem) => renderUserStatus(item, t)
        },
        {
            key: "organizationCol",
            name: t("Users.Search.Organization_Label"),
            fieldName: "organization",
            minWidth: 150,
            isResizable: true
        },
        {
            key: "departmentCol",
            name: t("Groups.MembersTable.Department_Head"),
            fieldName: "department",
            minWidth: 150,
            isResizable: true
        },
        {
            key: "primarySpecialtyCol",
            name: t("Groups.MembersTable.Primary_Specialty_Head"),
            fieldName: "primarySpecialty",
            minWidth: 150,
            isResizable: true
        },
        {
            key: "userTypeCol",
            name: t("Groups.MembersTable.User_Type_Head"),
            fieldName: "userType",
            minWidth: 100,
            isResizable: true
        }
    ];

    return (
        <main tabIndex={-1}>
            <HuxPageHeader actions={actions} titleInfo={{ title: t("Users.Users_Title") }} />
            <div className={usersSearchContainerStyles}>
                <div style={{ flexBasis: 500 }}>
                    <HuxSearchWithOptions
                        description={t("Users.Search.Filter.Text")}
                        example={t("Users.Search.Filter.Tip")}
                        queryOptions={queryOptions}
                        defaultQuery={queryOptions[2]}
                        ariaLabelForSearch={t("Users.Search.Search_Label")}
                        onChange={(searchData, searchString) => {
                            if (searchData) {
                                if (searchData.error != undefined) {
                                    setSearchDisabled(true);
                                } else if (searchData.data != null) {
                                    setSearchDisabled(false);
                                    setSearchTokensVal(searchData.data);
                                }
                            } else {
                                setSearchDisabled(true);
                            }
                            setSearchString(searchString);
                        }}
                        onSearch={() => {
                            if (!searchDisabled) {
                                onSearch();
                            }
                        }}
                        defaultSearchString={searchDataParsed?.string}
                        key={searchDataParsed?.string}
                    />
                </div>
                {userContext.multiOrg && (
                    <HapOrganizationDropdown
                        selectedOrgName={searchDataParsed?.organization?.name}
                        onSelectHandler={(selectedOrg?: IHuxPinListItem) => {
                            setSelectedOrg(selectedOrg);
                            if (searchDisabled && searchTokensVal) {
                                setSearchDisabled(false);
                            }
                        }}
                        key={searchDataParsed?.organization?.name}
                    ></HapOrganizationDropdown>
                )}
                <div>
                    {!isLoading && (
                        <PrimaryButton
                            componentRef={searchButtonRef}
                            styles={{ root: { flexBasis: 100 } }}
                            disabled={searchDisabled}
                            onClick={() => {
                                onSearch();
                            }}
                            ariaLabel={t("Users.Search.Search_Button")}
                        >
                            {t("Users.Search.Search_Button")}
                        </PrimaryButton>
                    )}
                    {isLoading && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >
                            <Spinner
                                size={SpinnerSize.large}
                                style={{ height: "80%", justifySelf: "left", marginRight: 8 }}
                                ariaLive={"assertive"}
                                ariaLabel={t("Progress.Spinner_Searching")}
                            ></Spinner>
                            <Text>{t("Progress.Spinner_Searching")}</Text>
                        </div>
                    )}
                </div>
            </div>
            {
                <div style={{ marginLeft: 24, marginRight: 24 }}>
                    <UserListComponent
                        userRequestParameters={searchDataParsed?.parameters}
                        onSearchComplete={() => {
                            setIsLoading(false);
                        }}
                        label={t("Users.Search.Pagination_Label")}
                        updateSkip={skip => {
                            /** take new skip value and create a new searchParams/Guid */
                            if (searchUrlGuid && searchDataParsed) {
                                const searchGuid = uuidv4();

                                sessionStorage.setItem(
                                    searchGuid,
                                    JSON.stringify({
                                        parameters: {
                                            ...searchDataParsed.parameters,
                                            skip: skip
                                        },
                                        string: searchDataParsed.string,
                                        organization: searchDataParsed.organization,
                                        tokenData: searchDataParsed.tokenData
                                    })
                                );
                                setSearchParams({ searchParams: searchGuid });
                            }
                        }}
                        key={searchUrlGuid}
                        columns={userContext.multiOrg ? listColumns : undefined}
                    />
                </div>
            }
            {isAddUserPanelOpen && <AddUserPanel {...addUserPanelProps} />}
            {isAddServicePrincipalPanelOpen && (
                <AddServicePrincipalPanel
                    orgUID={organizationUid}
                    onDismiss={() => toggleIsAddServicePrincipalPanelOpen()}
                    onUserAdded={(newUser: NewUserAdded) => {
                        toggleIsAddUserConfirmDialog();
                        setServicePrincipalAdded(true);
                        setNewUserAdded(newUser);
                    }}
                />
            )}
            {isAssignLicensesPanelOpen && userToAssignLicense && (
                <UserGrantLicensesPanel
                    user={userToAssignLicense}
                    existingLicenses={[]}
                    isNewUser={true}
                    onDismiss={toggleIsAssignLicensesPanelOpen}
                ></UserGrantLicensesPanel>
            )}
            {isAddUserConfirmDialog && newUserAdded && (
                <AddUserConfirmDialog
                    isOpen={isAddUserConfirmDialog}
                    userAdded={newUserAdded}
                    onDismiss={toggleIsAddUserConfirmDialog}
                    onAddAnotherUser={() => {
                        toggleIsAddUserConfirmDialog();
                        setTrueIsAddAnotherUser();
                        toggleIsAddUserPanelOpen();
                    }}
                    onAssignLicensesToUser={(user: IUser) => {
                        toggleIsAddUserConfirmDialog();
                        toggleIsAssignLicensesPanelOpen();
                        setUserToAssignLicense(user);
                    }}
                    isServicePrincipal={servicePrincipalAdded}
                />
            )}
        </main>
    );
};
