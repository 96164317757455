/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */
import { ISVGImageProps } from "./SVGImageProps";

/**
 * @description OrganizationSVG component contains SVG for Organization Grant icon
 * @param props {ISVGImageProps} - The props containing properties for SVG images.
 * @returns The OrganizationSVG component.
 */
export const OrganizationSVG = (props: ISVGImageProps) => {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="organization"
        >
            <path
                d="M6.83304 0.332031C5.45151 0.332031 4.33154 1.452 4.33154 2.83355C4.33154 4.04391 5.19115 5.0535 6.33317 5.28512V6.66686H4.1665C3.33808 6.66686 2.6665 7.33846 2.6665 8.16686V8.71713C1.5253 8.9494 0.666504 9.9586 0.666504 11.1684C0.666504 12.5499 1.78648 13.6699 3.16802 13.6699C4.54958 13.6699 5.66957 12.5499 5.66957 11.1684C5.66957 9.95753 4.80923 8.9476 3.6665 8.71653V8.16686C3.6665 7.89073 3.89036 7.66686 4.1665 7.66686H9.49984C9.77597 7.66686 9.99984 7.89073 9.99984 8.16686V8.71713C8.85864 8.9494 7.99984 9.9586 7.99984 11.1684C7.99984 12.5499 9.11984 13.6699 10.5014 13.6699C11.8829 13.6699 13.0029 12.5499 13.0029 11.1684C13.0029 9.95753 12.1426 8.9476 10.9998 8.71653V8.16686C10.9998 7.33846 10.3282 6.66686 9.49984 6.66686H7.33317V5.28508C8.4751 5.05338 9.33457 4.04384 9.33457 2.83355C9.33457 1.452 8.21464 0.332031 6.83304 0.332031ZM5.33154 2.83355C5.33154 2.00428 6.00377 1.33203 6.83304 1.33203C7.6623 1.33203 8.33457 2.00428 8.33457 2.83355C8.33457 3.66282 7.6623 4.33508 6.83304 4.33508C6.00377 4.33508 5.33154 3.66282 5.33154 2.83355ZM1.6665 11.1684C1.6665 10.3391 2.33876 9.66686 3.16802 9.66686C3.9973 9.66686 4.66955 10.3391 4.66955 11.1684C4.66955 11.9977 3.9973 12.6699 3.16802 12.6699C2.33876 12.6699 1.6665 11.9977 1.6665 11.1684ZM10.5014 9.66686C11.3306 9.66686 12.0029 10.3391 12.0029 11.1684C12.0029 11.9977 11.3306 12.6699 10.5014 12.6699C9.6721 12.6699 8.99984 11.9977 8.99984 11.1684C8.99984 10.3391 9.6721 9.66686 10.5014 9.66686Z"
                fill="#605E5C"
            />
        </svg>
    );
};
