/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

import { IDropdownOption } from "@fluentui/react";
import { i18n } from "i18next";

const specialties = [
    "Addiction Medicine",
    "Advanced Heart Failure and Transplant",
    "Allergy & Immunology",
    "Anesthesiology",
    "Cardiac Surgery",
    "Cardiology",
    "Cardiovascular Disease/Cardiology",
    "Clinical Cardiac Electrophysiology",
    "Colorectal Surgery",
    "Colorectal Surgery (Proctology)",
    "Critical Care Medicine",
    "Dermatology",
    "Diagnostic Radiology",
    "Emergency Medicine",
    "Endocrinology",
    "Family Medicine",
    "Family Practice",
    "Gastroenterology",
    "General Surgery",
    "Geriatric Medicine",
    "Geriatric Psychiatry",
    "Gynecological Oncology",
    "Hand Surgery",
    "Hematology",
    "Hematology & Oncology",
    "Hospice and Palliative Care",
    "Hospitalist",
    "Infectious Disease",
    "Internal Medicine",
    "Interventional Cardiology",
    "Interventional Pain Management",
    "Interventional Radiology",
    "Maxillofacial Surgery",
    "Medical Oncology",
    "Medical Toxicology",
    "Nephrology",
    "Neurological Surgery/Neurosurgery",
    "Neurology",
    "Neuropsychiatry",
    "Neurosurgery",
    "Nuclear Medicine",
    "Obstetrics & Gynecology",
    "Ophthalmology",
    "Orthopedic Surgery",
    "Osteopathic Manipulative Medicine",
    "Other",
    "Otolaryngology",
    "Pain Management",
    "Pain Medicine & Pain Management",
    "Pathology",
    "Pediatric Medicine",
    "Peripheral Vascular Disease",
    "Physical Medicine Rehabilitation",
    "Plastic and Reconstructive Surgery",
    "Plastic Surgery",
    "Preventative Medicine",
    "Preventive Medicine",
    "Pulmonary Disease/Pulmonology",
    "Psychiatry",
    "Radiation Oncology",
    "Radiology & Diagnostic Radiology",
    "Rheumatology",
    "Sleep Medicine",
    "Sports Medicine",
    "Surgical Oncology",
    "Thoracic Surgery",
    "Urology",
    "Vascular Surgery"
];

/**
 * Get the dropdown options for specialties
 * @param t The function to translate.
 * @returns Dropdown options for user specialties.
 */
export const getSpecialtiesOptions = (t: i18n["t"]): IDropdownOption[] => {
    const options: IDropdownOption[] = specialties.map(s => {
        return { text: s, key: s };
    });
    options.splice(0, 1, { text: t("Users.Add.Specialty_None"), key: "" });
    return options;
};
