/**
 * @copyright (C) Microsoft Corporation.
 * All Rights Reserved.
 */

import { mergeStyles, CheckboxVisibility } from "@fluentui/react";
import { IHuxDetailsListColumn, HuxDetailsList, NoItemsType } from "@nuance/hux-components";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { LicenseSelection } from "../../common/LicenseFunctions";
import { getLicenseToolTipStringIdentifier } from "../../common/getLicenseTooltipStringIdentifier";
import { useCallback, useEffect } from "react";
import { Selection } from "@fluentui/react";

// object containing generic key and value of type LicenseSelection[]
type LicenseFormikValues = {
    [key: string]: LicenseSelection[];
};

type Props = {
    isLoading: boolean;
    licenses?: LicenseSelection[];
    formikFieldName?: string;
};
/**
 * List of selectable licenses
 * @param props
 * @returns JSX.Element
 */
export const AvailableLicensesTable = (props: Props): JSX.Element => {
    const { isLoading, licenses } = props;
    const { t } = useTranslation();
    const formik = useFormikContext<LicenseFormikValues>();
    const formikFieldName = props.formikFieldName ?? "availableLicenses";

    const columns: IHuxDetailsListColumn[] = [
        {
            key: "type",
            name: t("Users.LicensesTable.License_Type_Head"),
            fieldName: "id",
            minWidth: 260,
            isResizable: true
        },
        {
            key: "availableCount",
            name: t("Users.LicensesTable.Available_Licenses_Head"),
            fieldName: "count",
            minWidth: 130,
            isResizable: true,
            className: mergeStyles({ textAlign: "right" }), // right align count
            styles: { cellTitle: { justifyContent: "end" } } //right align header
        }
    ];

    const items = licenses?.map((license, index) => {
        const tooltipStringId = getLicenseToolTipStringIdentifier(
            license.LicenseTypeGuid,
            license.PartnerCode
        );
        return {
            id: license.LicenseName,
            key: `${license.LicenseName}/${license.PartnerName}`,
            index,
            subtext: `${license.ProductName} / ${license.PartnerName}`,
            count: license.TotalLicenses - license.UsedLicenses,
            tooltip: tooltipStringId ? t(tooltipStringId) : undefined
        };
    });

    const onSelectRow = useCallback(
        (selectedIndexes: number[]) => {
            if (licenses && selectedIndexes.length > 0) {
                licenses?.forEach((license, index) => {
                    license.selected = selectedIndexes.includes(index);
                });
                formik.setFieldValue(formikFieldName, licenses);
            } else {
                formik.resetForm();
            }
        },
        [licenses]
    );

    const createSelection = (): Selection => {
        const selection = new Selection({
            onSelectionChanged: () => {
                const selectedRows = selection.getSelectedIndices();
                onSelectRow(selectedRows);
            }
        });
        return selection;
    };

    const selection = createSelection();

    // show previously selected licenses on initial render
    useEffect(() => {
        licenses?.forEach((license, index) => {
            const selected = formik.values[formikFieldName]?.[index]?.selected ?? false;
            selection.setIndexSelected(index, selected, true);
        });
    }, []);

    return (
        <HuxDetailsList
            id={formikFieldName}
            compact={true}
            columns={columns}
            items={items ?? []}
            isLoading={isLoading}
            checkboxVisibility={CheckboxVisibility.always}
            isSelectedOnFocus={false} // don't deselect previous row when clicking on a new row
            selection={selection}
            emptyStateProps={{
                type: NoItemsType.NoItemsNoAdd,
                heading: t("Users.LicensesTable.No_Available_Licenses_Title")
            }}
        ></HuxDetailsList>
    );
};
