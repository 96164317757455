/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import React, { useState, useEffect } from "react";
import {
    useHapAuth,
    IGetGroupsSearchParameters,
    UserManagementSvc,
    useApiRequest,
    HapOrganizationDropdown,
    CacheKey
} from "@nuance/hap-components";
import {
    mergeStyles,
    Spinner,
    SpinnerSize,
    Dropdown,
    PrimaryButton,
    Text,
    SearchBox,
    IDropdownOption
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { HuxPageHeader, HuxControlLabel } from "@nuance/hux-components";
import { useQueryClient } from "@tanstack/react-query";
import { getLogger } from "@nuance/hux-diagnostics";
import { GroupListComponent } from "../components/GroupListComponent";
import { EventTypes } from "../EventTypes";
import { FailedToLoadPage } from "../components/FailedToLoadPage";
import { getRoleTypeTranslationText } from "../common/getRoleTypeTranslationText";
import { useSearchParams } from "react-router-dom";
import { INameUidPair } from "../common/INameUidPair";
import { HashSvc } from "../services/HashSvc";

const logger = getLogger();

/**
 * Group Search Page
 * @returns JSX.Element
 */
export const GroupSearchPage = () => {
    const { userContext } = useHapAuth();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const userManagementSvc = new UserManagementSvc(userContext);
    const [searchParams, setSearchParams] = useSearchParams();

    /** isLoading for api call from GroupListComponent callback */
    const [isLoading, setIsLoading] = useState(false);
    /** If Search button is disabled */
    const [searchDisabled, setSearchDisabled] = useState(true);
    /** State for the search parameters */
    const [groupSearchParameters, setGroupSearchParameters] =
        useState<IGetGroupsSearchParameters>();

    // get search values from url search params
    const name = searchParams.get("name") || "";
    const role = searchParams.get("role");
    const org = searchParams.get("org");
    const orgName = searchParams.get("orgName");
    const skipInUrl = searchParams.get("skip");

    /** State for the currently selected roleUID, undefined if all */
    const [roleUID, setRoleUID] = useState<number | undefined>(role ? Number(role) : undefined);
    /** State for the current search box value */
    const [searchVal, setSearchVal] = useState<string>(name);
    /** state for pagination */
    const [skip, setSkip] = useState<number>(skipInUrl ? Number(skipInUrl) : 0);

    const hashService = HashSvc.getInstance();
    let orgNameUID: INameUidPair | undefined = undefined;
    if (orgName && org) {
        orgNameUID = {
            Name: orgName,
            UID: hashService.HashDecode(org)
        };
    }

    /** State for the currently selected organization, undefined if all */
    const [organization, setOrganization] = useState<INameUidPair | undefined>(orgNameUID);

    // Sets the page title
    useEffect(() => {
        document.title = t("Groups.Groups_Search_Page_Title");
    }, []);

    // Sets the search parameters based on what is found in the url
    useEffect(() => {
        if (searchParams.toString() && isValidSearch(name, orgNameUID?.UID)) {
            setRoleUID(role ? Number(role) : undefined);
            setSearchVal(name);
            setOrganization(orgNameUID);
            setGroupSearchParameters({
                organizationUID: orgNameUID?.UID,
                name: name,
                roleUID: role ? Number(role) : undefined,
                skip,
                take: 100
            });
        } else if (searchParams.toString() && !isValidSearch(name, orgNameUID?.UID)) {
            setRoleUID(role ? Number(role) : undefined);
            setSearchVal(name);
            setOrganization(orgNameUID);
            setGroupSearchParameters(undefined);
        } else if (searchParams.toString() === "") {
            setSearchVal("");
            setGroupSearchParameters(undefined);
        }
    }, [searchParams]);

    const { error: errorGettingRoles, data: roles } = useApiRequest(userManagementSvc.getRoles());

    /** What should happen if roles data fails to load */
    if (errorGettingRoles) {
        return <FailedToLoadPage />;
    }

    /** Options list for role dropdown */
    const roleOptions: IDropdownOption[] = [{ text: t("Groups.Search.All_Roles_Option"), key: 0 }];

    /** add role types returned from api call */
    if (roles) {
        roles.forEach(role => {
            const roleTypeTranslation = getRoleTypeTranslationText(role.RoleType);

            if (roleTypeTranslation) {
                roleOptions.push({
                    text: t(roleTypeTranslation),
                    key: role.UID
                });
            }
        });
    }

    /** Avoiding Fluent Stack since going away in Fluent 9 */
    const horizontalStackStyle = {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        gap: 16,
        flexWrap: "wrap"
    };

    /** Style for search components container */
    const groupSearchContainerStyles = mergeStyles(horizontalStackStyle, {
        margin: 24
    });

    /** save group name, role, orgUID, and skip to url search params */
    const saveSearchToUrl = () => {
        const newSearchParams = new URLSearchParams();

        if (searchVal) {
            newSearchParams.set("name", searchVal);
        }
        if (roleUID) {
            newSearchParams.set("role", roleUID.toString());
        }
        if (organization) {
            newSearchParams.set("orgName", organization.Name);
            const hashedOrgUID = hashService.HashEncode(organization.UID);
            newSearchParams.set("org", hashedOrgUID);
        }
        if (skip) {
            newSearchParams.set("skip", skip.toString());
        }

        setSearchParams(newSearchParams);
    };

    /** Actions that take place when a user presses the search button */
    const onSearch = () => {
        setSearchDisabled(true);

        /** Create variable for search parameters */
        const groupSearchParams: IGetGroupsSearchParameters = {
            organizationUID: organization?.UID,
            name: searchVal,
            roleUID: roleUID,
            skip,
            take: 100
        };

        /** If the desired data is already cached the spinner won't show */
        if (
            queryClient.getQueriesData({ queryKey: [CacheKey.getGroups, groupSearchParams] })
                .length === 0
        ) {
            setIsLoading(true);
        }

        /** Set state variable for search parameters */
        setGroupSearchParameters(groupSearchParams);

        logger.trackEvent(EventTypes.GroupSearchInitiated, { ...groupSearchParameters });

        saveSearchToUrl();
    };

    const isValidSearch = (searchString?: string, selectedOrgUID?: number) => {
        if (searchString && !searchString.match(/^(?!\s+$).*/)) {
            return false;
        } else if (
            (searchString && searchString.match(/^(?!\s+$).*/)) ||
            selectedOrgUID !== undefined
        ) {
            return true;
        } else return false;
    };

    /** set if the search should be disabled  */
    const setSearchButtonStatus = (searchString?: string, selectedOrgUID?: number) => {
        const isValid = isValidSearch(searchString, selectedOrgUID);
        setSearchDisabled(!isValid);
    };

    return (
        <main tabIndex={-1}>
            <HuxPageHeader titleInfo={{ title: t("Groups.Groups_Title") }} />
            <div className={groupSearchContainerStyles}>
                <div style={{ flexBasis: 300 }}>
                    <HuxControlLabel labelText={t("Groups.Search.Search_Label")} />
                    <SearchBox
                        onSearch={() => {
                            if (!searchDisabled) {
                                onSearch();
                            }
                        }}
                        value={searchVal}
                        onChange={(_, searchString) => {
                            setSearchButtonStatus(searchString, organization?.UID);

                            if (!searchString) {
                                setSearchVal("");
                            } else {
                                setSearchVal(searchString);
                            }
                        }}
                        ariaLabel={t("Groups.Search.Search_Label")}
                    />
                </div>
                <div style={{ flexBasis: 300 }}>
                    <Dropdown
                        label={t("Groups.Search.Role_Label")}
                        ariaLabel={t("Groups.Search.Role_Label")}
                        options={roleOptions}
                        selectedKey={roleUID ? roleUID : 0}
                        onChange={(_, option) => {
                            setSearchButtonStatus(searchVal, organization?.UID);

                            if (option && option?.key !== 0) {
                                setRoleUID(option.key as number);
                            } else {
                                setRoleUID(undefined);
                            }
                        }}
                    ></Dropdown>
                </div>
                {userContext.multiOrg && (
                    <div style={{ flexBasis: 300 }}>
                        <HapOrganizationDropdown
                            key={organization?.UID}
                            selectedOrgName={organization?.Name}
                            onSelectHandler={selectedOrg => {
                                setSearchButtonStatus(searchVal, selectedOrg?.uid);

                                if (selectedOrg) {
                                    const org: INameUidPair = {
                                        Name: selectedOrg.name,
                                        UID: selectedOrg.uid
                                    };
                                    setOrganization(org);
                                } else {
                                    setOrganization(undefined);
                                }
                            }}
                        />
                    </div>
                )}
                <div>
                    {!isLoading && (
                        <PrimaryButton
                            styles={{ root: { flexBasis: 100 } }}
                            disabled={searchDisabled}
                            onClick={() => {
                                onSearch();
                            }}
                            ariaLabel={t("Groups.Search.Search_Button")}
                        >
                            {t("Groups.Search.Search_Button")}
                        </PrimaryButton>
                    )}
                    {isLoading && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >
                            <Spinner
                                size={SpinnerSize.large}
                                style={{ height: "80%", justifySelf: "left", marginRight: 8 }}
                                ariaLive={"assertive"}
                                ariaLabel={t("Progress.Spinner_Searching")}
                            ></Spinner>
                            <Text>{t("Progress.Spinner_Searching")}</Text>
                        </div>
                    )}
                </div>
            </div>
            {
                <div style={{ marginLeft: 24, marginRight: 24 }}>
                    <GroupListComponent
                        groupRequestParameters={groupSearchParameters}
                        label={t("Groups.Search.Pagination_Label")}
                        roles={roles}
                        onSearchComplete={() => {
                            setIsLoading(false);
                        }}
                        updateSkip={(newSkip: number) => {
                            setSkip(newSkip);
                            if (newSkip) {
                                searchParams.set("skip", newSkip.toString());
                                setSearchParams(searchParams);
                            }
                        }}
                    />
                </div>
            }
        </main>
    );
};
