/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import {
    Dialog,
    DialogType,
    IDialogContentProps,
    Text,
    MessageBar,
    MessageBarType,
    Stack,
    Link
} from "@fluentui/react";
import { useTranslation } from "react-i18next";

export const GetNewApplicationUrl = (oldUrl: string): string | null => {
    if (oldUrl.toLocaleLowerCase() === "https://qaa.nccnonprod.nuance.com") {
        return "https://ncc.qa.us.dmp.nuance.com";
    } else if (oldUrl.toLocaleLowerCase() === "https://stg.nccv2.nuance.com") {
        return "https://stg.ncc.nuance.com";
    } else if (oldUrl.toLocaleLowerCase() === "https://nccv2.nuance.com") {
        return "https://ncc.nuance.com";
    } else {
        return null;
    }
};

/**
 * Inactivity Timeout Dialog properties
 */
export interface ApplicationChangeURLBannerDialogProps {
    /**
     * Callback on close.
     */
    onClose: () => void;
}
/**
 * Application Change URL Banner Dialog component.
 * @param props ApplicationChangeURLBannerDialog properties
 * @returns ApplicationChangeURLBannerDialog component.
 */
const ApplicationChangeURLBannerDialog = (
    props: ApplicationChangeURLBannerDialogProps
): JSX.Element => {
    const { t } = useTranslation();

    const dialogContentProps: IDialogContentProps = {
        type: DialogType.normal,
        title: t("ApplicationUrlChangeBanner.Header"),
        showCloseButton: true
    };
    const newUrl = `${GetNewApplicationUrl(window.location.origin)}?connect=${localStorage.getItem("authConnection")}`;

    return (
        <Dialog
            hidden={false}
            dialogContentProps={dialogContentProps}
            minWidth={500}
            onDismiss={props.onClose}
        >
            <Stack tokens={{ childrenGap: 16 }}>
                <MessageBar
                    messageBarType={MessageBarType.warning}
                    isMultiline={true}
                    dismissButtonAriaLabel="Close"
                >
                    {t("ApplicationUrlChangeBanner.WarningHeader")}
                </MessageBar>
                <Text>{t("ApplicationUrlChangeBanner.WarningDetails")}</Text>
                <Link href={newUrl}>{newUrl}</Link>
            </Stack>
        </Dialog>
    );
};

export default ApplicationChangeURLBannerDialog;
