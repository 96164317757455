/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */
import { EventTypes, ITrackEventInfo } from "../../EventTypes";
import { OrganizationSVG } from "../SVG/OrganizationSVG";
import { ISpecializedNameWithIconPropsBase, NameWithIcon } from "./NameWithIcon";

/**
 * OrganNameWithIcon properties
 */
export interface IOrgNameWithIconProps extends ISpecializedNameWithIconPropsBase {
    /** Organization */
    orgInfo: { Name: string; UID?: number; NuanceHCAccountGuid: string };
}

/**
 * Organization name with icon component.
 * @param props Component properties
 */
export const OrgNameWithIcon = (props: IOrgNameWithIconProps) => {
    const showLink = props.noLink !== true;
    const iconWidth = props.iconSize?.width ?? 14;
    const iconHeight = props.iconSize?.height ?? 14;
    const trackEventInfo: ITrackEventInfo = {
        eventType: props.trackEventInfo?.eventType ?? EventTypes.OrgNameLinkClicked,
        customProperties: {
            guid: props.orgInfo.NuanceHCAccountGuid,
            ...props.trackEventInfo?.customProperties
        }
    };

    const href = showLink ? `/orgs/${props.orgInfo.NuanceHCAccountGuid}/overview` : undefined;

    return (
        <NameWithIcon
            name={props.orgInfo.Name}
            icon={<OrganizationSVG width={iconWidth} height={iconHeight} />}
            href={href}
            placement={props.placement}
            trackEventInfo={trackEventInfo}
        />
    );
};
