/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

import {
    IHapUserContext,
    ILicenseSummary,
    ILicenseTypePrivilegeMap,
    LicenseMode,
    NmsPrivilege
} from "@nuance/hap-components";
import { i18n } from "i18next";
import { ILicenseListItem } from "../Product/Licenses";
import { IconPlacement } from "../components/name_with_icon/IconPlacement";
import { AppConfig } from "../services/AppConfigSvc";
import { NameWithIcon } from "../components/name_with_icon/NameWithIcon";
import { AccountSVG } from "../components/SVG/AccountSVG";
import { OrganizationSVG } from "../components/SVG/OrganizationSVG";
import { SiteSVG } from "../components/SVG/SiteSVG";

// function to get product guid used in url route
export function getProductGuid(productName: string, appConfig?: AppConfig): string | undefined {
    if (!appConfig) return undefined;
    const productsMetaData = appConfig.productsMetaData;
    const product = productsMetaData.find(
        p => p.productName.toLocaleLowerCase() === productName.toLocaleLowerCase()
    );
    return product?.productGuid;
}

export const renderLicenseMode = (item: ILicenseListItem, t: i18n["t"]) => {
    const type = getLicenseMode(item.mode);
    return <NameWithIcon icon={type.image} name={t(type.text)} placement={IconPlacement.Start} />;
};

/**
 * Interface for LicenseMode
 */
export interface ILicenseMode {
    text: string;
    image: JSX.Element;
}

/** get License Mode */
const getLicenseMode = (mode: number): ILicenseMode => {
    switch (mode) {
        case LicenseMode.Account:
            return {
                text: "Licenses.LicensesTable.Mode_Option.Account",
                image: <AccountSVG width={12} height={14} />
            };
        case LicenseMode.Enterprise:
            return {
                text: "Licenses.LicensesTable.Mode_Option.Enterprise",
                image: <OrganizationSVG width={14} height={14} />
            };
        case LicenseMode.Site:
            return {
                text: "Licenses.LicensesTable.Mode_Option.Site",
                image: <SiteSVG width={12} height={14} />
            };
        default:
            return {
                text: "Licenses.LicensesTable.Mode_Option.Account",
                image: <AccountSVG width={12} height={14} />
            };
    }
};

// if list contains both enterprise and account version of same license, combine them
export const getCombinedLicenses = (licenses?: ILicenseSummary[]) => {
    /** All Enterprise licenses */
    const enterpriseLicenses =
        licenses?.filter(license => license.EntityMode === LicenseMode.Enterprise) ?? [];
    /** All Account licenses */
    const accountLicenses =
        licenses?.filter(license => license.EntityMode === LicenseMode.Account) ?? [];
    /** Go through each enterprise license and find the corresponding account license and usedLicense value */
    enterpriseLicenses?.forEach(enterpriseLicense => {
        /** Find the corresponding account license */
        accountLicenses?.forEach((accountLicense, index) => {
            if (
                accountLicense.LicenseName === enterpriseLicense.LicenseName &&
                enterpriseLicense.PartnerName === accountLicense.PartnerName &&
                enterpriseLicense.OrganizationUID === accountLicense.OrganizationUID
            ) {
                /** set the UsedLicense value of the enterprise to the corresponding account value */
                enterpriseLicense.UsedLicenses = accountLicense.UsedLicenses;
                /** Remove that account license from the account array */
                /** Because we break out of the loop right after this it is ok to splice inside of the forEach */
                accountLicenses.splice(index, 1);
                /** Break out of loop */
                return;
            }
        });
    });

    return enterpriseLicenses.concat(accountLicenses);
};

/**
 * sort licenses by product, then license name, then partner
 * @param licenses
 * @returns
 */
export const sortLicenses = (licenses: ILicenseSummary[] | undefined) => {
    return (
        licenses?.sort((a, b) => {
            // sort by product, then license name, then partner
            if (a.ProductName === b.ProductName) {
                if (a.LicenseName === b.LicenseName) {
                    return a.PartnerName.localeCompare(b.PartnerName);
                }
                return a.LicenseName.localeCompare(b.LicenseName);
            }
            return a.ProductName.localeCompare(b.ProductName);
        }) ?? []
    );
};

/**
 * get enterprise licenses
 * @param licenses
 * @returns
 */
export const getEnterpriseLicenses = (licenses: ILicenseSummary[] | undefined) => {
    return (
        licenses?.filter(license => {
            const availableCount = license.TotalLicenses - license.UsedLicenses;
            return availableCount > 0 && license.EntityMode === LicenseMode.Enterprise;
        }) ?? []
    );
};

/**
 * interface for license selection list item
 */
export interface LicenseSelection extends ILicenseSummary {
    selected: boolean;
}
export const getAvailableLicenses = (
    licenses: ILicenseSummary[] | undefined,
    enterpriseLicenses: ILicenseSummary[] | undefined
): LicenseSelection[] => {
    return (
        licenses
            ?.filter(license => {
                // only account licenses with available count
                const availableCount = license.TotalLicenses - license.UsedLicenses;
                return (
                    availableCount > 0 &&
                    license.EntityMode === LicenseMode.Account &&
                    // filter out account licenses that match enterprise licenses
                    !enterpriseLicenses?.some(enterpriseLicense => {
                        return (
                            enterpriseLicense.LicenseName === license.LicenseName &&
                            enterpriseLicense.PartnerName === license.PartnerName
                        );
                    })
                );
            })
            .map(license => {
                return {
                    ...license,
                    selected: false
                };
            }) ?? []
    );
};

/**
 * if not superuser, filter out licenses that the user does not have privilege to grant
 * @param userContext
 * @param privileges
 * @param licenses
 * @returns
 */
export const getLicensesUserHasPrivilege = (
    userContext: IHapUserContext,
    privileges: ILicenseTypePrivilegeMap[] | undefined,
    licenses: ILicenseSummary[] | undefined
) => {
    if (!userContext.isSuperUser() && privileges) {
        licenses = licenses?.filter(license => {
            const privilege = privileges.find(
                priv => priv.LicenseTypeGuid === license.LicenseTypeGuid
            );
            if (privilege) {
                return userContext.hasPrivilege(privilege.PrivilegeDefinitionGuid as NmsPrivilege);
            }
            return false;
        });
    }
    return licenses;
};
