/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import React from "react";

const containerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gridGap: 32
};
const mainContentStyle = {
    gridColumn: "span 2"
};
/**
 * Two column layout props
 */
export type TwoColumnLayoutProps = {
    MainContent: JSX.Element;
    RightRail: JSX.Element;
};

/**
 * TwoColumnLayout
 * @param props Two column layout props
 * @returns Layout component rendering components in two columns.
 */
export const TwoColumnLayout = (props: TwoColumnLayoutProps): JSX.Element => {
    return (
        <div style={containerStyle} data-testid="TwoComponentLayoutContainer">
            <div style={mainContentStyle} data-testid="MainContent">
                {props.MainContent}
            </div>
            <div data-testid="RightRail">{props.RightRail}</div>
        </div>
    );
};
