/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */
import CryptoJS from "crypto-js";

const encryptionKey = "NCC-Clipboard-Encryption-Key";

/**
 * Class for encrypting data using AES, Advanced Encryption Standard, encryption
 */
export class NccEncryption {
    private encryptionKey;

    constructor() {
        this.encryptionKey = encryptionKey;
    }

    /**
     * Setting encrypted data to localStorage
     * @param key localStorage key value
     * @param data data as string
     */
    public setLocalStorage = (key: string, data: string) => {
        localStorage.setItem(key, this.encryptData(data));
    };

    /**
     * Encrypt string data
     * @param data data as string
     * @returns encrypted string data
     */
    encryptData = (data: string) => {
        return CryptoJS.AES.encrypt(data, this.encryptionKey).toString();
    };

    /**
     * Get decrypted data from localStorage
     * @param key localStorage key value
     * @returns decrypted data in string variable
     */
    getLocalStorage = (key: string) => {
        const encryptedData = localStorage.getItem(key);
        if (encryptedData) {
            return this.decryptData(encryptedData);
        }
        return null;
    };

    /**
     * Decrypt encrypted localStorage data
     * @param encryptedData encrypted data returned from localStorage
     * @returns data in string variable
     */
    decryptData = (encryptedData: string) => {
        return CryptoJS.AES.decrypt(encryptedData, this.encryptionKey).toString(CryptoJS.enc.Utf8);
    };
}
