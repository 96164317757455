/**
 * @copyright (C) Microsoft Corporation.
 * All Rights Reserved.
 */

import { Stack } from "@fluentui/react";
import { ILicenseSummary } from "@nuance/hap-components";
import { HuxListItem } from "@nuance/hux-components";
import { getLicenseToolTipStringIdentifier } from "../../common/getLicenseTooltipStringIdentifier";
import { i18n } from "i18next";

/**
 * Display non selectable list of license (with name, product, partner, and tooltip)
 * @param licenses list of licenses to display
 * @returns JSX.Element
 */
export const OtherLicensesList = (licenses: ILicenseSummary[], t: i18n["t"]): JSX.Element => {
    return (
        <Stack tokens={{ childrenGap: 16, padding: "0 0 0 16px" }}>
            {licenses.map(license => {
                const tooltipStringId = getLicenseToolTipStringIdentifier(
                    license.LicenseTypeGuid,
                    license.PartnerCode
                );
                return (
                    <HuxListItem
                        key={`${license.LicenseName}/${license.PartnerName}`}
                        text={license.LicenseName}
                        subtext={`${license.ProductName} / ${license.PartnerName}`}
                        tooltip={tooltipStringId ? t(tooltipStringId) : undefined}
                    ></HuxListItem>
                );
            })}
        </Stack>
    );
};
