/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */
import {
    Dialog,
    DialogFooter,
    DialogType,
    IDialogContentProps,
    Text,
    PrimaryButton,
    Stack,
    NeutralColors,
    Link,
    useTheme,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { CheckMarkSVG } from "../../components/SVG/CheckMarkSVG";
import { UserNameFormat, formatUserName } from "@nuance/hux-components";
import { HashSvc } from "../../services/HashSvc";
import { useNavigate } from "react-router-dom";
import {
    IUser,
    NmsPrivilege,
    UserManagementSvc,
    useApiRequest,
    useHapAuth
} from "@nuance/hap-components";
import { getLogger } from "@nuance/hux-diagnostics";
import { EventTypes } from "../../EventTypes";
import { SelectedGroup } from "../../components/SelectGroupsPanel";

const logger = getLogger();

/**
 * The interface that was returned on callback on successful save of new user.
 */
export interface NewUserAdded {
    /**
     * The first name
     */
    firstName: string;
    /**
     * The last name
     */
    lastName: string;
    /**
     * The user UID of newly created user.
     */
    userUID: number;
    /**
     * The organization Uid of new user.
     */
    organizationUID: number;
    /**
     * The groups into which new user is enrolled.
     */
    selectedGroups: SelectedGroup[];
    /**
     * The user type
     */
    userType?: number;
    /**
     * The primary specialty
     */
    primarySpecialty?: string;
    /**
     * The secondary specialty
     */
    secondarySpecialty?: string;
}

/**
 * The properties for Add User Confirm dialog
 */
export interface IAddUserConfirmDialogProps {
    /**
     * Is dialog open
     */
    isOpen: boolean;
    /**
     * The user added.
     */
    userAdded: NewUserAdded;
    /**
     * The callback on closing of dialog
     */
    onDismiss: () => void;
    /**
     * The callback on user clicking the link to add another user.
     */
    onAddAnotherUser: () => void;
    /**
     * The callback on user clicking the link to assign licenses to user.
     */
    onAssignLicensesToUser: (user: IUser) => void;
    /**
     * true if new user is a service principal / managed identity
     */
    isServicePrincipal?: boolean;
}

/**
 * @description AddUserConfirmDialog component show dialog on successful addition of user with post actions.
 * @param props {IAddUserConfirmDialogProps} - The props containing properties for AddUserConfirmDialog component.
 * @returns The AddUserConfirmDialog component.
 */
export const AddUserConfirmDialog = (props: IAddUserConfirmDialogProps): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const { userContext } = useHapAuth();
    const userManagementSvc = new UserManagementSvc(userContext);
    const userReq = useApiRequest(userManagementSvc.getUser(props.userAdded.userUID));
    if (userReq.isError) {
        logger.logError("Error while getting user.", {
            message: userReq.error.message
        });
        return (
            <MessageBar messageBarType={MessageBarType.error}>
                {t("Error.Loading.Selected_User")}
            </MessageBar>
        );
    }
    if (userReq.isLoading || !userReq.data) {
        return <Spinner size={SpinnerSize.large} style={{ height: "80%" }} />;
    }
    const hashService = HashSvc.getInstance();
    const userName = props.isServicePrincipal
        ? props.userAdded.lastName
        : formatUserName(
              {
                  FirstName: props.userAdded.firstName,
                  LastName: props.userAdded.lastName
              },
              UserNameFormat.LFM
          );
    const dialogContentProps: IDialogContentProps = {
        type: DialogType.normal,
        title: (
            <>
                <Stack horizontal tokens={{ childrenGap: "10px" }}>
                    <Stack.Item style={{ paddingTop: 5 }}>
                        <CheckMarkSVG></CheckMarkSVG>
                    </Stack.Item>
                    <Stack.Item>
                        <Text
                            style={{
                                fontWeight: 600,
                                fontSize: 20,
                                color: NeutralColors.gray160
                            }}
                        >
                            {t("Users.Add.User_Added_Success.Title", {
                                userName: userName
                            })}
                        </Text>
                    </Stack.Item>
                </Stack>
            </>
        ),
        showCloseButton: true,
        onDismiss: props.onDismiss
    };

    const onUserDetails = (ev?: React.MouseEvent<HTMLElement>) => {
        ev?.preventDefault();
        logger.trackEvent(EventTypes.AddUserConfirmDialogUserDetailsLinkClicked);
        const hashedOrgUID = hashService.HashEncode(props.userAdded.organizationUID);
        navigate(`/users/${userReq.data.NuanceUserGuid}/orgs/${hashedOrgUID}`);
    };
    return (
        <Dialog hidden={!props.isOpen} dialogContentProps={dialogContentProps} minWidth={600}>
            <Stack tokens={{ childrenGap: 8 }}>
                <Stack.Item>
                    <Text
                        style={{
                            fontWeight: 600,
                            fontSize: 14,
                            color: NeutralColors.gray160
                        }}
                    >
                        {t("Users.Add.User_Added_Success.Related_Label")}
                    </Text>
                </Stack.Item>
                {!props.isServicePrincipal &&
                    userContext.hasPrivilege(NmsPrivilege.ManageLicenses) && (
                        <Stack.Item>
                            <Link
                                href={"#"}
                                onClick={ev => {
                                    ev?.preventDefault();
                                    logger.trackEvent(
                                        EventTypes.AddUserConfirmDialogAssignLicensesLinkClicked
                                    );
                                    props.onAssignLicensesToUser(userReq.data);
                                }}
                                style={{
                                    color: theme.palette.themeDarkAlt
                                }}
                            >
                                {t("Users.Add.User_Added_Success.Grant_Licenses_Link")}
                            </Link>
                        </Stack.Item>
                    )}
                <Stack.Item>
                    <Link
                        href={"#"}
                        onClick={ev => onUserDetails(ev)}
                        style={{
                            color: theme.palette.themeDarkAlt
                        }}
                    >
                        {props.isServicePrincipal
                            ? t("Users.Add.User_Added_Success.View_Account_Link")
                            : t("Users.Add.User_Added_Success.View_User_Account_Link")}
                    </Link>
                </Stack.Item>
                {!props.isServicePrincipal && (
                    <Stack.Item>
                        <Link
                            href={"#"}
                            onClick={ev => {
                                ev?.preventDefault();
                                logger.trackEvent(
                                    EventTypes.AddUserConfirmDialogAddAnotherUserLinkClicked
                                );
                                props.onAddAnotherUser();
                            }}
                            style={{
                                color: theme.palette.themeDarkAlt
                            }}
                        >
                            {t("Users.Add.User_Added_Success.Add_Another_User_Link")}
                        </Link>
                    </Stack.Item>
                )}
            </Stack>
            <div>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            props.onDismiss();
                        }}
                    >
                        {t("Form.Done_Button")}
                    </PrimaryButton>
                </DialogFooter>
            </div>
        </Dialog>
    );
};
