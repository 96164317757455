/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import "./Main.css";
import {
    useHapAuth,
    NmsPrivilege,
    NmsGrant,
    NmsLicense,
    UserManagementSvc,
    ILicenseGrant,
    NotFoundError,
    HapApiError
} from "@nuance/hap-components";
import {
    HuxFeatureContext,
    HuxAppNav,
    IHuxNavLinkGroup,
    IHuxAppNavProps,
    HuxMessageBar,
    IFeatureSettings
} from "@nuance/hux-components";
import { NccFeatures } from "./common/NccFeature/NccFeatures";
import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate, Navigate, useLocation } from "react-router-dom";
import { getTheme, INavLink, Spinner } from "@fluentui/react";
import { AppConfig } from "./services/AppConfigSvc";
import { useTranslation } from "react-i18next";
import "./App.css";
import { NCCTitleBar, NCCTitleBarProps } from "./NCCTitleBar/NCCTitleBar";
import { useIdleTimer } from "react-idle-timer";
import { useBoolean } from "@fluentui/react-hooks";
import InactivityTimeoutDialog from "./logout/InactivityTimeoutDialog";
import {
    AUTOTEXT_CLIPBOARD_DATA_STORAGE_KEY,
    VOCABULARY_CLIPBOARD_DATA_STORAGE_KEY,
    Inactivity_Timeout_Warning_Duration
} from "./NCCConstants";
import { NotAuthorizedPage } from "./not_authorized/NotAuthorizedPage";
import { v4 as uuidv4 } from "uuid";
import { AxiosError } from "axios";
import { getLogger } from "@nuance/hux-diagnostics";
import { UserSearchPage } from "./users/UserSearchPage";
import { GroupSearchPage } from "./groups/GroupSearchPage";
import { computeEffectiveAppFeatureSet } from "./common/NccFeature/appFeatureSet";
import { OrgGroupPage } from "./orgGroups/OrgGroupPage";
import { NccEncryption } from "./common/NccEncryption";
import {
    INCCClipboardAutoTextData,
    INCCClipboardVocabData
} from "./common/ClipboardOperationTypes";
import { doesUserHaveGrantsToViewUserImport } from "./common/NccFeaturePermissionChecks";
import ApplicationChangeURLBannerDialog, {
    GetNewApplicationUrl
} from "./ApplicationChangeURLBannerDialog";

const AnalyticsLandingPage = lazy(
    () => import("./analytics/AnalyticsLandingPage" /* webpackChunkName: "AnalyticsLandingPage" */)
);
const AnalyticsReportPage = lazy(
    () => import("./analytics/AnalyticsReportPage" /* webpackChunkName: "AnalyticsReportPage" */)
);
const OrganizationPage = lazy(
    () => import("./organization/OrganizationPage" /* webpackChunkName: "OrganizationPage" */)
);
const UserPage = lazy(() => import("./users/UserPage" /* webpackChunkName: "UserPage" */));
const UserSpeechPage = lazy(
    () => import("./users/UserSpeechPage" /* webpackChunkName: "UserSpeechPage" */)
);
const SitePage = lazy(() => import("./sites/SitePage" /* webpackChunkName: "SitePage" */));
const GroupPage = lazy(() => import("./groups/GroupPage" /* webpackChunkName: "GroupPage" */));
const ProductPage = lazy(
    () => import("./Product/ProductPage" /* webpackChunkName: "ProductPage" */)
);
const UserProductSettingsPage = lazy(
    () =>
        import("./users/UserProductSettingsPage" /* webpackChunkName: "UserProductSettingsPage" */)
);
const LicenseDetailsPage = lazy(
    () => import("./license/LicenseDetailsPage" /* webpackChunkName: "LicenseDetailsPage" */)
);
const LicenseSearchPage = lazy(
    () => import("./license/LicenseSearchPage" /* webpackChunkName: "LicenseSearchPage" */)
);

const SpeechRecognitionPage = lazy(
    () => import("./speech/SpeechRecognitionPage" /* webpackChunkName: "SpeechRecognitionPage" */)
);

const SupportToolsPage = lazy(
    () => import("./supportTools/SupportTools" /* webpackChunkName: "SupportToolsPage" */)
);

const UserImportDashboard = lazy(
    () =>
        import(
            "./users/UserImport/UserImportDashboard" /* webpackChunkName: "UserImportDashboard" */
        )
);

/**
 * Main properties
 */
export interface MainProps {
    /**
     * Application configuration
     */
    appConfig: AppConfig;
}

const containerStyles = {
    height: "100vh",
    display: "grid",
    gridTemplateAreas: `"header header header"
        "nav content content"`,
    gridTemplateColumns: "minmax(min-content, max-content) 1fr 1fr",
    gridTemplateRows: "40px 1fr"
};

const headerStyles = {
    gridArea: "header"
};

const navStyles = {
    gridArea: "nav",
    height: "100vh"
};

const theme = getTheme();
const pageStyles = {
    gridArea: "content",
    borderLeft: "1px solid",
    borderLeftColor: theme.palette.neutralLight,
    height: "calc(100% - 40px)", // full page minus title bar
    overflow: "auto"
};

export default function Main(props: MainProps) {
    const onIdle = () => {
        logout(`${window.location.origin}/logout?isAutoLogout=true`);
    };

    const onPrompt = () => {
        toggleIsInactivityTimeoutPrompted();
    };

    const onKeepWorking = () => {
        idleTimer.reset();
        toggleIsInactivityTimeoutPrompted();
    };
    const { isAuthenticated, userContext, logout, error } = useHapAuth();
    const [, setFeatureSettings] = useState<IFeatureSettings>();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const spanElement = useRef<HTMLSpanElement>(null);
    const [isFocused, setFocus] = useState(true);
    const isOldUrlMatch = GetNewApplicationUrl(window.location.origin) != null;
    const showApplicationUrlChangeBanner =
        isOldUrlMatch && sessionStorage.getItem("ApplicationUrlChangeBannerShown") !== "true";
    if (isAuthenticated && isOldUrlMatch && showApplicationUrlChangeBanner) {
        sessionStorage.setItem("ApplicationUrlChangeBannerShown", "true");
    }
    const [showBanner, { toggle: toggleShowBanner }] = useBoolean(showApplicationUrlChangeBanner);
    const [isInactivityTimeoutPrompted, { toggle: toggleIsInactivityTimeoutPrompted }] =
        useBoolean(false);
    const logger = getLogger();

    const [licenseGrant, setLicenseGrant] = useState<ILicenseGrant>();

    const encryption = new NccEncryption();

    useEffect(() => {
        if (spanElement.current && spanElement.current.getElementsByTagName("main").length > 0) {
            spanElement.current.getElementsByTagName("main")[0].focus();
        }
        if (isAuthenticated && !userContext.hasLicense(NmsLicense.NmcAdministrator)) {
            const fetchLicenseGrants = async () => {
                const userManagementSvc = new UserManagementSvc(userContext);
                const getNmcLicenseGrant = userManagementSvc.getLicenseGrant(
                    NmsLicense.NmcAdministrator
                );
                await getNmcLicenseGrant
                    .execute()
                    .then((resp: ILicenseGrant) => {
                        setLicenseGrant(resp);
                    })
                    .catch((e: AxiosError | NotFoundError | HapApiError | Error) => {
                        // call will return 404 if user does not have required grant
                        logger.logError(e.message);
                        if (e instanceof NotFoundError) setLicenseGrant(undefined);
                    });
            };
            fetchLicenseGrants();
        }

        /**
         * Clipboard management after authentication
         */
        if (isAuthenticated) {
            const atData = encryption.getLocalStorage(AUTOTEXT_CLIPBOARD_DATA_STORAGE_KEY);
            const vocabData = encryption.getLocalStorage(VOCABULARY_CLIPBOARD_DATA_STORAGE_KEY);
            if (atData) {
                const clipboardData = JSON.parse(atData) as INCCClipboardAutoTextData;

                /**
                 * If guid in clipboard data does not match current user guid, clear clipboard data
                 */
                if (clipboardData.userGuid !== userContext.user.guid) {
                    localStorage.removeItem(AUTOTEXT_CLIPBOARD_DATA_STORAGE_KEY);
                }
            }
            if (vocabData) {
                const clipboardData = JSON.parse(vocabData) as INCCClipboardVocabData;

                /**
                 * If guid in clipboard data does not match current user guid, clear clipboard data
                 */
                if (clipboardData.userGuid !== userContext.user.guid) {
                    localStorage.removeItem(VOCABULARY_CLIPBOARD_DATA_STORAGE_KEY);
                }
            }
        }
    }, [isFocused, isAuthenticated]);

    function hasNmcLicense() {
        return userContext.hasLicense(NmsLicense.NmcAdministrator) || licenseGrant;
    }

    const idleTimer = useIdleTimer({
        onIdle,
        onPrompt,
        timeout: 1000 * 60 * userContext.inactivityTimeOut,
        promptBeforeIdle: 1000 * Inactivity_Timeout_Warning_Duration
    });

    const onSkipToMainMenuButtonClickHandler = (): void => {
        setFocus(prevCheck => !prevCheck);
    };
    if (isAuthenticated) {
        //Creates a version 4 (random) UUID ex:⇨ '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed' and stores in local storage
        //Currently this UUID is logged with user info while changing password later this info can be used to validate against any un-intended password changes.
        localStorage.setItem("uuid", uuidv4());

        const featureSettings = computeEffectiveAppFeatureSet(props.appConfig, userContext);
        const links: INavLink[] = [];
        const navLinkGroups: IHuxNavLinkGroup[] = [{ links }];
        const routes: JSX.Element[] = [];
        if (
            (userContext?.isSuperUser() ||
                userContext?.hasGrant(NmsGrant.SuperUserReadOnly) ||
                userContext?.hasGrant(NmsGrant.ViewOrganization)) &&
            featureSettings.isAllowed(NccFeatures.Organization)
        ) {
            links.push({
                name: t("Organization.Organization_Title"),
                url: `/orgs/${userContext?.getDefaultOrganization().guid}/overview`,
                key: "/orgs",
                icon: "Org",
                title: ""
            });
            if (featureSettings.isAllowed(NccFeatures.Site)) {
                routes.push(
                    <Route
                        key="SitePage"
                        path="/orgs/:orgGuid/sites/:siteHash/*"
                        element={<SitePage />}
                    />
                );
            }

            routes.push(
                <Route
                    key="GroupPage"
                    path="/orgs/:orgGuidOrOrgUidHash/groups/:groupHash/*"
                    element={<GroupPage />}
                />
            );
            routes.push(
                <Route key="OrgsPage" path="/orgs/:orgGuid/*" element={<OrganizationPage />} />
            );
            routes.concat(
                ["/orgs", "/MyOrg"].map((path, idx) => {
                    return (
                        <Route
                            path={path}
                            key={idx}
                            element={<Navigate to={`/orgs/${userContext?.organization.guid}`} />}
                        />
                    );
                })
            );
        }
        routes.push(
            <Route
                key="userSpeechPage"
                path="/users/:userGuid/orgs/:orgGuidOrOrgUidHash/speech/:speechObjectType"
                element={<UserSpeechPage />}
            ></Route>
        );
        if (featureSettings.isAllowed(NccFeatures.User)) {
            routes.push(
                <Route
                    key="me"
                    path="/Me"
                    element={
                        <Navigate
                            to={`/users/${userContext?.user.guid}/orgs/${userContext?.organization.guid}`}
                        />
                    }
                />,
                <Route
                    key="userPage"
                    path="/users/:userGuid/orgs/:orgGuidOrOrgUidHash/*"
                    element={<UserPage />}
                />
            );
            routes.push(<Route key="userSearchPage" path="/users" element={<UserSearchPage />} />);
            links.push({
                name: t("Users.Users_Title"),
                url: "/users",
                key: "/users",
                icon: "Contact",
                title: ""
            });
            if (featureSettings.isAllowed(NccFeatures.ProductSettings)) {
                routes.push(
                    <Route
                        key="userSettingPage"
                        path="/users/:userGuid/orgs/:orgGuid/product/:productGuid"
                        element={<UserProductSettingsPage />}
                    />
                );
            }
        }
        if (
            featureSettings.isAllowed(NccFeatures.UserImport) &&
            doesUserHaveGrantsToViewUserImport(userContext)
        ) {
            routes.push(
                <Route
                    key="userImportDashboard"
                    path="/users/import"
                    element={<UserImportDashboard />}
                />
            );
        }
        if (featureSettings.isAllowed(NccFeatures.Group)) {
            routes.push(
                <Route key="groupSearchPage" path="/groups" element={<GroupSearchPage />} />
            );
            links.push({
                name: t("Groups.Groups_Title"),
                url: "/groups",
                key: "/groups",
                icon: "Group",
                title: ""
            });
        }
        if (featureSettings.isAllowed(NccFeatures.LicenseDetails)) {
            routes.push(
                <Route
                    key="licenseDetailsPage"
                    path="/orgs/:orgUidHash/product/:productGuid/license/:licenseTypeGuid/:hashedPartnerUID/mode/:licenseMode"
                    element={<LicenseDetailsPage />}
                />
            );

            if (
                userContext?.isSuperUser() ||
                userContext?.hasPrivilege(NmsPrivilege.ManageLicenses)
            ) {
                routes.push(
                    <Route
                        key="licenseSearchPage"
                        path="/licenses"
                        element={<LicenseSearchPage />}
                    />
                );
                links.push({
                    name: t("Licenses.Licenses_Title"),
                    url: "/licenses",
                    key: "/licenses",
                    icon: "Permissions",
                    title: ""
                });
            }
        }
        if (
            (userContext.isSuperUser() ||
                userContext.hasPrivilege(NmsPrivilege.ProductManageSpeechRecognition)) &&
            featureSettings.isAllowed(NccFeatures.SpeechRecognition)
        ) {
            links.push({
                name: t("SpeechRec.SpeechRec_Title"),
                url: `/speech/${userContext?.getDefaultOrganization().guid}/autoText`,
                key: "/speech",
                icon: "Microphone",
                title: ""
            });
            routes.push(
                <Route
                    key="speechRecognitionPage"
                    path="/speech/:orgGUID/*"
                    element={<SpeechRecognitionPage />}
                />
            );
            routes.push(
                <Route
                    key="speechRecognitionPage"
                    path="/speech/:orgGUID/:objectType/:hashedObjectUID/*"
                    element={<SpeechRecognitionPage />}
                />
            );
        }

        if (
            featureSettings.isAllowed(NccFeatures.SupportTools) &&
            (userContext?.isSuperUser() || userContext?.hasPrivilege(NmsPrivilege.SupportTools))
        ) {
            links.push({
                name: t("SupportTools.Support_Tools_Title"),
                url: "/support",
                key: "/support",
                icon: "processing"
            });
            routes.push(
                <Route key="supportToolsList" path="/support/*" element={<SupportToolsPage />} />
            );
        }

        if (
            (userContext?.isSuperUser() || userContext?.hasPrivilege(NmsPrivilege.ViewAnalytics)) &&
            featureSettings.isAllowed(NccFeatures.Analytics)
        ) {
            links.push({
                name: t("Analytics.Analytics_Title"),
                url: `/analytics/${userContext?.getDefaultOrganization().guid}`,
                key: "/analytics",
                icon: "LineChart",
                title: ""
            });
            routes.push(
                <Route
                    key="analyticsLandingPage"
                    path="/analytics/:orgGuid"
                    element={<AnalyticsLandingPage />}
                />,
                <Route
                    key="analyticsReportPage"
                    path="/analytics/:orgGuid/:reportId"
                    element={<AnalyticsReportPage />}
                />
            );
        }
        if (featureSettings.isAllowed(NccFeatures.LicenseDetails)) {
            routes.push(
                <Route
                    key="settingPage"
                    path="/orgs/:orgGuid/product/:productGuid/*"
                    element={<ProductPage />}
                />
            );
        }
        if (featureSettings.isAllowed(NccFeatures.OrgGroup)) {
            routes.push(
                <Route
                    key="orgGroupPage"
                    path="/orgs/:orgGuidOrOrgUidHash/orgGroups/:orgGroupHash/*"
                    element={<OrgGroupPage />}
                />
            );
        }
        const navLinkClickHandler = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink): void => {
            ev?.preventDefault();
            if (item) {
                navigate(item.url);
            }
        };
        const path = `/${location.pathname.split("/")[1]}`;
        const navProps: IHuxAppNavProps = {
            navLinkGroups,
            selectedKey: path,
            onLinkClick: navLinkClickHandler
        };

        const nccTitleBarProps: NCCTitleBarProps = {
            isAuthenticated: isAuthenticated,
            onClickHandler: onSkipToMainMenuButtonClickHandler
        };

        return (
            <HuxFeatureContext.Provider
                value={{ settings: featureSettings, setSettings: setFeatureSettings }}
            >
                <div style={containerStyles}>
                    <div style={headerStyles}>
                        <NCCTitleBar {...nccTitleBarProps} />
                    </div>
                    {(!hasNmcLicense() || links.length <= 0) && (
                        <span style={pageStyles}>
                            <NotAuthorizedPage />
                        </span>
                    )}
                    {hasNmcLicense() && links.length > 0 && (
                        <>
                            <span style={navStyles}>
                                <HuxAppNav {...navProps}></HuxAppNav>
                            </span>
                            <span style={pageStyles} ref={spanElement}>
                                <HuxMessageBar pageId={location.key}></HuxMessageBar>
                                <Suspense
                                    fallback={<Spinner label={t("Progress.Spinner_Loading")} />}
                                >
                                    <Routes>
                                        {routes}
                                        {}
                                        <Route
                                            path="/analytics"
                                            element={
                                                <Navigate
                                                    to={`/analytics/${userContext?.organization.guid}`}
                                                />
                                            }
                                        />
                                        <Route path="/" element={<Navigate to={links[0].url} />} />
                                        <Route path="*" element={<NotAuthorizedPage />} />
                                    </Routes>
                                </Suspense>
                            </span>
                        </>
                    )}
                </div>
                {isInactivityTimeoutPrompted && (
                    <InactivityTimeoutDialog
                        isOpen={isInactivityTimeoutPrompted}
                        inactivityTimeoutWarningDuration={Inactivity_Timeout_Warning_Duration}
                        onKeepWorking={onKeepWorking}
                    ></InactivityTimeoutDialog>
                )}
                {showBanner && (
                    <ApplicationChangeURLBannerDialog
                        onClose={toggleShowBanner}
                    ></ApplicationChangeURLBannerDialog>
                )}
            </HuxFeatureContext.Provider>
        );
    } else if (error) {
        return (
            <>
                <NCCTitleBar />
                <NotAuthorizedPage />
            </>
        );
    } else {
        return (
            <>
                <NCCTitleBar />
                <Spinner label={t("Progress.Spinner_Loading")} style={{ height: "80%" }} />
            </>
        );
    }
}
