/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Spinner } from "@fluentui/react";

const DEFAULT_REACT_QUERY_STALE_TIME = 5 * 60 * 1000;

initializeIcons();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: DEFAULT_REACT_QUERY_STALE_TIME
        }
    }
});

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(
    <QueryClientProvider client={queryClient}>
        <React.Suspense fallback={<Spinner />}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.Suspense>
    </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
