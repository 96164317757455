/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */
import React from "react";
import { Stack, Text, FontWeights, mergeStyleSets } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { GeneralErrorSVG } from "./GeneralErrorSVG";

/**
 * Page that is displayed when a page cannot be rendered on an unhandled exception (general error)
 *
 * @returns JSX Element
 */
export const GeneralErrorPage = (): JSX.Element => {
    const { t } = useTranslation();

    const styles = mergeStyleSets({
        root: {
            textAlign: "center",
            marginTop: 24,
            marginBottom: 24
        },
        titleTextStyle: {
            fontSize: "18px",
            fontWeight: FontWeights.bold,
            marginTop: 28
        },
        detailTextStyle: {
            fontSize: "14px",
            marginTop: 8
        }
    });

    return (
        <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
            <GeneralErrorSVG />

            <Text block className={styles.titleTextStyle}>
                {t("Error.Generic")}
            </Text>
            <Text block className={styles.detailTextStyle}>
                {t("Error.Problem_Persists_Text")}
            </Text>
        </Stack>
    );
};
