/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

import { ISelection, MessageBarType, SelectionMode, Selection } from "@fluentui/react";
import {
    IGetUsersSearchParameters,
    IUser,
    useHapAuth,
    UserManagementSvc
} from "@nuance/hap-components";
import {
    formatUserName,
    HuxDetailsList,
    IHuxDetailsListColumn,
    IHuxDetailsListItem,
    IHuxMessageProps,
    INoItemsAvailableProps,
    NoItemsType,
    UserNameFormat
} from "@nuance/hux-components";
import { getLogger } from "@nuance/hux-diagnostics";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { getUserTypeString } from "../users/UserFunctions";
import { UserHoverCard } from "./UserHoverCard";
import { getUserStatus, renderUserStatus } from "../components/UserStatus";

const INFINITE_QUERY_TAKE = 1000;

export interface IAvailableUsersListProps {
    /** currently enrolled user UIDs */
    currentUIDs: number[];
    /** selected user UIDs */
    selectedUIDs: number[];
    /** user search criteria, skip and take not required */
    searchCriteria?: Partial<IGetUsersSearchParameters>;
    /** callback when the search is complete */
    onSearchCompleted: (resultsCount: number) => void;
    /** callback when a user is selected */
    onUserSelected: (users: IUser[]) => void;
    /* Whether to disable row select */
    disableSelect?: boolean;
    /* Whether to clear selection */
    clearSelection?: boolean;
    /* Selection override for details list */
    selection?: ISelection;
    /* Optional ID for the available users list */
    id?: string;
    /* Whether to refetch users */
    refetchUsers?: boolean;
}

/**
 * Component for displaying a list of users based on desired search parameters
 *
 * @param props IUserListComponentProps
 * @returns JSX.Element
 */
export const AvailableUsersList = (props: IAvailableUsersListProps) => {
    const { userContext } = useHapAuth();
    const { t } = useTranslation();
    const logger = getLogger();
    const queryClient = useQueryClient();

    const [currentlySelectedRows, setCurrentlySelectedRows] = useState<number[]>([]);

    const emptySearchMsg = {
        type: NoItemsType.NoResults,
        heading: t("Search.Error.No_Results_Heading"),
        subHeading: t("Search.Error.No_Results_Text")
    };

    const startSearchMsg = {
        type: NoItemsType.StartSearch,
        heading: t("Search.Start_Search_Heading")
    };

    const noMoreResults = {
        type: NoItemsType.NoResults,
        heading: t("Search.Error.No_More_Results_Heading"),
        subHeading: t("Search.Error.No_More_Results_Text")
    };

    const [emptyStateProps, setEmptyStateProps] = useState<INoItemsAvailableProps>(startSearchMsg);

    const userManagementSvc = new UserManagementSvc(userContext);
    // create initial request so we have access to cache key
    const getUsersReq = userManagementSvc.getUsers({
        ...props.searchCriteria,
        skip: 0,
        take: INFINITE_QUERY_TAKE
    });

    const fetchUsers = (skip: number) => {
        /** reset users and doneFetching if a new search */
        return userManagementSvc
            .getUsers({
                ...props.searchCriteria,
                skip,
                take: INFINITE_QUERY_TAKE
            })
            .execute();
    };

    const {
        error,
        data,
        isPending: isLoading,
        fetchNextPage
    } = useInfiniteQuery({
        queryKey: getUsersReq.cacheKey,
        queryFn: ({ pageParam = 0 }) => {
            return fetchUsers(pageParam);
        },
        enabled: props.searchCriteria !== undefined,
        gcTime: 0,
        initialPageParam: 0,
        getNextPageParam: (lastPage: IUser[], allPages: IUser[][], lastPageParam: number) => {
            if (lastPage.length === INFINITE_QUERY_TAKE) {
                return lastPageParam + INFINITE_QUERY_TAKE;
            }
            return undefined; // If last page has less items then we asked then there are no more pages.
        },
        placeholderData: previousData => previousData
    });

    useEffect(() => {
        if (
            data &&
            data.pages.length > 0 &&
            data.pages[data.pages.length - 1].length === INFINITE_QUERY_TAKE
        ) {
            // If the previous page has as many entries as we requested, there may be more pages to fetch.
            fetchNextPage();
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            const msgProps: IHuxMessageProps = {
                message: t("Error.Unable_to_Load"),
                messageBarType: MessageBarType.error
            };
            postMessage(msgProps);
        }
    }, [data, error]);

    const users = useMemo(() => {
        return data?.pages.flat();
    }, [data]);

    const filteredUsers = useMemo(() => {
        const usersAfterFilter = users
            // do not show users who are in currentUIDs
            ?.filter((user: IUser) => {
                return props.currentUIDs.indexOf(user.UID) === -1;
            });
        // Report the number of search results to parent after filtering
        props.onSearchCompleted(usersAfterFilter?.length ?? 0);

        return usersAfterFilter ?? [];
    }, [users]);

    /** Items to populate the details list */
    const items = useMemo(() => {
        return (
            filteredUsers
                // Mapping returned data to fit into details list
                .map((user, index) => {
                    return {
                        id: formatUserName(user, UserNameFormat.LFM),
                        index,
                        login: user.Login,
                        email: user.EMailAddress,
                        department: user.Department,
                        primarySpecialty: user.PrimarySpecialty,
                        userType: getUserTypeString(user.UserType, t),
                        groupCount: user.GroupEnrollmentCount ?? 0,
                        key: user.UID,
                        status: getUserStatus(user.Disabled, user.PasswordLockoutDate)
                    };
                })
        );
    }, [filteredUsers]);

    /**
     * Actions taken place when the user hovers over a specific user in the name column
     * @param item IHuxDetailsListItem
     * @returns JSX.Element
     */
    const onRenderHoverCard = (item: IHuxDetailsListItem) => {
        if (filteredUsers && items && filteredUsers[item.index] !== undefined) {
            const user = filteredUsers[item.index];
            return <UserHoverCard user={user} readonly />;
        }
        return <></>;
    };

    /** Columns to be shown in the details list */
    const columns: IHuxDetailsListColumn[] = [
        {
            key: "nameCol",
            name: t("Groups.MembersTable.Name_Head"),
            fieldName: "id",
            minWidth: 200,
            isResizable: true,
            onRenderHoverCard: onRenderHoverCard
        },
        {
            key: "usernameCol",
            name: t("Groups.MembersTable.Username_Head"),
            fieldName: "login",
            minWidth: 200,
            isResizable: true
        },
        {
            key: "emailCol",
            name: t("Groups.MembersTable.Email_Head"),
            fieldName: "email",
            minWidth: 150,
            isResizable: true
        },
        {
            key: "statusCol",
            name: t("Groups.MembersTable.Status_Head"),
            fieldName: "status",
            minWidth: 120,
            isResizable: true,
            onRender: (item: IHuxDetailsListItem) => renderUserStatus(item, t)
        },
        {
            key: "departmentCol",
            name: t("Groups.MembersTable.Department_Head"),
            fieldName: "department",
            minWidth: 150,
            isResizable: true
        },
        {
            key: "primarySpecialtyCol",
            name: t("Groups.MembersTable.Primary_Specialty_Head"),
            fieldName: "primarySpecialty",
            minWidth: 150,
            isResizable: true
        },
        {
            key: "userTypeCol",
            name: t("Groups.MembersTable.User_Type_Head"),
            fieldName: "userType",
            minWidth: 100,
            isResizable: true
        }
    ];

    /** useEffect for when a row is selected */
    useEffect(() => {
        if (!props.disableSelect && users && items) {
            const selectedUsers = currentlySelectedRows.map(row => {
                const selectedUser = users.find(user => user.UID === items[row].key);
                if (selectedUser === undefined) {
                    logger.logError("Error: Could not select user");
                    throw Error("Error: Could not select user");
                }
                return selectedUser;
            });
            props.onUserSelected(selectedUsers);
        }
    }, [currentlySelectedRows]);

    const [selection] = useState<ISelection>(
        new Selection({
            selectionMode: SelectionMode.multiple,
            onSelectionChanged: () => {
                const selectedRows = selection.getSelectedIndices();
                setCurrentlySelectedRows(selectedRows);
            }
        })
    );

    /** useEffect for clearing selection */
    useEffect(() => {
        if (props.clearSelection && currentlySelectedRows.length > 0) {
            setCurrentlySelectedRows([]);
            selection.setAllSelected(false);
        }
    }, [props.clearSelection]);

    /** useEffect for refetching users (after a selection is enrolled) */
    useEffect(() => {
        if (props.refetchUsers && currentlySelectedRows.length > 0) {
            setCurrentlySelectedRows([]);
            queryClient.invalidateQueries({ queryKey: getUsersReq.cacheKey });
        }

        if (props.refetchUsers && emptyStateProps !== noMoreResults && props.searchCriteria) {
            // Same search, but re-fetched users; empty state would indicate no more results
            setEmptyStateProps(noMoreResults);
        } else if (
            // New search
            !props.refetchUsers &&
            emptyStateProps !== emptySearchMsg &&
            props.searchCriteria
        ) {
            setEmptyStateProps(emptySearchMsg);
        }
    }, [props.refetchUsers, props.searchCriteria]);

    return (
        <>
            {!error && (
                <>
                    <HuxDetailsList
                        id={props.id ?? "usersTable"}
                        isLoading={props.searchCriteria !== undefined && isLoading}
                        columns={columns}
                        items={items ?? []}
                        selectionMode={
                            props.disableSelect ? SelectionMode.none : SelectionMode.multiple
                        }
                        onShouldVirtualize={() => {
                            // true by default, but turn off for tests, otherwise only renders 10 rows
                            return process.env.NODE_ENV !== "test";
                        }}
                        selection={props.selection ?? selection}
                        ariaLabelForGrid={t("Users.Search.Results_Title")}
                        emptyStateProps={emptyStateProps}
                        isSelectedOnFocus={false}
                    ></HuxDetailsList>
                </>
            )}
        </>
    );
};
