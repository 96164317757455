/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

import { Stack, Text, StackItem, ActionButton } from "@fluentui/react";
import { HuxControlLabel, IHuxPinListItem } from "@nuance/hux-components";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBoolean } from "@fluentui/react-hooks";
import {
    HapApiError,
    HapOrgSearchPanelProps,
    HapOrgSelectorPanel,
    IOrganization,
    NotFoundError,
    UserManagementSvc,
    useHapAuth
} from "@nuance/hap-components";
import { AxiosError } from "axios";
import { getLogger } from "@nuance/hux-diagnostics";
import * as Yup from "yup";
import { errorStyle } from "../CommonStyles";

const logger = getLogger();

/**
 * The form data for Organization form control
 */
export interface IOrganizationDataItem {
    /**
     * The selected organization Uid.
     */
    OrganizationUid?: number;
}

/**
 * The properties for Organization form entry component
 */
export interface IOrganizationFormEntryProps {
    /**
     * The UID of the organization to be selected by default.
     */
    organizationUid?: number;
    /**
     * Callback on organization change.
     */
    onOrganizationChange: (Org: IOrganization) => void;
    /**
     * Callback on error while loading organization.
     */
    onLoadingError: (errorMessage: string) => void;
    /**
     * The event type for change organization button click.
     */
    changOrgLinkClickedEventType: string;
}

/**
 * @description Organization validation schema used by itself or to concatenate to another validation schema and then initialize formik
 * @returns Organization Validation schema to use with formik
 */
export const getOrganizationValidationSchema = () => {
    return Yup.object({
        OrganizationUid: Yup.number().nonNullable()
    });
};

/**
 * @description OrganizationFormEntry component is form component for organization.
 * @param props {IOrganizationFormEntryProps} - The props containing properties for OrganizationFormEntry component.
 * @returns The OrganizationFormEntry component.
 */
export function OrganizationFormEntry(props: IOrganizationFormEntryProps): JSX.Element {
    const { t } = useTranslation();
    const { userContext } = useHapAuth();
    const [isOrgPanelOpen, { toggle: toggleIsOrgPanelOpen }] = useBoolean(false);
    const [organization, setOrganization] = useState<IOrganization>();
    useEffect(() => {
        if (props.organizationUid) {
            getOrganizationInfo(props.organizationUid);
        }
    }, [props.organizationUid]);
    const userManagementSvc = new UserManagementSvc(userContext);

    const formik = useFormikContext<IOrganizationDataItem>();

    if (formik === undefined)
        throw new Error("You must wrap ChangeOrganizationFormEntry component in <FormikProvider>");

    const formIdOrganizationUid: keyof IOrganizationDataItem = "OrganizationUid";

    const changeOrgPanelProps: HapOrgSearchPanelProps = {
        isOpen: isOrgPanelOpen,
        dismissPanel: toggleIsOrgPanelOpen,
        onSelectHandler: (item: IHuxPinListItem) => {
            toggleIsOrgPanelOpen();
            getOrganizationInfo(item.uid);
        },
        title: t("SelectOrg.Change_Organization_Title")
    };

    const getOrganizationInfo = (organizationUid: number) => {
        userManagementSvc
            .getOrganizationByUID(organizationUid)
            .execute()
            .then(async org => {
                setOrganization(org);
                formik.setFieldValue(formIdOrganizationUid, org.UID);
                formik.setFieldValue("organization", org);
                props.onOrganizationChange(org);
            })
            .catch((e: AxiosError | HapApiError | Error | NotFoundError) => {
                logger.logError("Error while getting organization", {
                    message: e.message
                });
                props.onLoadingError(t("Error.Unable_to_Load"));
            });
    };

    return (
        <>
            <Stack>
                <HuxControlLabel
                    labelText={t("Users.BasicInfo.Organization.Label")}
                ></HuxControlLabel>

                <Stack
                    style={{ paddingBottom: 12 }}
                    enableScopedSelectors
                    horizontal
                    horizontalAlign="space-between"
                >
                    <StackItem>
                        <Text>
                            {organization
                                ? organization.Name
                                : props.organizationUid
                                  ? ""
                                  : t("SelectOrg.None_Value")}
                        </Text>
                    </StackItem>
                    {userContext.multiOrg && (
                        <StackItem>
                            <ActionButton
                                iconProps={{
                                    iconName: "Switch"
                                }}
                                style={{ height: 20 }}
                                onClick={() => {
                                    toggleIsOrgPanelOpen();
                                    logger.trackEvent(props.changOrgLinkClickedEventType);
                                }}
                            >
                                {t("SelectOrg.Change_Organization_Link")}
                            </ActionButton>
                        </StackItem>
                    )}
                </Stack>
                {!props.organizationUid && !organization && (
                    <Text style={errorStyle}>{t("SelectOrg.Select_Org_Hint")}</Text>
                )}
            </Stack>
            <HapOrgSelectorPanel {...changeOrgPanelProps} />
        </>
    );
}
