/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

/**
 * @description DisabledSVG component contains SVG for Check mark icon
 * @returns The DisabledSVG component.
 */
export const DisabledSVG = () => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 1C3.23858 1 1 3.23858 1 6C1 7.20063 1.42318 8.30243 2.12853 9.16437L9.16437 2.12853C8.30243 1.42318 7.20063 1 6 1ZM9.87147 2.83563L2.83563 9.87147C3.69757 10.5768 4.79937 11 6 11C8.76142 11 11 8.76142 11 6C11 4.79937 10.5768 3.69757 9.87147 2.83563ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z"
                fill="#D83B01"
            />
        </svg>
    );
};
