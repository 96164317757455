/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

import { NmsLicense, PartnerGuids } from "@nuance/hap-components";

/** Record for getting the string identifier for the NMS Licenses */
const licenseToolTipStringIdentifiers: Record<string, string | undefined> = {
    [NmsLicense.CAPD + PartnerGuids.EPIC]: "License_Tooltips.CAPD_EPIC",
    [NmsLicense.DragonMedicalSpeechKitEmbedded + PartnerGuids.NuanceSpeechOnDemand]:
        "License_Tooltips.DragonMedicalSpeechKit_NuanceSpeechOnDemand",
    [NmsLicense.DragonMedicalSpeechKitEmbedded + PartnerGuids.NuanceDorado]:
        "License_Tooltips.DragonMedicalSpeechKit_NuanceDorado",
    [NmsLicense.DragonMedicalSpeechKitEmbedded + PartnerGuids.NuancePowerScribe360]:
        "License_Tooltips.DragonMedicalSpeechKit_NuancePowerScribe360",
    [NmsLicense.DragonMedicalSpeechKitEmbedded + PartnerGuids.AthenaHealth]:
        "License_Tooltips.DragonMedicalSpeechKit_AthenaHealth",
    [NmsLicense.DragonMedicalSpeechKitEmbedded + PartnerGuids.Cerner]:
        "License_Tooltips.DragonMedicalSpeechKit_Cerner",
    [NmsLicense.DragonMedicalSpeechKitEmbedded + PartnerGuids.Meditech]:
        "License_Tooltips.DragonMedicalSpeechKit_Meditech",
    [NmsLicense.DragonMedicalSpeechKitEmbedded + PartnerGuids.Vincari]:
        "License_Tooltips.DragonMedicalSpeechKit_Vincari",
    [NmsLicense.DragonMedicalSpeechKitEmbedded + PartnerGuids.EPIC]:
        "License_Tooltips.DragonMedicalSpeechKit_EPIC",
    [NmsLicense.DragonMedicalSpeechKitEmbedded + PartnerGuids.EpicRover]:
        "License_Tooltips.DragonMedicalSpeechKit_EPICRover",
    [NmsLicense.DragonMedicalVirtualAssistantPhysician + PartnerGuids.EPIC]:
        "License_Tooltips.DragonMedicalVirtualAssistantPhysician_EPIC",
    [NmsLicense.DragonMedicalVirtualAssistantNursePa + PartnerGuids.EpicRover]:
        "License_Tooltips.DragonMedicalVirtualAssistantNursePA_EPICRover",
    [NmsLicense.MUSE + PartnerGuids.Nuance]: "License_Tooltips.MUSE_Nuance",
    [NmsLicense.MUSE + PartnerGuids.EPIC]: "License_Tooltips.MUSE_EPIC",
    [NmsLicense.MUSE + PartnerGuids.Meditech]: "License_Tooltips.MUSE_Meditech",
    [NmsLicense.MUSE + PartnerGuids.NuancePowerScribe360]:
        "License_Tooltips.MUSE_NuancePowerScribe360"
};

/**
 * Get string identifiers for tooltip for license guid
 * @param licenseGuid license guid to get string identifier
 * @param partnerGuid partner guid to get string identifier
 * @returns String identifier for license tooltip
 */
export const getLicenseToolTipStringIdentifier = (licenseGuid: string, partnerGuid: string) => {
    return licenseToolTipStringIdentifiers[licenseGuid + partnerGuid];
};
