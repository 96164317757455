/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */
import { useTranslation } from "react-i18next";

export interface IRequiredAsteriskKeyProps {
    /** Optional style override for the required asterisk*/
    style?: React.CSSProperties;
}

/*
 * Styling for the required asterisk key
 */
export const requiredStyle = {
    color: "#a4262c",
    marginTop: "16px",
    marginBottom: "16px"
};

/*
 * Explains that the red asterisk indicates a required field
 * by providing a "* = required" key.
 */
export const RequiredAsteriskKey = (props: IRequiredAsteriskKeyProps) => {
    const { t } = useTranslation();
    return <div style={props.style ?? requiredStyle}>{t("Error.Validation.Required_Key")}</div>;
};
