/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import React from "react";
import { Stack, Text, FontWeights, mergeStyleSets } from "@fluentui/react";
import { FailedToLoadSVG } from "./FailedToLoadSVG";
import { useTranslation } from "react-i18next";

/**
 * Page that is displayed when a page cannot be rendered due to a= failed API call(s)
 *
 * @returns JSX Element
 */
export const FailedToLoadPage = (): JSX.Element => {
    const { t } = useTranslation();

    const styles = mergeStyleSets({
        root: {
            textAlign: "center",
            marginTop: 24,
            marginBottom: 24
        },
        titleTextStyle: {
            fontSize: "18px",
            fontWeight: FontWeights.bold,
            marginTop: 28
        },
        detailTextStyle: {
            fontSize: "14px",
            marginTop: 8
        }
    });

    return (
        <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
            <FailedToLoadSVG />

            <Text block className={styles.titleTextStyle}>
                {t("Error.Unable_to_Load")}
            </Text>
            <Text block className={styles.detailTextStyle}>
                {t("Error.Problem_Persists_Text")}
            </Text>
        </Stack>
    );
};
