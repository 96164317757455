/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

/**
 * SVG to display on a general error.
 */
export const GeneralErrorSVG = () => {
    return (
        <svg
            width="200"
            height="200"
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <circle cx="100" cy="100" r="100" fill="#BFBFBF" />
            <g style={{ mixBlendMode: "multiply" }} filter="url(#filter0_dd_515_6288)">
                <path
                    d="M152 59H48V107C48 134.712 69.6772 157.361 97 158.915V175C97 176.657 98.3431 178 100 178C101.657 178 103 176.657 103 175V158.915C130.323 157.361 152 134.712 152 107V59Z"
                    fill="white"
                />
            </g>
            <g filter="url(#filter1_b_515_6288)">
                <path
                    d="M152 59H48V107C48 134.712 69.6772 157.361 97 158.915V175C97 176.657 98.3431 178 100 178C101.657 178 103 176.657 103 175V158.915C130.323 157.361 152 134.712 152 107V59Z"
                    fill="url(#paint0_linear_515_6288)"
                />
            </g>
            <g style={{ mixBlendMode: "hard-light" }} filter="url(#filter2_ii_515_6288)">
                <path
                    d="M152 59H48V107C48 134.712 69.6772 157.361 97 158.915V175C97 176.657 98.3431 178 100 178C101.657 178 103 176.657 103 175V158.915C130.323 157.361 152 134.712 152 107V59Z"
                    fill="#808080"
                />
                <path
                    d="M152 59H48V107C48 134.712 69.6772 157.361 97 158.915V175C97 176.657 98.3431 178 100 178C101.657 178 103 176.657 103 175V158.915C130.323 157.361 152 134.712 152 107V59Z"
                    fill="url(#pattern0)"
                    fillOpacity="0.02"
                />
            </g>
            <g style={{ mixBlendMode: "multiply" }} filter="url(#filter3_dd_515_6288)">
                <path d="M69 41H85V59H69V41Z" fill="white" />
            </g>
            <g filter="url(#filter4_b_515_6288)">
                <path d="M69 41H85V59H69V41Z" fill="url(#paint1_linear_515_6288)" />
            </g>
            <g style={{ mixBlendMode: "hard-light" }} filter="url(#filter5_ii_515_6288)">
                <path d="M69 41H85V59H69V41Z" fill="#808080" />
                <path d="M69 41H85V59H69V41Z" fill="url(#pattern1)" fillOpacity="0.02" />
            </g>
            <g style={{ mixBlendMode: "multiply" }} filter="url(#filter6_dd_515_6288)">
                <path d="M115 41H131V59H115V41Z" fill="white" />
            </g>
            <g filter="url(#filter7_b_515_6288)">
                <path d="M115 41H131V59H115V41Z" fill="url(#paint2_linear_515_6288)" />
            </g>
            <g style={{ mixBlendMode: "hard-light" }} filter="url(#filter8_ii_515_6288)">
                <path d="M115 41H131V59H115V41Z" fill="#808080" />
                <path d="M115 41H131V59H115V41Z" fill="url(#pattern2)" fillOpacity="0.02" />
            </g>
            <g style={{ mixBlendMode: "multiply" }} filter="url(#filter9_dd_515_6288)">
                <path
                    d="M37 59H163V67C163 73.6274 157.627 79 151 79H49C42.3726 79 37 73.6274 37 67V59Z"
                    fill="white"
                />
            </g>
            <g filter="url(#filter10_b_515_6288)">
                <path
                    d="M37 59H163V67C163 73.6274 157.627 79 151 79H49C42.3726 79 37 73.6274 37 67V59Z"
                    fill="url(#paint3_linear_515_6288)"
                />
            </g>
            <g style={{ mixBlendMode: "hard-light" }} filter="url(#filter11_ii_515_6288)">
                <path
                    d="M37 59H163V67C163 73.6274 157.627 79 151 79H49C42.3726 79 37 73.6274 37 67V59Z"
                    fill="#808080"
                />
                <path
                    d="M37 59H163V67C163 73.6274 157.627 79 151 79H49C42.3726 79 37 73.6274 37 67V59Z"
                    fill="url(#pattern3)"
                    fillOpacity="0.02"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 101C19.9474 101.339 18.3387 102.947 18 105C17.6613 102.947 16.0526 101.339 14 101C16.0526 100.661 17.6613 99.0526 18 97C18.3387 99.0526 19.9474 100.661 22 101Z"
                fill="#F2F2F2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34 110C31.9474 110.339 30.3387 111.947 30 114C29.6613 111.947 28.0526 110.339 26 110C28.0526 109.661 29.6613 108.053 30 106C30.3387 108.053 31.9474 109.661 34 110Z"
                fill="#F2F2F2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 87C22.1051 87.6775 25.3225 90.8949 26 95C26.6775 90.8949 29.8949 87.6775 34 87C29.8949 86.3225 26.6775 83.1051 26 79C25.3225 83.1051 22.1051 86.3225 18 87Z"
                fill="#F2F2F2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M174 108C171.947 108.339 170.339 109.947 170 112C169.661 109.947 168.053 108.339 166 108C168.053 107.661 169.661 106.053 170 104C170.339 106.053 171.947 107.661 174 108Z"
                fill="#F2F2F2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M169 91C173.105 91.6775 176.323 94.8949 177 99C177.677 94.8949 180.895 91.6775 185 91C180.895 90.3225 177.677 87.1051 177 83C176.323 87.1051 173.105 90.3225 169 91Z"
                fill="#F2F2F2"
            />
            <path d="M102 114.9V96L88 119.1H98V138L112 114.9H102Z" fill="#BFBFBF" />
            <defs>
                <filter
                    id="filter0_dd_515_6288"
                    x="40"
                    y="52"
                    width="120"
                    height="135"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_515_6288"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0.14 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_515_6288"
                        result="effect2_dropShadow_515_6288"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_515_6288"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_b_515_6288"
                    x="46"
                    y="57"
                    width="108"
                    height="123"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_515_6288"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_515_6288"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_ii_515_6288"
                    x="48"
                    y="59"
                    width="104"
                    height="119"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0.5 0"
                    />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_515_6288" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="3" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_innerShadow_515_6288"
                        result="effect2_innerShadow_515_6288"
                    />
                </filter>
                <pattern
                    id="pattern0"
                    patternContentUnits="objectBoundingBox"
                    width="0.576923"
                    height="0.504202"
                >
                    <use xlinkHref="#image0_515_6288" transform="scale(0.00961538 0.00840336)" />
                </pattern>
                <filter
                    id="filter3_dd_515_6288"
                    x="61"
                    y="34"
                    width="32"
                    height="34"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_515_6288"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0.14 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_515_6288"
                        result="effect2_dropShadow_515_6288"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_515_6288"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter4_b_515_6288"
                    x="67"
                    y="39"
                    width="20"
                    height="22"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_515_6288"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_515_6288"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter5_ii_515_6288"
                    x="69"
                    y="41"
                    width="16"
                    height="18"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0.5 0"
                    />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_515_6288" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="3" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_innerShadow_515_6288"
                        result="effect2_innerShadow_515_6288"
                    />
                </filter>
                <pattern
                    id="pattern1"
                    patternContentUnits="objectBoundingBox"
                    width="3.75"
                    height="3.33333"
                >
                    <use xlinkHref="#image0_515_6288" transform="scale(0.0625 0.0555556)" />
                </pattern>
                <filter
                    id="filter6_dd_515_6288"
                    x="107"
                    y="34"
                    width="32"
                    height="34"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_515_6288"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0.14 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_515_6288"
                        result="effect2_dropShadow_515_6288"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_515_6288"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter7_b_515_6288"
                    x="113"
                    y="39"
                    width="20"
                    height="22"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_515_6288"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_515_6288"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter8_ii_515_6288"
                    x="115"
                    y="41"
                    width="16"
                    height="18"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0.5 0"
                    />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_515_6288" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="3" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_innerShadow_515_6288"
                        result="effect2_innerShadow_515_6288"
                    />
                </filter>
                <pattern
                    id="pattern2"
                    patternContentUnits="objectBoundingBox"
                    width="3.75"
                    height="3.33333"
                >
                    <use xlinkHref="#image0_515_6288" transform="scale(0.0625 0.0555556)" />
                </pattern>
                <filter
                    id="filter9_dd_515_6288"
                    x="29"
                    y="52"
                    width="142"
                    height="36"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_515_6288"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0 0.25098 0 0 0 0.14 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_515_6288"
                        result="effect2_dropShadow_515_6288"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_515_6288"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter10_b_515_6288"
                    x="35"
                    y="57"
                    width="130"
                    height="24"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_515_6288"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_515_6288"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter11_ii_515_6288"
                    x="37"
                    y="59"
                    width="126"
                    height="20"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0.5 0"
                    />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_515_6288" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="3" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_innerShadow_515_6288"
                        result="effect2_innerShadow_515_6288"
                    />
                </filter>
                <pattern
                    id="pattern3"
                    patternContentUnits="objectBoundingBox"
                    width="0.47619"
                    height="3"
                >
                    <use xlinkHref="#image0_515_6288" transform="scale(0.00793651 0.05)" />
                </pattern>
                <linearGradient
                    id="paint0_linear_515_6288"
                    x1="100"
                    y1="178"
                    x2="100"
                    y2="59"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F2F2F2" stopOpacity="0.5" />
                    <stop offset="1" stopColor="#F2F2F2" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_515_6288"
                    x1="77"
                    y1="59"
                    x2="77"
                    y2="41"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F2F2F2" stopOpacity="0.5" />
                    <stop offset="1" stopColor="#F2F2F2" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_515_6288"
                    x1="123"
                    y1="59"
                    x2="123"
                    y2="41"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F2F2F2" stopOpacity="0.5" />
                    <stop offset="1" stopColor="#F2F2F2" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_515_6288"
                    x1="100"
                    y1="79"
                    x2="100"
                    y2="59"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F2F2F2" stopOpacity="0.5" />
                    <stop offset="1" stopColor="#F2F2F2" />
                </linearGradient>
                <image id="image0_515_6288" width="60" height="60" />
            </defs>
        </svg>
    );
};
