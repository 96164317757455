/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

import { ITextStyles, Link, Text, useTheme } from "@fluentui/react";
import { getLogger } from "@nuance/hux-diagnostics";
import { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import { ITrackEventInfo } from "../../EventTypes";
import { ISVGImageProps } from "../SVG/SVGImageProps";
import { IconPlacement } from "./IconPlacement";
const logger = getLogger();

export interface ISpecializedNameWithIconPropsBase {
    /** Optional Width and height of the image */
    iconSize?: ISVGImageProps;

    /** Optional icon placement.  If not specified, icon shown at the start */
    placement?: IconPlacement;

    /**
     * Optional custom track event information.  If not provided, a default OrgNameLinkClicked event is used.
     */
    trackEventInfo?: ITrackEventInfo;

    /** Optional Flag to hide showing hyperlink */
    noLink?: boolean;
}

/**
 * Interface of the properties for NameWithIcon component
 */
export interface INameWithIconProps {
    /** Name to display */
    name: string;
    /** Icon to show with the name */
    icon: JSX.Element;
    /** Link that wraps the name */
    href?: string;
    /** Icon placement.  If not specified, icon shown at end */
    placement?: IconPlacement;
    /** Event type to track */
    trackEventInfo?: ITrackEventInfo;
}

/**
 * Object name with icon component
 * @param props NameWithIcon properties
 * @returns Name with icon
 */
export const NameWithIcon = (props: INameWithIconProps) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const isStartPlacement = props.placement === IconPlacement.Start;

    const evaluatedTextStyle: Partial<ITextStyles> = {
        root: {
            paddingInlineStart: isStartPlacement ? 5 : 0,
            paddingInlineEnd: !isStartPlacement ? 5 : 0,
            color: props.href !== undefined ? theme.palette.themeDarkAlt : undefined
        }
    };

    /**
     * Properly handling link clicked with react-router.
     * @param ev LinkClicked event
     */
    const onLinkClicked = (ev?: React.MouseEvent<HTMLElement>) => {
        ev?.preventDefault();
        if (props?.href) {
            if (props.trackEventInfo) {
                const eventProperties = {
                    name: props.name,
                    ...props.trackEventInfo.customProperties
                };

                logger.trackEvent(props.trackEventInfo.eventType, eventProperties);
            }
            navigate(props.href);
        }
    };
    const spanStyle: CSSProperties = {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center"
    };

    return (
        <span style={spanStyle}>
            {isStartPlacement && props.icon}
            {props.href !== undefined && (
                <Link
                    href={encodeURI(props.href)}
                    onClick={ev => onLinkClicked(ev)}
                    styles={{
                        root: {
                            color: theme.palette.themeDarkAlt
                        }
                    }}
                >
                    <Text styles={evaluatedTextStyle}>{props.name}</Text>
                </Link>
            )}
            {!props.href && <Text styles={evaluatedTextStyle}>{props.name}</Text>}
            {!isStartPlacement && props.icon}
        </span>
    );
};
