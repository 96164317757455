/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import React from "react";
import { Stack, Text, FontWeights, ITextStyles } from "@fluentui/react";
import { NotAuthorizedSVG } from "./NotAuthorizedSVG";
import { useTranslation } from "react-i18next";

/**
 * Page that is displayed when a user attempts to navigate to a page they do not have access to
 *
 * @returns JSX Element
 */
export const NotAuthorizedPage = (): JSX.Element => {
    const { t } = useTranslation();

    /**
     * styling for the text
     */
    const textStyle: ITextStyles = {
        root: {
            fontSize: "18px",
            fontWeight: FontWeights.bold
        }
    };

    return (
        <Stack
            horizontalAlign="center"
            verticalAlign="center"
            verticalFill
            tokens={{ childrenGap: 28 }}
        >
            <NotAuthorizedSVG />
            <Text block styles={textStyle}>
                {t("Error.Unauthorized")}
            </Text>
        </Stack>
    );
};
