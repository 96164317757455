/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import { MessageBar, MessageBarType, Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import {
    useApiRequest,
    useHapAuth,
    UserManagementSvc,
    IOrganization,
    IUser
} from "@nuance/hap-components";
import {
    formatUserName,
    HuxField,
    HuxFieldType,
    HuxInfoItemList,
    UserNameFormat
} from "@nuance/hux-components";
import { getLogger } from "@nuance/hux-diagnostics";
import { useTranslation } from "react-i18next";
import { FailedToLoadPage } from "./FailedToLoadPage";
import { HashSvc } from "../services/HashSvc";
import { EventTypes } from "../EventTypes";
import { ICredentials } from "@nuance/hap-components/lib/domain/Services/Types/ICredentials";

const logger = getLogger();

export type UserHoverCardUserInfo = Pick<
    IUser,
    | "UID"
    | "OrganizationUID"
    | "NuanceUserGuid"
    | "FirstName"
    | "LastName"
    | "MiddleName"
    | "Disabled"
    | "Login"
    | "EMailAddress"
>;

/**
 * UserHoverCard properties
 */
export interface IUserHoverCardProps {
    /** NMS User information */
    user: UserHoverCardUserInfo;
    /** Whether the hovercard should be rendered as readonly (no links) */
    readonly?: boolean;
}

/**
 * Component to show hover card for user
 * @param props UserHoverCard properties
 * @returns UserHoverCard component
 */
export const UserHoverCard = (props: IUserHoverCardProps): JSX.Element => {
    const { userContext } = useHapAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userManagementSvc = new UserManagementSvc(userContext);
    const user = props.user;

    const orgRequest = useApiRequest(userManagementSvc.getOrganizationByUID(user.OrganizationUID));
    const credsRequest = useApiRequest(userManagementSvc.getCredentials(user.UID));
    const requests = [orgRequest, credsRequest];

    const isLoading = requests.some(i => i.isLoading);
    const isError = requests.some(i => i.isError);

    if (isError) {
        // show failed to load
        logger.logError("Error loading user hover card", {
            orgRequest: orgRequest.status,
            credRequest: credsRequest.status
        });
        return <FailedToLoadPage />;
    }

    if (isLoading) {
        // show spinner while loading
        return (
            <div style={{ width: 220, margin: "16px 24px 16px 24px" }}>
                <Stack horizontal tokens={{ childrenGap: "8px" }}>
                    <Spinner
                        size={SpinnerSize.large}
                        style={{ height: "80%", justifySelf: "left" }}
                        ariaLive={"assertive"}
                        ariaLabel={t("Progress.Spinner_Loading")}
                    ></Spinner>
                    <Text>{t("Progress.Spinner_Loading")}</Text>
                </Stack>
            </div>
        );
    }

    if (orgRequest.isSuccess && credsRequest.isSuccess) {
        //build hover card
        const hashService = HashSvc.getInstance();
        const hashedOrgUID = hashService.HashEncode(user.OrganizationUID);
        const fullName = formatUserName(user, UserNameFormat.LFM);
        const org: IOrganization = orgRequest.data;
        const creds: ICredentials = credsRequest.data;

        const infoItemFields: HuxField[] = [];
        if (user.Disabled) {
            infoItemFields.push({
                type: HuxFieldType.Custom,
                render: () => {
                    return (
                        <MessageBar id={"disabled user id"} messageBarType={MessageBarType.warning}>
                            {t("Users.UserAuth.Disabled_User_Heading")}
                        </MessageBar>
                    );
                }
            });
        }

        infoItemFields.push(
            {
                label: t("Users.Search.Organization_Label"),
                type: HuxFieldType.Text,
                value: org.Name
            },
            {
                label: t("Users.Search.Username_Head"),
                type: HuxFieldType.Text,
                value: user.Login
            },
            {
                label: t("Users.Search.Email_Head"),
                type: HuxFieldType.Text,
                value: user.EMailAddress
            }
        );

        const tokenCreds = creds.credsToken.map(token => {
            return token.Login;
        });
        const ntlmCreds = creds.credsNTLM.map(ntlm => {
            return { name: ntlm.Login, domain: ntlm.DomainName };
        });

        tokenCreds.forEach(token => {
            infoItemFields.push({
                label: t("Users.UserAuth.Token"),
                type: HuxFieldType.Text,
                value: token
            });
        });

        ntlmCreds.forEach(ntlm => {
            infoItemFields.push({
                label: t("Users.UserAuth.NTLM"),
                type: HuxFieldType.Text,
                value: `${ntlm.name}\n${ntlm.domain}`
            });
        });

        return (
            <div style={{ minWidth: 220, maxWidth: 400, margin: "16px 24px 0px 24px" }}>
                <HuxInfoItemList
                    header={{
                        label: fullName,
                        href: `/users/${user.NuanceUserGuid}/orgs/${hashedOrgUID}/overview`,
                        onClick: props.readonly
                            ? undefined
                            : ev => {
                                  ev?.preventDefault();
                                  logger.trackEvent(EventTypes.UserLinkClicked, {
                                      user: fullName
                                  });
                                  navigate(
                                      `/users/${user.NuanceUserGuid}/orgs/${hashedOrgUID}/overview`
                                  );
                              }
                    }}
                    fields={infoItemFields}
                />
            </div>
        );
    }

    // shouldn't get down to here
    logger.logWarning("Unexpected status", {
        isLoading,
        isError,
        orgRequest: orgRequest.status,
        credRequest: credsRequest.status
    });
    return <FailedToLoadPage />;
};
