/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

import { CommandBarButton, IButtonProps, TooltipHost } from "@fluentui/react";

/**
 * Properties for CommandBarItemWithTooltip
 */
export interface IActionButtonWithTooltipProps extends IButtonProps {
    /*
     * Content to display in the Tooltip.
     */
    tooltipContent?: string | JSX.Element | JSX.Element[];
}

/**
 * Renders an action button with tooltip
 * @param props
 * @returns command bar button wrapped in tooltip
 */
export const ActionButtonWithTooltip = (props: IActionButtonWithTooltipProps): JSX.Element => {
    const tooltipStyles = { root: { height: "100%" } };
    const commandButtonStyles = { root: { height: "100%" } };

    return (
        <TooltipHost content={props.tooltipContent} styles={tooltipStyles}>
            <CommandBarButton
                ariaLabel={props.ariaLabel || props.text}
                iconProps={props.iconProps}
                onClick={props.onClick}
                text={props.text}
                disabled={props.disabled}
                role={props.role || "button"}
                styles={props.styles || commandButtonStyles}
                allowDisabledFocus={true}
            ></CommandBarButton>
        </TooltipHost>
    );
};
