/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
/**
 * Inactivity Timeout Warning Dialog Duration in seconds
 */
export const Inactivity_Timeout_Warning_Duration = 30;

/**
 * Number of items to request per page request when using infinite query
 */
export const INFINITE_QUERY_TAKE = 100;

/**
 * Min characters for users to type in when complexity requirements are not set.
 */

export const MIN_PASSWORD_CHAR = 6;

/**
 * Password cannot contain the user name or part of the name that exceeds 2 consecutive characters.
 */
export const NUM_CONSECUTIVE_CHARS = 2;

/**
 * Key of local storage for feature set
 */
export const PREVIEW_MODE_STORAGE_KEY = "previewMode";

/**
 * Number of items to request per page request
 */
export const TAKE = 2500;

/**
 * Local storage key for autotext clipboard data
 */
export const AUTOTEXT_CLIPBOARD_DATA_STORAGE_KEY = "NCCAutoTextClipboard";

/**
 * Local storage key for vocabulary clipboard data
 */
export const VOCABULARY_CLIPBOARD_DATA_STORAGE_KEY = "NCCVocabularyClipboard";

/**
 * Clipboard change event name
 */
export const CLIPBOARD_CHANGE_EVENT_NAME = "NCCClipboardChange";
