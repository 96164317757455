/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
    // detect user language
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    .use(Backend)
    .init({
        ns: ["NCC", "HuxComponents", "HapComponents"],
        debug: true,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json"
        },
        defaultNS: "NCC"
    });

export default i18n;
