/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

import { RoleTypes } from "@nuance/hap-components";

/** Record for getting the translation for the different role types */
const LocalizedRoleType: Record<RoleTypes, string | undefined> = {
    [RoleTypes.Unknown]: undefined,
    [RoleTypes.NMCAdministrator]: "Groups.Groups_Role.Value_NMC_Admin",
    [RoleTypes.DragonMedicalAuthor]: "Groups.Groups_Role.Value_DragonMedicalAuthor",
    [RoleTypes.SpeechAnywhere]: "Groups.Groups_Role.Value_SpeechRecognition",
    [RoleTypes.DMMR]: "Groups.Groups_Role.Value_DragonMedicalRecorder",
    [RoleTypes.TurboRecorder]: undefined, // blocked (obsolete)
    [RoleTypes.Reporting]: "Groups.Groups_Role.Value_Reporting",
    [RoleTypes.MTCollection]: undefined, // blocked
    [RoleTypes.User]: "Groups.Groups_Role.Value_User",
    [RoleTypes.CLU]: undefined, // blocked
    [RoleTypes.DMAOrgGroup]: undefined // blocked
};

/**
 * Get string for localized text lookup for roleTypes
 * @param roleType RoleType to get localized string
 * @returns String for localize text lookup
 */
export const getRoleTypeTranslationText = (roleType: RoleTypes) => {
    return LocalizedRoleType[roleType];
};
