/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */
import { ISVGImageProps } from "./SVGImageProps";

/**
 * @description AccountSVG component contains SVG for Account icon
 * @param props {ISVGImageProps} - The props containing properties for SVG images.
 * @returns The AccountSVG component.
 */
export const AccountSVG = (props: ISVGImageProps) => {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="account-icon"
        >
            <path
                d="M11.8361 9.33355C12.6641 9.33355 13.3353 10.0048 13.3353 10.8328V11.2164C13.3353 11.8126 13.1222 12.3892 12.7345 12.8421C11.6883 14.0644 10.0969 14.6676 7.99993 14.6676C5.90267 14.6676 4.312 14.0642 3.2678 12.8413C2.88129 12.3888 2.66895 11.8131 2.66895 11.2179V10.8328C2.66895 10.0048 3.34018 9.33355 4.16819 9.33355H11.8361ZM11.8361 10.3336H4.16819C3.89247 10.3336 3.66895 10.5571 3.66895 10.8328V11.2179C3.66895 11.575 3.79635 11.9204 4.02826 12.192C4.86379 13.1704 6.17438 13.6676 7.99993 13.6676C9.82547 13.6676 11.1372 13.1704 11.9749 12.1918C12.2075 11.92 12.3353 11.5741 12.3353 11.2164V10.8328C12.3353 10.5571 12.1117 10.3336 11.8361 10.3336ZM7.99993 1.33667C9.84087 1.33667 11.3333 2.82906 11.3333 4.67C11.3333 6.51096 9.84087 8.00335 7.99993 8.00335C6.15897 8.00335 4.66659 6.51096 4.66659 4.67C4.66659 2.82906 6.15897 1.33667 7.99993 1.33667ZM7.99993 2.33667C6.71127 2.33667 5.66659 3.38134 5.66659 4.67C5.66659 5.95867 6.71127 7.00335 7.99993 7.00335C9.2886 7.00335 10.3333 5.95867 10.3333 4.67C10.3333 3.38134 9.2886 2.33667 7.99993 2.33667Z"
                fill="#605E5C"
            />
        </svg>
    );
};
