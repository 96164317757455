/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */

import { ICustomEventProperties } from "@nuance/hux-diagnostics";

/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
export const EventTypes = {
    NavbarClicked: "Navbar clicked",
    TitleBarClicked: "TitleBar clicked",
    ReportSelected: "Report selected",
    OrganizationUpdated: "OrganizationUpdated",
    UserUpdated: "UserUpdated",
    ChangeOrgClicked: "Change Org clicked",
    AddSite: "Site added",
    AddGroup: "Group added",
    UpdateGroup: "Group updated",
    SiteUpdated: "SiteUpdated",
    DeleteSite: "Site deleted",
    ChangePassword: "Change Password",
    SiteDetailsOrgBreadCrumbItemClicked: "Site details: Organization bread crumb item clicked",
    SitesTabSiteLinkClicked: "Organization sites tab: Site link clicked",
    ProductsTabProductLinkClicked: "Organization Products tab: Product link clicked",
    ProductsDetailsOrgBreadCrumbItemClicked:
        "Product details: Organization bread crumb item clicked",
    ProductsDetailsProductContextualMenuItemClicked:
        "Product details: Product contextual menu item clicked",
    ChangeLevelButton: "Open change setting level button clicked",
    LevelChanged: "Setting level changed",
    IgnoreSettingLevelChanges: "Setting levels changes dismissed",
    UsernameChanged: "Username modified",
    CredentialModifiedOrAdded: "Credential modified or added",
    CredentialDeleted: "Credential deleted",
    SettingValueSaved: "Hierarchical Settings: Setting value saved",
    SearchResultClicked: "HuxTreeSearchResults search result clicked",
    TreeItemClicked: "HuxTreeMenu tree item clicked",
    SiteGroupLinkClicked: "Site Details: Group link clicked",
    UserGroupLinkClicked: "User Details: Group link clicked",
    GroupUserLinkClicked: "Group Details: User link clicked",
    GroupDetailsOrgBreadCrumbItemClicked: "Group details: Organization bread crumb item clicked",
    GroupDetailsSiteBreadCrumbItemClicked: "Group details: Site bread crumb item clicked",
    GroupBasicInfoOrgLinkClicked: "Group basic info: Org Link Clicked",
    GroupBasicInfoSiteLinkClicked: "Group basic info: Site Link Clicked",
    UserLinkClicked: "User Link Clicked",
    AvailableItemsUserLinkClicked: "Available Items User Link Clicked",
    AvailableUsersNextPageClicked: "Available users next page clicked",
    AvailableUsersPrevPageClicked: "Available users previous page clicked",
    ClearedSelectedUsers: "Cleared selected users",
    UserSettingsTabProductLinkClicked: "User Settings tab: Product link clicked",
    UserSettingsBreadCrumbItemClicked: "User settings page: User bread crumb item clicked",
    UserSettingsProductContextualMenuItemClicked:
        "User settings page: Product contextual menu item clicked",
    UserSearchInitiated: "User Search Page: Search initiated",
    GroupSearchInitiated: "Group Search Page: Search initiated",
    GroupSearchLinkClicked: "Group Search Page results link clicked",
    UnenrollUserFromOrgGroup: "User unenrolled from Org Group",
    EnrollUserInOrgGroup: "User enrolled in Org Group",
    UnenrollUserFromGroup: "User unenrolled from group",
    EnrollUserInGroup: "User enrolled in group",
    EnrollUserButtonClicked: "Enroll user button clicked",
    GroupPrivilegesSaved: "Group privileges saved",
    UsersAddedToGroup: "Users enrolled in group",
    UsersRemovedFromGroup: "Users unenrolled from group",
    UsersRemovedFromOrgGroup: "Users unenrolled from org group",
    SelectSitesPanelSelectedSites: " Select sites panel: Sites selected",
    ReorderGroupsInUser: "Reorder groups in user",
    ReorderGroupsMakePrimary: "Reorder groups Make primary button clicked",
    ReorderGroupsMoveUp: "Reorder groups Move up button clicked",
    ReorderGroupsMoveDown: "Reorder groups Move down button clicked",
    ReorderGroupsDragDrop: "Reorder groups Drag & drop used",
    DeleteGroup: "Group deleted",
    OrgGroupBasicInfoOrgLinkClicked: "OrgGroup basic info: Org Link Clicked",
    OrgGroupPageBreadcrumbLinkClicked: "OrgGroup breadcrumb link clicked",
    OrgGroupNameChanged: "OrgGroup name changed",
    OrgGroupLinkClicked: "OrgGroup link clicked",
    DeleteOrgGroup: "Org Group deleted",
    AddGrantsPanelSelectOrganization: " Add grants panel: Select organization clicked",
    AddGrantsPanelOrganizationSelected: " Add grants panel: Organization selected",
    AddGrantsPanelAddSitesClicked: " Add grants panel: Add sites clicked",
    AddGrantsPanelRemoveSitesClicked: " Add grants panel: Remove sites clicked",
    AddGrantsPanelAddGroupsClicked: " Add grants panel: Add groups clicked",
    AddGrantsPanelRemoveGroupsClicked: " Add grants panel: Remove groups clicked",
    GrantsAddedToGroup: "Grants added to group",
    GrantsRevokedFromGroup: "Grants revoked from group",
    GroupGrantsTabAddGrantButtonClicked: "Group grants tab: Add grant button clicked",
    GroupGrantsTabRevokeGrantButtonClicked: "Group grants tab: Revoke grant button clicked",
    OrgNameLinkClicked: "Organization name link clicked",
    SiteNameLinkClicked: "Site name link clicked",
    GroupNameLinkClicked: "Group name link clicked",
    UserNameLinkClicked: "User name link clicked",
    UsersEnrolledInGroup: "User(s) enrolled in group",
    UsersGrantedLicenses: "User(s) granted licenses",
    UsersLicenseRevoked: "User(s) licenses revoked",
    UserLicensesRevoked: "User licenses revoked",
    LicenseLinkClicked: "License name link clicked",
    UserLicenseLinkClicked: "User license link clicked",
    LicenseDetailsProductLinkClicked: "License details product link clicked",
    SuccessPageButtonClicked: "Success page button clicked",
    AddUserPanelChangeOrgClicked: "Add Org Panel: Change Organization button clicked",
    AddUserPanelAddGroupsClicked: "Add Org Panel: Add groups button clicked",
    AddUserPanelRemoveGroupsClicked: "Add Org Panel: Remove groups button clicked",
    AddUserConfirmDialogUserDetailsLinkClicked:
        "Add User Confirm Dialog: User details link clicked",
    AddUserConfirmDialogAddAnotherUserLinkClicked:
        "Add User Confirm Dialog: Add another user link clicked",
    AddUserConfirmDialogAssignLicensesLinkClicked:
        "Add User Confirm Dialog: Assign licenses link clicked",
    AssignLicenseToNewUserUserDetailsLinkClicked:
        "Assign License to New User Successfully: User details link clicked.",
    AddUserConfirmDialogUserSaved: "Add User Confirm Dialog: User Saved",
    UpdatedUserBasicInfo: "Edit User Basic Info Panel: User basic info updated",
    UpdatedUserContactInfo: "Edit User Contact Info Panel: User contact info updated",
    UserSavedSuccessfully: "User Saved Successfully",
    ServicePrincipalSavedSuccessfully: "Service Principal / Managed Identity saved Successfully",
    UserSearchPageAddUserAccountButtonClicked: "User Search Page: Add user account button clicked",
    UserSearchPageAddServicePrincipleButtonClicked:
        "User Search Page: Add Service Principal button clicked",
    GroupDetailsPageAddUserAccountButtonClicked:
        "Group Details Page: Add user account button clicked",
    UserDeleted: "User deleted",
    GrantLicenseButtonClicked: "User Grant License button clicked",
    LicensesAddedToUser: "Licenses added to user",
    DeleteUserClicked: "Delete user clicked",
    LicenseSearchInitiated: "License Search Page: Search initiated",
    LicenseSearchLinkClicked: "License Search Page results link clicked",
    OnlineHelpClicked: "Online help button clicked",
    LicenseImportedSuccessfully: "License imported successfully",
    ProductProvisioningSaved: "Product provisioning saved",
    AutoTextsDeleted: "Auto-texts deleted",
    VocabularyLinkClicked: "Vocabulary link clicked",
    DeleteAutoTextButtonClicked: "Delete auto-text button clicked",
    ViewMenuButtonClicked: "View menu button clicked",
    SupportToolLinkClicked: "Support tool link clicked",
    TokenAuthenticationAvailabilityUpdated: "Token Authentication Availability updated",
    AutoTextCopyButtonClicked: "Auto-text copy button clicked",
    AutoTextCutButtonClicked: "Auto-text cut button clicked",
    UndoCopyButtonClicked: "Clear copy button clicked",
    CancelCutButtonClicked: "Cancel cut button clicked",
    AutoTextEnableButtonClicked: "Auto text Enable button clicked",
    AutoTextsEnabled: "Auto texts enabled",
    AutoTextPasteButtonClicked: "AutoText paste button clicked",
    AutoTextsPasted: "Auto texts pasted",
    AutoTextUpdated: "Auto text updated",
    AutoTextLinkClicked: "Auto text link clicked",
    UserSpeechObjectLinkClicked: "User speech object link clicked",
    KeywordModified: "Keyword modified or added",
    ManageKeywordsClicked: "Speech Recognition tab: Manage Keywords clicked",
    EHRLinkClicked: "Organization EHR clicked",
    AADTenantsSaved: "AAD Tenants Saved",
    EHRAdded: "EHR added",
    EHREdited: "EHR edited",
    AddEHRButtonClicked: "Add EHR button clicked",
    DeleteEHRButtonClicked: "Delete EHR button clicked",
    UserEHRDeleted: "User EHR deleted",
    UserEHRAddedOrUpdated: "User EHR added or modified",
    UserImportDropdownXlsxClicked: "User import dropdown XLSX filetype clicked",
    UserImportDropdownCsvClicked: "User import dropdown CSV filetype clicked",
    UserImportDropdownXmlClicked: "User import dropdown XML filetype clicked",
    UserImportXlsxSampleFileDownloaded: "User import sample Xlsx file downloaded",
    UserImportCsvSampleFileDownloaded: "User import sample Csv file downloaded",
    UserImportXmlSampleFileDownloaded: "User import sample Xml file downloaded",
    UserImportFileTypeChanged: "User import filetype changed",
    RemoveUserImportClicked: "Remove user import clicked",
    CancelUserImportClicked: "Cancel user import clicked",
    UserImportRemoved: "User import removed",
    UserImportCancelled: "User import cancelled",
    UserImportChangeOrg: "User import change organization selected",
    ViewUserImportDetailsClicked: "View user import details clicked",
    UserImportFileUploadCompleted: "User import file upload completed with no errors",
    UserImportFileUploadCompletedWithErrors: "User import file upload completed with errors",
    UserImportCancelledAtInitialValidation: "User import cancelled at initial validation",
    FileExplorerOpened: "File explorer opened",
    UserImportRecordsWithErrorsViewed: "Records with errors tab viewed",
    UserSpeechPageAutoTextsClicked: "User speech page: Auto Texts clicked",
    UserSpeechPageVocabularyClicked: "User speech page: Vocabulary clicked",
    VocabularyUpdated: "A vocabulary has been updated",
    AddVocabularyButtonClicked: "Add vocabulary button clicked",
    VocabularyAdded: "A vocabulary had been added",
    CopyVocabularyButtonClicked: "Copy vocabulary button clicked",
    PasteVocabularyButtonClicked: "Paste vocabulary button clicked",
    SuccessfullyPastedVocabulary: "Successfully pasted vocabulary",
    VocabularyEnableButtonClicked: "Vocabulary enable button clicked",
    VocabularyEnabled: "Vocabulary enabled",
    DeleteVocabButtonClicked: "Delete vocabulary button clicked",
    VocabularyDeleted: "Vocabulary deleted",
    UserEntraIdsLinked: "User EntraIDs linked",
    LinkEntraIdButtonClicked: "User link EntraID button clicked",
    QuickSearchLinkClicked: "EntraID quick search link clicked",
    UserEntraIdUnlinked: "User Entra ID unlinked",
    UnlinkEntraIdButtonClicked: "Unlink Entra ID button clicked"
};

/**
 * Interface for passing logger track event
 */
export interface ITrackEventInfo {
    /** Event type to track */
    eventType: string;
    /** custom event properties */
    customProperties?: ICustomEventProperties;
}
