/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import React from "react";
import { TextField, ITextFieldStyles, FontWeights, Text } from "@fluentui/react";

export interface InfoItemProps {
    /**
     * Label for the infoItem
     */
    name: string; // aka label
    /**
     * The value for the infoItem.
     */
    value?: string;
    /**
     * Whether the infoItem should render as multiline.
     */
    multiline?: boolean;
    /**
     * text displayed below the field value
     */
    description?: string;
}

const textStyle: Partial<ITextFieldStyles> = {
    field: {
        padding: 0,
        whiteSpace: "pre-line",
        resize: "none"
    },
    fieldGroup: {
        height: 20
    }
};

const descriptionStyle = {
    fontStyle: "italic",
    fontWeight: FontWeights.regular
};
/**
 * Renders a Key value pair in Basic Info
 * @param props name <string>
 * @param props value <string>
 * @returns Details list for CustomerIdentifiersSection
 */
export const InfoItem = (props: InfoItemProps): JSX.Element => {
    const value = props.value === undefined || props.value === "" ? "--" : props.value;
    return (
        <div style={{ paddingBottom: 16 }}>
            <TextField
                tabIndex={-1}
                label={props.name}
                value={value}
                borderless
                readOnly
                styles={textStyle}
                data-testid={props.name}
                multiline={props.multiline}
                autoAdjustHeight={props.multiline}
            />
            {props.description && <Text style={descriptionStyle}>{props.description}</Text>}
        </div>
    );
};
