/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */
import { ISVGImageProps } from "./SVGImageProps";

/**
 * @description SiteSVG component contains SVG for Site Grant icon
 * @param props {ISVGImageProps} - The props containing properties for SVG images.
 * @returns The SiteSVG component.
 */
export const SiteSVG = (props: ISVGImageProps) => {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="site"
        >
            <path
                d="M1.89552 2.04524C4.16246 -0.221703 7.8379 -0.221703 10.1048 2.04524C12.3718 4.31218 12.3718 7.98764 10.1048 10.2546L9.3135 11.0372C8.7303 11.6096 7.9735 12.3456 7.04296 13.2452C6.46143 13.8075 5.5389 13.8074 4.9575 13.2451L2.6301 10.9812C2.33759 10.694 2.09274 10.4518 1.89552 10.2546C-0.371422 7.98764 -0.371422 4.31218 1.89552 2.04524ZM9.3977 2.75234C7.5213 0.875923 4.47904 0.875923 2.60262 2.75234C0.726211 4.62876 0.726211 7.67104 2.60262 9.54744L3.59401 10.5258C4.13994 11.06 4.82623 11.727 5.6527 12.5263C5.8465 12.7137 6.15403 12.7138 6.34783 12.5264L8.6111 10.3254C8.92376 10.0185 9.18596 9.75918 9.3977 9.54744C11.2742 7.67104 11.2742 4.62876 9.3977 2.75234ZM6.00016 4.33234C7.1053 4.33234 8.00123 5.22823 8.00123 6.33338C8.00123 7.43851 7.1053 8.33438 6.00016 8.33438C4.89503 8.33438 3.99914 7.43851 3.99914 6.33338C3.99914 5.22823 4.89503 4.33234 6.00016 4.33234ZM6.00016 5.33234C5.4473 5.33234 4.99916 5.78051 4.99916 6.33338C4.99916 6.88624 5.4473 7.33438 6.00016 7.33438C6.55303 7.33438 7.00123 6.88624 7.00123 6.33338C7.00123 5.78051 6.55303 5.33234 6.00016 5.33234Z"
                fill="#212121"
            />
        </svg>
    );
};
