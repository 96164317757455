/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */

import React, { useRef, useState } from "react";
import {
    CacheKey,
    IGetUsersSearchParameters,
    IGroup,
    IOrganization,
    IOrgGroup,
    IUser,
    useApiRequest,
    useHapAuth,
    UserManagementSvc
} from "@nuance/hap-components";
import { useTranslation } from "react-i18next";
import { AvailableUsersList } from "./AvailableUsersList";
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IButton,
    mergeStyles,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Text
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { getLogger } from "@nuance/hux-diagnostics";
import { QueryClient, useMutation, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { HuxSearchWithOptions, IHuxQueryItem, StringTokenizerData } from "@nuance/hux-components";
import { EventTypes } from "../EventTypes";
import { useBoolean } from "@fluentui/react-hooks";
import { namespaces } from "../common/translationNamespaces";
import { getGroupId, instanceOfIOrgGroup } from "../common/GroupFunctions";
import { panelStyles } from "../CommonStyles";

export interface IGroupUserEnrollmentPanelProps {
    group: IGroup | IOrgGroup;
    currentUsers: IUser[];
    onDismiss: () => void;
}

export const GroupUserEnrollmentPanel = (props: IGroupUserEnrollmentPanelProps) => {
    const { t } = useTranslation([namespaces.NCC, namespaces.HuxComponents]);
    const { userContext } = useHapAuth();
    const logger = getLogger();
    const userManagementSvc = new UserManagementSvc(userContext);
    const queryClient = useQueryClient();
    const errorMessageId = useId("errorMessage");
    const confirmMessageId = useId("confirmMessage");
    const isOrgGroup = instanceOfIOrgGroup(props.group);

    let orgReq: UseQueryResult<IOrganization>;

    if (isOrgGroup) {
        const group = props.group as IOrgGroup;
        orgReq = useApiRequest(userManagementSvc.selectOrganizationByGuid(group.OrgGuid));
    } else {
        const group = props.group as IGroup;
        orgReq = useApiRequest(userManagementSvc.getOrganizationByUID(group.OrganizationUID));
    }

    /** Error message for if enrolling fails */
    const [errorMsg, setErrorMsg] = useState<string | undefined>();
    /** Confirmation message for if enrolling succeeds */
    const [confirmMsg, setConfirmMsg] = useState<string | undefined>();
    /** All the currently selected users */
    const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
    /** is the search loading or not */
    const [loading, setLoading] = useState(false);
    /** If Search button is disabled */
    const [searchDisabled, setSearchDisabled] = useState(true);
    /** Search input val */
    const [searchTokensVal, setSearchTokensVal] = useState<StringTokenizerData>();
    /** Ref for search button */
    const searchButtonRef = useRef<IButton>(null);
    /** State for the search parameters */
    const [userSearchParameters, setUserSearchParameters] = useState<IGetUsersSearchParameters>();
    /** State for if the dialog is open */
    const [isDialogOpen, { toggle: toggleIsDialogOpen }] = useBoolean(true);
    /** State for if clear selection dialog is open */
    const [isClearDialogOpen, { toggle: toggleIsClearDialogOpen }] = useBoolean(true);
    /** State for if there are no results from a search (for screen reader) */
    const [numResults, setNumResults] = useState<number>();
    /** Whether to clear the current selection */
    const [clearSelection, setClearSelection] = useState(false);
    /** Id for search box */
    const searchBoxId = useId("searchBox");
    /** Id for enroll button */
    const enrollButtonId = useId("enrollUsersButton");
    /** Whether to refetch users in the available users list */
    const [refetchUsers, setRefetchUsers] = useState(false);
    /** Timeout Id for confirm message */
    let timeoutId: number;

    /** Mutation for enrolling users */
    const { mutate, isPending: isMutationLoading } = useMutation({
        mutationFn: async () => {
            setErrorMsg(undefined);
            setConfirmMsg(undefined);

            /** Get all the UIDs of the selected users */
            const selectedUsersUIDs = selectedUsers.map(user => user.UID);

            if (isOrgGroup) {
                const group = props.group as IOrgGroup;
                await unenrollFromOrgGroup(selectedUsers, userManagementSvc, queryClient);
                return userManagementSvc
                    .enrollUsersInOrgGroup(group.OrgGroupId, selectedUsersUIDs)
                    .execute();
            }
            const group = props.group as IGroup;
            return userManagementSvc.enrollUsersInGroup(group.UID, selectedUsersUIDs).execute();
        },
        onSuccess: () => {
            logger.trackEvent(EventTypes.UsersEnrolledInGroup, {
                groupUID: getGroupId(props.group),
                usersEnrolled: selectedUsers.length
            });

            /** Invalidate cache for the users in the current group */
            queryClient.invalidateQueries({
                queryKey: [
                    CacheKey.getUsers,
                    {
                        groupUID: getGroupId(props.group),
                        skip: 0,
                        take: 100
                    }
                ]
            });

            //** Invalidate group cache for all users that were enrolled */
            selectedUsers.forEach(user => {
                queryClient.invalidateQueries({ queryKey: [CacheKey.getGroupsByUser, user.UID] });
                queryClient.invalidateQueries({ queryKey: [CacheKey.getUserEnrollment, user.UID] });
                queryClient.invalidateQueries({ queryKey: [CacheKey.getOrgGroups, user.UID] });
            });

            //** Invalidate org group cache for user count*/
            queryClient.invalidateQueries({ queryKey: [CacheKey.getOrgGroupsByOrg] });
            // Invalidate org site EHRs as user's sites may have changed
            queryClient.invalidateQueries({ queryKey: [CacheKey.getOrgSiteEhr] });
            queryClient.invalidateQueries({ queryKey: [CacheKey.getUserEhr] });

            //** Set confirmation message */
            setConfirmMsg(t("Groups.Enroll.Enroll_Members_Success_Message"));
            clearTimeout(timeoutId);

            // Clear confirmation after 5 seconds
            timeoutId = window.setTimeout(() => {
                setConfirmMsg(undefined);
            }, 5000);

            setRefetchUsers(true);
        },
        onError: (e: Error) => {
            logger.logError(e.message);
            setErrorMsg(t("Messages.Error.Changes_Not_Saved"));
            const errorElement = document.getElementById(errorMessageId);
            errorElement?.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest"
            });
        }
    });

    if (orgReq.isLoading) {
        return <Spinner size={SpinnerSize.large} style={{ height: "80%" }} />;
    }

    /**
     * Panel Footer to be rendered when the mutation is not loading
     * @returns JSX.Element
     */
    const footerNotLoading = () => (
        <div>
            <PrimaryButton
                id={enrollButtonId}
                disabled={selectedUsers.length === 0 ? true : false}
                type="submit"
                style={{ marginRight: "16px" }}
                onClick={() => {
                    mutate();
                    refetchUsers && setRefetchUsers(false);
                }}
            >
                {t("Groups.Enroll.Enroll_Users_Button", {
                    selectedUsersLength: selectedUsers.length
                })}
            </PrimaryButton>
            <DefaultButton
                onClick={() => {
                    if (selectedUsers.length > 0) {
                        toggleIsDialogOpen();
                    } else props.onDismiss();
                }}
            >
                {t("Form.Done_Button")}
            </DefaultButton>
        </div>
    );

    /**
     * Panel Footer to be rendered when the mutation is loading
     * @returns JSX.Element
     */
    const footerLoading = () => {
        const label = t("Progress.Spinner_Enrolling");
        return (
            <Spinner
                size={SpinnerSize.large}
                styles={{ root: { justifyContent: "left" } }}
                ariaLive={"assertive"}
                ariaLabel={label}
                label={label}
                labelPosition="right"
            ></Spinner>
        );
    };

    /** Styling for user search components */
    const horizontalStackStyle = {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        gap: 16,
        flexWrap: "wrap"
    };

    /** Style for search components container */
    const usersSearchContainerStyles = mergeStyles(horizontalStackStyle, {
        marginTop: 24,
        marginBottom: 24
    });

    /** User search key options */
    const queryOptions: IHuxQueryItem[] = [
        {
            key: "login",
            prefix: t("Users.Search.Filter.Login_Key"),
            description: t("Users.Search.Filter.Login_Text")
        },
        {
            key: "emailAddress",
            prefix: t("Users.Search.Filter.Email_Key"),
            description: t("Users.Search.Filter.Email_Text")
        },
        {
            key: "lastName",
            prefix: t("Users.Search.Filter.Last_Key"),
            description: t("Users.Search.Filter.Last_Text")
        },
        {
            key: "firstname",
            prefix: t("Users.Search.Filter.First_Key"),
            description: t("Users.Search.Filter.First_Text")
        },
        {
            key: "npi",
            prefix: t("Users.Search.Filter.NPI_Key"),
            description: t("Users.Search.Filter.NPI_Text")
        },
        {
            key: "siteName",
            prefix: t("Users.Search.Filter.Site_Key"),
            description: t("Users.Search.Filter.Site_Text")
        },
        {
            key: "groupName",
            prefix: t("Users.Search.Filter.Group_Key"),
            description: t("Users.Search.Filter.Group_Text")
        }
    ];

    /** Actions that take place when a user Searches */
    const onSearch = () => {
        /** remove focus from search box (if using enter key) */
        searchButtonRef.current?.focus();
        /** Disabled search button */
        setSearchDisabled(true);
        refetchUsers && setRefetchUsers(false);

        /** Set the search parameters */
        const getUsersSearchParameters = {
            ...searchTokensVal,
            organizationUID: orgReq.data?.UID,
            skip: 0 /** Initial value, UserListComponent will handle pagination */,
            take: 1000
        };

        /** If the desired data is already cached the spinner won't show */
        if (
            queryClient.getQueriesData({ queryKey: [CacheKey.getUsers, getUsersSearchParameters] })
                .length === 0
        ) {
            setLoading(true);
        }

        /** Set the search parameters */
        setUserSearchParameters(getUsersSearchParameters);

        logger.trackEvent(EventTypes.UserSearchInitiated, {
            organizationUID: userSearchParameters?.organizationUID,
            siteUID: userSearchParameters?.siteUID,
            groupUID: userSearchParameters?.groupUID,
            roleUID: userSearchParameters?.roleUID,
            login: userSearchParameters?.login,
            firstName: userSearchParameters?.firstName,
            lastName: userSearchParameters?.lastName,
            groupName: userSearchParameters?.groupName,
            siteName: userSearchParameters?.siteName,
            npi: userSearchParameters?.npi,
            emailAddress: userSearchParameters?.emailAddress,
            skip: userSearchParameters?.skip,
            take: userSearchParameters?.npi,
            licenseCriteriaHasLicense: userSearchParameters?.licenseCriteria?.hasLicense,
            licenseCriteriaLicenseTypeGuid: userSearchParameters?.licenseCriteria?.licenseTypeGuid,
            licenseCriteriaPartnerGuid: userSearchParameters?.licenseCriteria?.partnerGuid
        });
    };

    /** Dialog props */
    const dialogContentProps = {
        type: DialogType.normal,
        title: t("Dialog.Unsaved_Changes.Title2"),
        subText: t("Dialog.Unsaved_Changes.Text_Enroll_Users")
    };

    /** Clear selection dialog props */
    const clearDialogContentProps = {
        type: DialogType.normal,
        title: t("Dialog.Clear_Selection.Title"),
        subText: t("Dialog.Clear_Selection.Text")
    };

    return (
        <Panel
            styles={panelStyles}
            isOpen={true}
            headerText={t("Groups.Enroll.Enroll_Members_Title")}
            isFooterAtBottom={true}
            type={PanelType.extraLarge}
            onDismiss={selectedUsers.length > 0 ? toggleIsDialogOpen : props.onDismiss}
            onRenderFooterContent={() => {
                return isMutationLoading ? footerLoading() : footerNotLoading();
            }}
            isLightDismiss={true}
            closeButtonAriaLabel={t("Action.Close_Button")}
        >
            {numResults !== undefined &&
                (numResults === 0 ? (
                    <div aria-live="assertive" className="screenReaderOnly">
                        {t(`${namespaces.HuxComponents}:NoItemsAvailable.No_Results_Heading`)}
                    </div>
                ) : (
                    <div aria-live="assertive" className="screenReaderOnly">
                        {t(`${namespaces.HuxComponents}:HUXDetailsList.Items_Found`, {
                            numberOfItems: numResults
                        })}
                    </div>
                ))}
            {errorMsg ? (
                <MessageBar id={errorMessageId} messageBarType={MessageBarType.error}>
                    {errorMsg}
                </MessageBar>
            ) : (
                <></>
            )}
            {confirmMsg && !errorMsg ? (
                <MessageBar
                    id={confirmMessageId}
                    messageBarType={MessageBarType.success}
                    aria-live="assertive"
                    onDismiss={() => undefined}
                    dismissButtonAriaLabel={t(
                        `${namespaces.HuxComponents}:HuxMessageBar.MessageBar_Dismiss_button`
                    )}
                >
                    {confirmMsg}
                </MessageBar>
            ) : (
                <></>
            )}
            <div style={{ display: "flex", flexDirection: "column" }}></div>
            <div className={usersSearchContainerStyles}>
                <div style={{ flexBasis: 500 }}>
                    <HuxSearchWithOptions
                        searchBoxId={searchBoxId}
                        description={t("Users.Search.Filter.Text")}
                        example={t("Users.Search.Filter.Tip")}
                        queryOptions={queryOptions}
                        defaultQuery={queryOptions[2]}
                        ariaLabelForSearch={t("Groups.Enroll.Search_Users_Label")}
                        onChange={searchString => {
                            if (searchString) {
                                if (searchString.error != undefined) {
                                    setSearchDisabled(true);
                                } else if (searchString.data != null) {
                                    setSearchDisabled(false);
                                    setSearchTokensVal(searchString.data);
                                }
                            } else {
                                setSearchDisabled(true);
                            }
                        }}
                        onSearch={() => {
                            if (!searchDisabled) {
                                onSearch();
                            }
                        }}
                        onFocus={() => {
                            clearSelection && setClearSelection(false);
                            if (selectedUsers.length > 0) {
                                document.getElementById(searchBoxId)?.blur();
                                document.getElementById(enrollButtonId)?.focus();
                                toggleIsClearDialogOpen();
                            }
                        }}
                        suppressCalloutFocus={selectedUsers.length > 0}
                        suppressOnClear={selectedUsers.length > 0}
                        labelText={t("Groups.Enroll.Search_Users_Label")}
                        labelToolTipText={t("Groups.Enroll.Search_Users_Tooltip")}
                    />
                </div>
                <div>
                    {!loading && (
                        <PrimaryButton
                            componentRef={searchButtonRef}
                            styles={{ root: { flexBasis: 100 } }}
                            disabled={searchDisabled}
                            onClick={() => {
                                onSearch();
                            }}
                            ariaLabel={t("Users.Search.Search_Button")}
                        >
                            {t("Users.Search.Search_Button")}
                        </PrimaryButton>
                    )}
                    {loading && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >
                            <Spinner
                                size={SpinnerSize.large}
                                style={{ height: "80%", justifySelf: "left", marginRight: 8 }}
                                ariaLive={"assertive"}
                                ariaLabel={t("Progress.Spinner_Searching")}
                            ></Spinner>
                            <Text>{t("Progress.Spinner_Searching")}</Text>
                        </div>
                    )}
                </div>
            </div>
            {
                <AvailableUsersList
                    currentUIDs={props.currentUsers.map(user => user.UID)}
                    selectedUIDs={selectedUsers.map(user => user.UID)}
                    searchCriteria={userSearchParameters}
                    onSearchCompleted={resultsCount => {
                        setLoading(false);
                        setNumResults(resultsCount);
                    }}
                    onUserSelected={newSelectedUsers => {
                        clearSelection && setClearSelection(false);
                        setSelectedUsers(newSelectedUsers);
                    }}
                    clearSelection={clearSelection}
                    refetchUsers={refetchUsers}
                />
            }
            <Dialog
                hidden={isDialogOpen}
                onDismiss={toggleIsDialogOpen}
                dialogContentProps={dialogContentProps}
                modalProps={{
                    isBlocking: true
                }}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            toggleIsDialogOpen();
                            props.onDismiss();
                        }}
                        text={t("Dialog.Unsaved_Changes.Done_Button")}
                    />
                    <DefaultButton onClick={toggleIsDialogOpen} text={t("Form.Cancel_Button")} />
                </DialogFooter>
            </Dialog>
            {
                <Dialog
                    hidden={isClearDialogOpen}
                    onDismiss={toggleIsClearDialogOpen}
                    dialogContentProps={clearDialogContentProps}
                    modalProps={{
                        isBlocking: true
                    }}
                    maxWidth={500}
                >
                    <DialogFooter>
                        <PrimaryButton
                            onClick={() => {
                                toggleIsClearDialogOpen();
                            }}
                            text={t("Form.Cancel_Button")}
                        />
                        <DefaultButton
                            onClick={() => {
                                logger.trackEvent(EventTypes.ClearedSelectedUsers, {
                                    groupUID: getGroupId(props.group),
                                    usersSelected: selectedUsers.length
                                });
                                setSelectedUsers([]);
                                setClearSelection(true);
                                toggleIsClearDialogOpen();
                            }}
                            text={t("Dialog.Clear_Selection.Clear_Selection_Button")}
                        />
                    </DialogFooter>
                </Dialog>
            }
        </Panel>
    );
};

const unenrollFromOrgGroup = (
    selectedUsers: IUser[],
    userManagementSvc: UserManagementSvc,
    queryClient: QueryClient
) => {
    const promises: Promise<void>[] = [];

    // for each user get the org group; if any, unenroll user from that group
    selectedUsers.forEach(async user => {
        const promise = userManagementSvc
            .getOrgGroupsByUserUID(user.UID, 0, 1)
            .execute()
            .then(async orgGroups => {
                if (orgGroups.length > 0) {
                    // user can only have 1
                    const oldGroupId = orgGroups[0].OrgGroupId;
                    await userManagementSvc
                        .unenrollUsersFromOrgGroup(oldGroupId, [user.UID])
                        .execute();

                    /** Invalidate cache for the users in the old group */
                    queryClient.invalidateQueries({
                        queryKey: [
                            CacheKey.getUsers,
                            {
                                groupUID: oldGroupId,
                                skip: 0,
                                take: 100
                            }
                        ]
                    });
                }
            });
        promises.push(promise);
    });
    return Promise.all(promises);
};
