/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import {
    IStackStyles,
    IconButton,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
    TextField,
    TooltipHost
} from "@fluentui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NCCTitleBar } from "./NCCTitleBar/NCCTitleBar";
import { getLogger } from "@nuance/hux-diagnostics";

const AUTH_CONNECTION_STORAGE_KEY = "authConnection";
const logger = getLogger();

/**
 * check if the url has an error
 * @param href
 * @returns the url params if the url has an error or undefined if no error
 */
const hasError = (href: string): URLSearchParams | undefined => {
    // if connection is invalid, the Authorization Server adds the error to the url as a query param or hash
    const url = new URL(href);
    const params = url.searchParams;
    const errorParam = params.get("error");
    if (errorParam) {
        return params;
    }

    // for AAD its a hash not query param
    const returnString = url.hash;
    const errorIndex = returnString.indexOf("error=");
    if (errorIndex > 0) {
        const errorHashSubstring = returnString?.substring(errorIndex);
        const urlParams = new URLSearchParams(errorHashSubstring);
        return urlParams;
    }
};

/**
 * Get the auth connection from query param, local storage, or default.
 * @param href
 * @returns
 */
export function getAndUpdateAuthConnection(href: string): string {
    const params = new URL(href).searchParams;
    const connectionParam = params.get("connect");

    const error = hasError(href);
    if (error) {
        const urlParams = Object.fromEntries(error);
        const connection = localStorage.getItem(AUTH_CONNECTION_STORAGE_KEY) || undefined;
        logger.logError("Error logging in", { ...urlParams, connection });
        return "";
    }
    if (connectionParam !== null && connectionParam !== "") {
        localStorage.setItem(AUTH_CONNECTION_STORAGE_KEY, connectionParam);
    }

    let connection = localStorage.getItem(AUTH_CONNECTION_STORAGE_KEY) || "";

    // This will handle two situations: 1. aad/ passed through url 2. aad/ in local storage
    if (connection?.substring(0, 4).toLowerCase() === "aad/") {
        const aadString = connection?.substring(0, 4);
        connection = connection.replace(aadString, "entraid/");
        localStorage.setItem(AUTH_CONNECTION_STORAGE_KEY, connection);
    }

    return connection;
}

const inputStyles = { fieldGroup: [{ width: 365 }] };
const stackStyle: IStackStyles = {
    inner: {
        alignItems: "baseline" //so input box and button are always aligned, even with error message
    }
};

/**
 * Page for user to enter the tenant id (aka connection string) for authentication
 * ex: NuanceNMS or NuanceAD
 */
export const AuthConnectionPage = () => {
    const { t } = useTranslation();
    const defaultTenantId = localStorage.getItem(AUTH_CONNECTION_STORAGE_KEY) || "";
    const [tenantId, setTenantId] = useState(defaultTenantId);
    const navigate = useNavigate();

    document.title = t("Initializing.Tenant_Needed.Page_Title");

    const connectionHandler = () => {
        localStorage.setItem(AUTH_CONNECTION_STORAGE_KEY, tenantId);
        navigate(`?connect=${tenantId}`);
    };

    const error = useMemo(() => hasError(window.location.href), [window.location.href]);

    return (
        <>
            <NCCTitleBar />
            <main>
                {error && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {t("Initializing.Tenant_Needed.Org_ID_Not_Found_Error")}
                    </MessageBar>
                )}
                <Stack horizontalAlign="center" tokens={{ padding: "112px 8px" }}>
                    <div>
                        <label htmlFor="ncc-tenant-id" style={{ fontSize: 18 }}>
                            {t("Initializing.Tenant_Needed.Instructions")}
                        </label>
                        <TooltipHost
                            content={t("Initializing.Tenant_Needed.Tooltip")}
                            styles={{ root: { height: "100%", width: "100%" } }}
                        >
                            <IconButton
                                iconProps={{ iconName: "Info" }}
                                ariaDescription={t("Initializing.Tenant_Needed.Tooltip")}
                                styles={{
                                    root: {
                                        padding: "0px",
                                        marginTop: "-7px"
                                    }
                                }}
                            />
                        </TooltipHost>
                        <Stack
                            horizontal
                            verticalAlign="center"
                            wrap
                            tokens={{ childrenGap: 16, padding: "24px 0" }}
                            styles={stackStyle}
                        >
                            <Stack tokens={{ maxWidth: 365 }}>
                                <TextField
                                    id="ncc-tenant-id"
                                    styles={inputStyles}
                                    value={tenantId}
                                    onChange={(e, newValue) => setTenantId(newValue ?? "")}
                                    errorMessage={
                                        error
                                            ? t("Initializing.Tenant_Needed.Org_ID_Needed_Error")
                                            : ""
                                    }
                                    onKeyDown={e => {
                                        if (e.key === "Enter") connectionHandler();
                                    }}
                                />
                            </Stack>
                            <PrimaryButton
                                text={t("Action.Continue_Button")}
                                onClick={connectionHandler}
                                disabled={!tenantId}
                            />
                        </Stack>
                    </div>
                </Stack>
            </main>
        </>
    );
};
