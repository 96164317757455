/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */
import { Stack, StackItem, Text, useTheme } from "@fluentui/react";
import { IHuxDetailsListItem } from "@nuance/hux-components";
import { DisabledSVG } from "../components/SVG/DisabledSVG";
import { LockedSVG } from "../components/SVG/LockedSVG";
import { i18n } from "i18next";

/**
 * User Status enumeration
 */
export enum Status {
    /**
     * Disabled
     */
    Disabled = "Disabled",
    /**
     * Enabled
     */
    Enabled = "Enabled",
    /**
     * Locked
     */
    Locked = "Locked"
}

/** Get the user status - whether user is an
 * active user or a disabled user or the user
 * account is locked */
export const getUserStatus = (userDisable: boolean, userLockedOut: string) => {
    if (userLockedOut != undefined && userDisable === undefined) return Status.Locked;
    else if (userLockedOut === undefined && userDisable === true) return Status.Disabled;
    else return Status.Enabled;
};

/** Get the image for the user status */
export const getStatusImage = (status: Status, t: i18n["t"]) => {
    switch (status) {
        case Status.Disabled:
            return {
                text: t("Value.Disabled"),
                image: <DisabledSVG />
            };
        case Status.Locked:
            return {
                text: t("Value.Locked"),
                image: <LockedSVG />
            };
        case Status.Enabled:
            return {
                text: t("Value.Enabled"),
                image: ""
            };
        default:
            return {
                text: t("Value.Enabled"),
                image: ""
            };
    }
};

export const renderUserStatus = (item: IHuxDetailsListItem, t: i18n["t"]) => {
    const theme = useTheme();
    const status = getStatusImage(item.status as Status, t);
    return (
        <Stack enableScopedSelectors horizontal>
            <StackItem style={{ paddingRight: 5 }}>{status.image}</StackItem>
            <StackItem>
                <Text style={{ fontSize: 12, color: theme.palette.neutralSecondary }}>{`${t(
                    status.text
                )}`}</Text>
            </StackItem>
        </Stack>
    );
};
