/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

export enum IconPlacement {
    /** Icon is placed before the name */
    Start = "Start",
    /** Icon is placed after the name */
    End = "End"
}
