/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */
import React, { useMemo, useState } from "react";
import {
    getUidAndLevelFromKey,
    HuxTreeMenu,
    HuxTreeSearchResults,
    ITreeNode
} from "@nuance/hux-components";
import {
    DefaultButton,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton,
    SearchBox,
    Spinner,
    SpinnerSize,
    Stack,
    Text
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import {
    HapOrgExplorationLevels,
    ISite,
    RoleTypes,
    useApiRequest,
    useHapAuth,
    UserManagementSvc,
    IGroup,
    screenReaderOnly,
    NmsBoolean
} from "@nuance/hap-components";
import { getLogger } from "@nuance/hux-diagnostics";
import { namespaces } from "../common/translationNamespaces";
import { panelStyles } from "../CommonStyles";

/** Interface for a selected group item */
export interface SelectedGroup {
    /** group name */
    name: string;
    /** group UID */
    groupUID: number;
    /** group roleType */
    roleType: RoleTypes;
    /** group site name */
    siteName: string;
}

/**
 * Props for SelectGroupsPanel
 */
export interface ISelectGroupsPanelProps {
    /** Call back for when the panel is closed */
    onDismiss: () => void;
    /** Callback for when the submit button is pressed */
    onAdd: (selected: SelectedGroup[]) => void;
    /** list of currently selected groups */
    selected: SelectedGroup[];
    /** UID of the org for getting data from API calls */
    orgUID: number;
    /** Text for the header of the panel */
    headerText: string;
    /** Text for the submit button */
    submitButtonText: string;
    /** Provide spinner text if the action upon clicking submit has loading time */
    spinnerText?: string;
    /**If set filter the groups to which user has access to enroll other users */
    showOnlyGroupsWhichUserCanEnroll: boolean;
}

/** styling for the search box */
const searchBoxStyles = {
    root: {
        margin: "16px 0"
    }
};

/**
 *
 * @param props ISelectGroupPanelProps
 * @returns JSX.Element
 */
export const SelectGroupsPanel = (props: ISelectGroupsPanelProps) => {
    const { t } = useTranslation([namespaces.NCC, namespaces.HapComponents]);
    const [query, setQuery] = useState<string>("");
    const [searchResultsStatus, setSearchResultsStatus] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const { userContext } = useHapAuth();
    const userManagementSvc = new UserManagementSvc(userContext);
    const logger = getLogger();

    const [selected, setSelected] = useState<SelectedGroup[]>(props.selected);
    let tree: ITreeNode[] = [];

    const getSiteReq = useApiRequest(userManagementSvc.getSitesByOrganization<ISite>(props.orgUID));
    const getGroupsReq = useApiRequest(userManagementSvc.getGroupsByOrg(props.orgUID));

    const isLoading = getSiteReq.isLoading || getGroupsReq.isLoading;
    const isError = getSiteReq.isError || getGroupsReq.isError;

    /** Actions to populate the TreeNode */
    const populateSitesAndGroups = (sites: ISite[], groups: IGroup[]) => {
        const siteNodes: ITreeNode[] = [];
        for (const site of sites) {
            const siteTree: ITreeNode = {
                name: site.Name,
                uid: site.UID,
                level: HapOrgExplorationLevels.Site
            };
            for (const group of groups) {
                if (
                    group.SiteUID === site.UID &&
                    (!props.showOnlyGroupsWhichUserCanEnroll ||
                        group.CanCreateUser === NmsBoolean.True)
                ) {
                    if (!siteTree.children) siteTree.children = [];

                    siteTree.children.push({
                        name: group.Name,
                        uid: group.UID,
                        level: HapOrgExplorationLevels.Group,
                        parentName: site.Name
                    });
                }
            }

            if (siteTree.children !== undefined && siteTree.children.length !== 0) {
                siteNodes.push(siteTree);
            }
        }

        return siteNodes;
    };

    /** If both API calls return with data populate the tree */
    tree = useMemo(() => {
        return populateSitesAndGroups(getSiteReq.data ?? [], getGroupsReq.data ?? []);
    }, [getSiteReq.data, getGroupsReq.data]);

    /** If either API call fails log error (error message displayed at top of panel as well) */
    if (isError) {
        logger.logError("Error loading sites or groups for SelectGroupsPanel");
    }

    /** Renders the footer for the panel */
    const renderFooter = () => {
        return (
            <div>
                <PrimaryButton
                    type="submit"
                    style={{ marginRight: "16px" }}
                    onClick={() => {
                        props.onAdd(selected);
                        /** When submit button is clicked loading will be initialized if spinner text is provided.
                         * When submission is complete, to close panel, change state of variable controlling the panel in the parent component */
                        props.spinnerText && setLoading(true);
                    }}
                    disabled={
                        JSON.stringify(selected) === JSON.stringify(props.selected) ||
                        selected.length === 0
                    }
                    ariaLabel={props.submitButtonText}
                >
                    {props.submitButtonText}
                </PrimaryButton>
                <DefaultButton
                    onClick={() => {
                        props.onDismiss();
                    }}
                    ariaLabel={t(`${namespaces.NCC}:Form.Cancel_Button`)}
                >
                    {t(`${namespaces.NCC}:Form.Cancel_Button`)}
                </DefaultButton>
            </div>
        );
    };

    /**
     * Footer to be rendered when the mutation is loading
     * @returns JSX.Element
     */
    const footerLoading = () => {
        return (
            <Stack horizontal tokens={{ childrenGap: "8px" }}>
                <Spinner
                    size={SpinnerSize.large}
                    style={{ height: "80%", justifySelf: "left" }}
                    ariaLive={"assertive"}
                    ariaLabel={props.spinnerText}
                ></Spinner>
                <Text>{props.spinnerText}</Text>
            </Stack>
        );
    };

    return (
        <Panel
            styles={panelStyles}
            isOpen={true}
            headerText={props.headerText}
            type={PanelType.custom}
            customWidth={"505px"}
            onRenderFooterContent={!loading ? renderFooter : footerLoading}
            isFooterAtBottom={true}
            onDismiss={props.onDismiss}
            isLightDismiss={true}
            closeButtonAriaLabel={t("Action.Close_Button")}
        >
            {isError && (
                <MessageBar messageBarType={MessageBarType.error}>{t("Error.Generic")}</MessageBar>
            )}
            <SearchBox
                styles={searchBoxStyles}
                placeholder={t("SelectObject.Filter")}
                onChange={(_, newValue) => {
                    setQuery(newValue ?? "");
                }}
                ariaLabel={t("SelectObject.Filter")}
            ></SearchBox>
            <div style={{ paddingBottom: "12px" }}>
                <Text>{t("Users.Enroll.Select_Sites_Tip")}</Text>
            </div>
            {isLoading && <Spinner label={t(`${namespaces.NCC}:Progress.Spinner_Loading`)} />}
            {!isLoading && tree && query.length <= 0 && (
                <HuxTreeMenu
                    tree={tree}
                    multiSelect
                    checkedKeys={selected.map(
                        group => `${group.groupUID}/${HapOrgExplorationLevels.Group}`
                    )}
                    onCheckboxClick={(keys: string[]) => {
                        const selected: SelectedGroup[] = [];
                        keys.forEach(key => {
                            const nodeUidLevel = getUidAndLevelFromKey(key);
                            if (nodeUidLevel.level === HapOrgExplorationLevels.Group) {
                                const group = getGroupsReq.data?.find(
                                    group => group.UID === nodeUidLevel.uid
                                );

                                if (group) {
                                    selected.push({
                                        name: group.Name,
                                        groupUID: group.UID,
                                        roleType: group.RoleType,
                                        siteName: group.SiteName
                                    });
                                }
                            }
                        });
                        setSelected(selected);
                    }}
                />
            )}
            {!isLoading && query.length === 1 && (
                <>
                    <div aria-live="polite" className={screenReaderOnly}>
                        {t(`${namespaces.HapComponents}:HapOrgExploration.EnterMinSearchChars`)}
                    </div>
                    <Text>
                        {t(`${namespaces.HapComponents}:HapOrgExploration.EnterMinSearchChars`)}
                    </Text>
                </>
            )}
            {!isLoading && tree && query.length > 1 && (
                <>
                    <div aria-live="polite" className={screenReaderOnly}>
                        {searchResultsStatus}
                    </div>
                    <HuxTreeSearchResults
                        multiSelect
                        onCheckboxClick={(keys: string[]) => {
                            const selected: SelectedGroup[] = [];
                            keys.forEach(key => {
                                const nodeUidLevel = getUidAndLevelFromKey(key);
                                if (nodeUidLevel.level === HapOrgExplorationLevels.Group) {
                                    const group = getGroupsReq.data?.find(
                                        group => group.UID === nodeUidLevel.uid
                                    );

                                    if (group) {
                                        selected.push({
                                            name: group.Name,
                                            groupUID: group.UID,
                                            roleType: group.RoleType,
                                            siteName: group.SiteName
                                        });
                                    }
                                }
                            });
                            setSelected(selected);
                        }}
                        query={query}
                        tree={tree}
                        checkedKeys={selected.map(
                            group => `${group.groupUID}/${HapOrgExplorationLevels.Group}`
                        )}
                        onSearch={(count: number) => {
                            if (count <= 0) {
                                setSearchResultsStatus(
                                    t(
                                        `${namespaces.HapComponents}:HapOrgExploration.NoSearchResults`
                                    )
                                );
                            } else {
                                setSearchResultsStatus(
                                    t(`${namespaces.HapComponents}:HapOrgExploration.Items_Found`, {
                                        numberOfItems: count
                                    })
                                );
                            }
                        }}
                    ></HuxTreeSearchResults>
                </>
            )}
        </Panel>
    );
};
