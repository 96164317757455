/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */

import { DefaultButton, IStackItemStyles, Label, Stack } from "@fluentui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { NCCTitleBar } from "../NCCTitleBar/NCCTitleBar";

export default function Logout() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const isAutoLogout = searchParams.get("isAutoLogout");
    const successLogoutMessage =
        isAutoLogout !== null && isAutoLogout === "true"
            ? t("LoggedOut.TimedOut_Message")
            : t("LoggedOut.Successful_Message");

    useEffect(() => {
        document.title = t("LoggedOut.Page_Title");
    }, []);

    const styles: Partial<IStackItemStyles> = {
        root: {
            textAlign: "center",
            justifyContent: "center"
        }
    };

    return (
        <Stack>
            <Stack.Item>
                <NCCTitleBar />
            </Stack.Item>
            <Stack.Item styles={styles}>
                <Label style={{ marginTop: "112px", fontSize: "18px" }}>
                    {successLogoutMessage}
                </Label>
                <DefaultButton
                    style={{ marginTop: "24px" }}
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    {t("LoggedOut.Return_To_Login_Button")}
                </DefaultButton>
            </Stack.Item>
        </Stack>
    );
}
