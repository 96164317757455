/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

import { IStackStyles, Stack } from "@fluentui/react";
import { HuxTextBox } from "@nuance/hux-components";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { i18n } from "i18next";
import * as Yup from "yup";
import {
    HapApiError,
    IPasswordRules,
    NotFoundError,
    UserManagementSvc,
    useHapAuth
} from "@nuance/hap-components";
import { useEffect, useState } from "react";
import YupPassword from "yup-password";
import { NUM_CONSECUTIVE_CHARS } from "../NCCConstants";
import { AxiosError } from "axios";
import { getLogger } from "@nuance/hux-diagnostics";
import { RequiredAsteriskKey, requiredStyle } from "../components/RequiredAsteriskKey";
YupPassword(Yup);

const logger = getLogger();

/**
 * User password properties
 */
export interface IUserPasswordProps {
    /**
     * The organization UID of the user for which password is going to configured.
     */
    organizationUid?: number;
    /**
     * The optional text for password field.
     */
    passwordFieldText?: string;
    /**
     * If set password complexity rules will be displayed as tooltip else it will shown above password fields.
     */
    showPasswordComplexityAsToolTip: boolean;
    /**
     * Callback while loading password rules.
     */
    onLoadingError: (errorMessage: string) => void;
    /**
     * Callback on password rules change.
     */
    onPasswordRulesChange: (rules: IPasswordRules) => void;
    /**
     * The required asterisk key is included by default, but can be removed by passing false to this property.
     */
    disableRequiredAsteriskKey?: boolean;
}

/**
 * Password complexity component props
 */
interface IPasswordComplexityProps {
    /**
     * If set show all rules for complexity.
     */
    mustMeetComplexityRequirements?: boolean;
    /**
     * The minimum length for the password.
     */
    minLength?: number;
}

/**
 * Form properties for the password.
 */
export interface IUserPassword {
    /**
     * User name.
     */
    UserName: string;
    /**
     * Password.
     */
    Password: string;
    /**
     * Confirm password
     */
    ConfirmPassword: string;
}

/**
 * Use this validation schema by itself or to concatenate to another validation schema and then initialize formik
 * @param t The initialized translation function
 * @param rules The password rules
 * @returns Password Validation schema to use with formik
 */
export const getPasswordValidationSchema = (t: i18n["t"], rules?: IPasswordRules) => {
    const passwordValidationSchema = rules
        ? Yup.object({
              Password: Yup.string()
                  .required(t("Error.Validation.Field_Required"))
                  .min(rules.MinLength, ({ min }) =>
                      t("Password.Validation.Meet_Min_Length_Characters", {
                          minCharacters: min
                      })
                  )
                  .when([], {
                      is: () => {
                          return rules.MustMeetComplexityRequirements;
                      },
                      then: schema =>
                          schema
                              .test(
                                  "Validate password for illegal characters",
                                  t("Password.Validation.Invalid_Characters"),
                                  function (newPassword) {
                                      return !new RegExp(rules.InvalidCharacters).test(newPassword);
                                  }
                              )
                              .minLowercase(1, t("Password.Validation.MinLowerCase"))
                              .minUppercase(1, t("Password.Validation.MinUpperCase"))
                              .minNumbers(1, t("Password.Validation.MinNumbers"))
                              .minSymbols(1, t("Password.Validation.MinSymbols"))
                              .max(rules.MaxLength, ({ max }) =>
                                  t("Error.Validation.Exceed_Max_Length_Characters", {
                                      maxCharacters: max
                                  })
                              )
                              .test(
                                  "Validate password for consecutive characters",
                                  t("Password.Complexity_Text.ConsecutiveCharacters"),
                                  function (newPassword) {
                                      const username = this.parent.UserName;
                                      const password = newPassword?.toLowerCase();
                                      if (username) {
                                          for (
                                              let i = 0;
                                              i < username.length - NUM_CONSECUTIVE_CHARS;
                                              i++
                                          ) {
                                              const curCombination =
                                                  username[i] + username[i + 1] + username[i + 2];
                                              if (password?.indexOf(curCombination) !== -1) {
                                                  return false;
                                              }
                                          }
                                      }
                                      return true;
                                  }
                              )
                  }),
              ConfirmPassword: Yup.string()
                  .required(t("Error.Validation.Field_Required"))
                  .oneOf([Yup.ref("Password")], t("Password.Validation.Password_Mismatch"))
          })
        : Yup.object();

    return passwordValidationSchema;
};

const panelStack: IStackStyles = {
    root: {
        paddingRight: 10
    }
};

const subHeading: IStackStyles = {
    root: {
        paddingTop: 20,
        paddingBottom: 10
    }
};

/**
 * @description PasswordComplexity component information about complexity requirement of password based on settings.
 * @param props {IPasswordComplexityProps} - The props containing settings for password complexity.
 * @returns The PasswordComplexity component.
 */
const PasswordComplexity = (props: IPasswordComplexityProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Stack styles={panelStack}>
            <Stack styles={subHeading}>
                <Stack.Item>
                    <b>{t("Password.Complexity_Header")}</b>
                </Stack.Item>
            </Stack>
            <Stack>
                <Stack.Item>
                    <ul style={{ margin: 0 }}>
                        <li>
                            {t("Password.Complexity_Text.MinLength", {
                                minCharacters: props.minLength
                            })}
                        </li>
                        {props.mustMeetComplexityRequirements && (
                            <>
                                <li>{t("Password.Complexity_Text.ConsecutiveCharacters")}</li>
                                <li>
                                    {t("Password.Complexity_Text.CategoriesRequirement")}
                                    <ul>
                                        <li>{t("Password.Complexity_Text.Uppercase")}</li>
                                        <li>{t("Password.Complexity_Text.Lowercase")}</li>
                                        <li>{t("Password.Complexity_Text.Numbers")}</li>
                                        <li>{t("Password.Complexity_Text.NonAlphaCharacters")}</li>
                                    </ul>
                                </li>
                            </>
                        )}
                    </ul>
                </Stack.Item>
            </Stack>
        </Stack>
    );
};

/**
 * @description UserPassword component contains form elements for password for the user.
 * @param props {IUserPasswordProps} - The props for UserPassword component .
 * @returns The UserPassword component.
 */
export const UserPassword = (props: IUserPasswordProps): JSX.Element => {
    const { t } = useTranslation();
    const { userContext } = useHapAuth();
    const [passwordRules, setPasswordRules] = useState<IPasswordRules>();
    const userManagementSvc = new UserManagementSvc(userContext);

    useEffect(() => {
        if (props.organizationUid) {
            userManagementSvc
                .getPasswordRules(props.organizationUid)
                .execute()
                .then(async rules => {
                    setPasswordRules(rules);
                    props.onPasswordRulesChange(rules);
                })
                .catch((e: AxiosError | HapApiError | Error | NotFoundError) => {
                    logger.logError("Error while getting password rules", {
                        message: e.message
                    });
                    props.onLoadingError(t("Error.Unable_to_Load"));
                });
        }
    }, [props.organizationUid]);

    const formik = useFormikContext<IUserPassword>();

    if (formik === undefined)
        throw new Error("You must wrap UserPassword component in <FormikProvider>");
    return (
        <>
            {passwordRules && (
                <Stack style={{ paddingBottom: 16 }} tokens={{ childrenGap: 16 }}>
                    {!props.showPasswordComplexityAsToolTip && (
                        <PasswordComplexity
                            mustMeetComplexityRequirements={
                                passwordRules?.MustMeetComplexityRequirements
                            }
                            minLength={passwordRules?.MinLength}
                        />
                    )}
                    {!props.disableRequiredAsteriskKey && (
                        <RequiredAsteriskKey style={{ ...requiredStyle, marginBottom: 0 }} />
                    )}
                    <HuxTextBox
                        infoText={
                            props.showPasswordComplexityAsToolTip
                                ? {
                                      element: (
                                          <PasswordComplexity
                                              mustMeetComplexityRequirements={
                                                  passwordRules?.MustMeetComplexityRequirements
                                              }
                                              minLength={passwordRules?.MinLength}
                                          />
                                      ),
                                      text: getPasswordRequirementAriaText(
                                          {
                                              minLength: passwordRules.MinLength,
                                              mustMeetComplexityRequirements:
                                                  passwordRules.MustMeetComplexityRequirements
                                          },
                                          t
                                      )
                                  }
                                : undefined
                        }
                        required
                        label={
                            props.passwordFieldText
                                ? props.passwordFieldText
                                : t("Password.Password_Label")
                        }
                        type={"Password"}
                        name="Password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorMessage={formik.touched.Password ? formik.errors.Password : undefined}
                    ></HuxTextBox>
                    <HuxTextBox
                        required
                        label={t("Password.Confirm_Password_Label")}
                        type={"Password"}
                        name="ConfirmPassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorMessage={
                            formik.touched.ConfirmPassword
                                ? formik.errors.ConfirmPassword
                                : undefined
                        }
                    ></HuxTextBox>
                </Stack>
            )}
        </>
    );
};

function getPasswordRequirementAriaText(props: IPasswordComplexityProps, t: i18n["t"]) {
    let text = `${t("Password.Complexity_Header")}\n${t("Password.Complexity_Text.MinLength", {
        minCharacters: props.minLength
    })}\n`;
    if (props.mustMeetComplexityRequirements) {
        text = text.concat(
            `${t("Password.Complexity_Text.ConsecutiveCharacters")}\n`,
            `${t("Password.Complexity_Text.CategoriesRequirement")}\n`,
            `${t("Password.Complexity_Text.Uppercase")}\n`,
            `${t("Password.Complexity_Text.Lowercase")}\n`,
            `${t("Password.Complexity_Text.Numbers")}\n`,
            `${t("Password.Complexity_Text.NonAlphaCharacters")}\n`
        );
    }
    return text;
}
