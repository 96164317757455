/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import { IDropdownOption } from "@fluentui/react";
import { IHapUserContext, UserManagementSvc } from "@nuance/hap-components";
import { getLogger } from "@nuance/hux-diagnostics";
import { i18n } from "i18next";

export const userTypeI18nKeys: Record<number, string> = {
    0: "Users.BasicInfo.UserType.Value_User",
    1: "Users.BasicInfo.UserType.Value_Physician",
    2: "Users.BasicInfo.UserType.Value_Nurse",
    3: "Users.BasicInfo.UserType.Value_Resident",
    4: "Users.BasicInfo.UserType.Value_PhysicianAssistant",
    5: "Users.BasicInfo.UserType.Value_NMCAdmin",
    6: "Users.BasicInfo.UserType.Value_OtherNonPhysician",
    7: "Users.BasicInfo.UserType.Value_NursePractitioner"
};

export const getUserTypeString = (userType: number, t: i18n["t"]) => {
    return userTypeI18nKeys[userType]
        ? t(userTypeI18nKeys[userType])
        : t("Users.BasicInfo.UserType.Value_User");
};

/**
 * Get the dropdown options for user types
 * @param t The function to translate.
 * @returns Dropdown options for user types.
 */
export const getUserTypeOptions = (t: i18n["t"]): IDropdownOption[] => {
    const userTypes: IDropdownOption[] = [];
    for (const key in userTypeI18nKeys) {
        userTypes.push({ text: t(userTypeI18nKeys[key]), key: Number(key) });
    }
    return userTypes.sort((a, b) => a.text.localeCompare(b.text));
};

const logger = getLogger();
const MAX_GROUPS = 32;

/**
 * Updates the user's groups (add, deletes, and reorders)
 * @param groupUIDs groupUIDs user should be in, in priority order
 * @param userUID User's UID
 * @param userContext
 * @returns Promise, resolves if success, rejects with failed group UIDs if fails
 */
export const updateGroupEnrollment = async (
    groupUIDs: number[],
    userUID: number,
    userContext: IHapUserContext
) => {
    let index = 0;
    const failedToEnrollUIDs: number[] = [];

    const userManagementSvc = new UserManagementSvc(userContext);
    do {
        await userManagementSvc
            .updateGroupEnrollmentForUser(
                userUID,
                groupUIDs.slice(index, index + MAX_GROUPS),
                index
            )
            .execute()
            .then((UIDs: number[]) => {
                // API returns UIDs of groups that user could not be enrolled in
                failedToEnrollUIDs.push(...UIDs);
            })
            .catch(() => {
                const errorMsg = "Failed to enroll user in selected groups.";
                logger.logError(errorMsg);
                return Promise.reject(failedToEnrollUIDs);
            });
        index += MAX_GROUPS;
    } while (index < groupUIDs.length);
    if (failedToEnrollUIDs.length === 0) {
        return Promise.resolve();
    } else {
        // we have groups that could not be enrolled.
        return Promise.reject(failedToEnrollUIDs);
    }
};
