/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

import { useTranslation } from "react-i18next";

import { IOrganization } from "@nuance/hap-components";
import { useState } from "react";
import { HuxControlLabel, HuxListItem, ITreeNode } from "@nuance/hux-components";
import { useBoolean } from "@fluentui/react-hooks";
import { ActionButton, Stack, StackItem, Text } from "@fluentui/react";
import { ChangeGroupPanel } from "../components/panels/ChangeGroupPanel";
import { useFormikContext } from "formik";
import { errorStyle } from "../CommonStyles";

export interface IChangeGroupDataItem {
    /**
     * The selected group Uid.
     */
    group?: ITreeNode;
}

/**
 * Interface for ChangeGroup props
 */
export interface IChangeGroupProps {
    /** the organization to use for group search */
    organization: IOrganization;
    /** the selected group */
    group?: ITreeNode;
}

/**
 *
 * @param props - The props for change group component.
 * @returns The ChangeGroup Component.
 */
export function ChangeGroup(props: IChangeGroupProps) {
    const { t } = useTranslation();
    const formik = useFormikContext<IChangeGroupDataItem>();

    const [isGroupPanelOpen, { toggle: toggleIsGroupPanelOpen }] = useBoolean(false);
    const [group, setGroup] = useState<ITreeNode | undefined>(props.group);
    const [organizationUid, setOrganizationUid] = useState(props.organization?.UID);

    // reset group if org changes
    if (organizationUid && props.organization?.UID !== organizationUid) {
        setOrganizationUid(props.organization?.UID);
        setGroup(undefined);
        formik.setFieldValue("group", undefined);
    }

    if (formik === undefined)
        throw new Error("You must wrap ChangeGroup component in <FormikProvider>");

    return (
        <>
            <HuxControlLabel
                labelText={t("Users.Add.Service_Principal.Group_Label")}
            ></HuxControlLabel>
            <Stack horizontal horizontalAlign="space-between">
                <StackItem>
                    {group ? (
                        <HuxListItem text={group.name} subtext={group.parentName}></HuxListItem>
                    ) : (
                        <Stack tokens={{ childrenGap: "10px" }}>
                            <StackItem>
                                <Text>{t("Value.None")}</Text>
                            </StackItem>
                            <StackItem>
                                <Text style={errorStyle}>
                                    {t("Users.Add.Service_Principal.Group_Please_Select_Text")}
                                </Text>
                            </StackItem>
                        </Stack>
                    )}
                </StackItem>
                <StackItem>
                    <ActionButton
                        iconProps={{
                            iconName: "Switch"
                        }}
                        style={{ height: 20 }}
                        onClick={() => {
                            toggleIsGroupPanelOpen();
                        }}
                    >
                        {t("ProductDetails.Provisioning.Change_Group_Button_Text")}
                    </ActionButton>
                </StackItem>
            </Stack>

            {isGroupPanelOpen && props.organization && (
                <ChangeGroupPanel
                    org={{
                        name: props.organization.Name,
                        uid: props.organization.UID,
                        guid: props.organization.NuanceHCAccountGuid
                    }}
                    selectedNode={group}
                    onClosePanel={node => {
                        toggleIsGroupPanelOpen();
                        if (node) {
                            setGroup(node);
                            formik.setFieldValue("group", node);
                        }
                    }}
                ></ChangeGroupPanel>
            )}
        </>
    );
}
