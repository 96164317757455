/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import axios from "axios";
import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { provisioningTypes } from "../common/provisioningTypes";

const ProductMetaDataSchema = z.object({
    /** Internal name of the product */
    productName: z.string().min(1),
    /** Product Guid */
    productGuid: z.string().min(1),
    /** Hierarchical setting definition name for the product */
    definitionName: z.string().optional(),
    /** The product provisioning type */
    provisioningType: z.nativeEnum(provisioningTypes).optional(),
    /** Is auto provisioning enabled by default */
    defaultAutoProvisioning: z.boolean().optional(),
    /** Does this product allow license restrictions */
    allowLicenseRestriction: z.boolean().optional()
});

/**
 * NCCv2 Metadata for a product
 */
export type ProductMetaData = z.infer<typeof ProductMetaDataSchema>;

/**
 * Application configuration data.
 *
 * @see config-schema.json Please make sure you keep both files  in-sync!
 */
const AppConfigDataSchema = z.object({
    /** environment type */
    environment: z.string(),
    /** Application Insights connection string */
    appInsightsConnectionString: z.string().min(1),
    /** Auth0 configuration information */
    auth0Config: z
        .object({
            /** Auth0 domain to use for authentication */
            domain: z.string().min(1),
            /** Auth0 client identified */
            clientId: z.string().min(1),
            /** Audience associated with the application */
            audience: z.string().min(1),
            /** NMS product guid for the application */
            productGuid: z.string().min(1),
            /** application name */
            nmsCallerId: z.string().min(1)
        })
        .optional(),
    /** AAD configuration information */
    aadConfig: z
        .object({
            /** Entra id authority */
            authority: z.string().min(1).optional(),
            /** AAD client identifier */
            clientId: z.string().min(1),
            /** optional AAD scopes */
            scopes: z.array(z.string().min(1)).optional(),
            /** NMS product guid for the application */
            productGuid: z.string().min(1),
            /** application name */
            nmsCallerId: z.string().min(1)
        })
        .optional(),
    /** FedRamp ADFS configuration. */
    ADFSConfig: z
        .object({
            /** ADFS client identifier */
            clientId: z.string().min(1),
            /** ADFS authority */
            authority: z.string().min(1),
            /** optional AAD scopes */
            scopes: z.array(z.string().min(1)).optional(),
            /** NMS product guid for the application */
            productGuid: z.string().min(1),
            /** NMS called id */
            nmsCallerId: z.string().min(1),
            /** Resource */
            resource: z.string().optional()
        })
        .optional(),
    /** URL to Power BI reports */
    powerBiEmbedUrl: z.string().min(1),
    /** Feature set */
    featureSet: z.number().min(0).max(3),
    /** System-specific hash function salt value */
    salt: z.string().min(1),
    /** Application Metadata for products */
    productsMetaData: ProductMetaDataSchema.array()
});

/** Application configuration information */
export type AppConfig = z.infer<typeof AppConfigDataSchema>;

/**
 * HTTP request to get the application configuration
 * @returns Application configuration
 */
export async function getAppConfig(): Promise<AppConfig> {
    const response = await axios.get("/config.json");
    const result = AppConfigDataSchema.parse(response.data);
    return result;
}

/**
 * Hook to get (and cache) the application configuration
 * @returns Application configuration
 */
export function useAppConfig() {
    return useQuery<AppConfig>({ queryKey: ["config"], queryFn: getAppConfig });
}
