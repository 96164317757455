/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */

import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    useApiRequest,
    useHapAuth,
    UserManagementSvc,
    IGetUsersSearchParameters,
    IGroup,
    NmsBoolean,
    IOrgGroup,
    CacheKey,
    convertToLocaleDate,
    parseNMSDate,
    LicenseMode,
    NmsPrivilege,
    IUser
} from "@nuance/hap-components";
import {
    SelectionMode,
    MessageBarType,
    Stack,
    Text,
    ICommandBarItemProps,
    Selection
} from "@fluentui/react";
import {
    HuxDetailsList,
    IHuxDetailsListItem,
    HuxSimplePagination,
    IHuxMessageProps,
    postMessage,
    IHuxDetailsListColumn,
    formatUserName,
    UserNameFormat,
    INoItemsAvailableProps,
    IHuxDetailsListActionsProps,
    HuxFeatureContext,
    HuxConfirmDialog,
    NoItemsType
} from "@nuance/hux-components";
import { useTranslation } from "react-i18next";
import { getLogger } from "@nuance/hux-diagnostics";
import { EventTypes } from "../EventTypes";
import { getUserTypeString } from "../users/UserFunctions";
import { HashSvc } from "../services/HashSvc";
import { UserHoverCard } from "./UserHoverCard";
import { NccFeatures } from "../common/NccFeature/NccFeatures";
import { useBoolean } from "@fluentui/react-hooks";
import { GroupUserEnrollmentPanel } from "./GroupUserEnrollmentPanel";
import { useQueryClient } from "@tanstack/react-query";
import { instanceOfIOrgGroup } from "../common/GroupFunctions";
import { GrantLicensesPanel } from "../license/GrantLicensesPanel";
import { AddUserPanel, IAddUserPanelProps } from "../groups/users/AddUserPanel";
import { AddUserConfirmDialog, NewUserAdded } from "../groups/users/AddUserConfirmDialog";
import UserGrantLicensesPanel from "../users/UserGrantLicensesPanel";
import { ActionButtonWithTooltip } from "./ActionButtonWithTooltip";
import { getUserStatus, renderUserStatus } from "../components/UserStatus";

const logger = getLogger();

/**
 * Props for UserListComponent
 */
export interface IUserListComponentProps {
    /* Optional columns to specify for the table */
    columns?: IHuxDetailsListColumn[];
    /** Parameters controlling the search results */
    userRequestParameters?: IGetUsersSearchParameters;
    /** What parameters are desired in the return data */
    include?: string[];
    /** Callback for parent component to know when loading has finished */
    onSearchComplete?: () => void;
    /** Label for pagination footer and list ariaGridLabel */
    label: string;
    /** what to display when there are no items on the list */
    emptyStateProps?: INoItemsAvailableProps;
    /** Display enroll/unenroll command */
    showEnrollmentCommandsForGroup?: IGroup | IOrgGroup;
    /** Display Add User Command */
    showAddUserCommandForGroup?: IGroup;
    /* If passed, table will show licenses command buttons (grant and revoke)*/
    showLicenseCommands?: {
        /* number of licenses available for this license type / partner */
        availableLicenses: number;
        /* the license type guid */
        licenseTypeGuid: string;
        /* the partner Uid */
        partnerUid: number;
        /* The guid of the partner */
        partnerGuid: string;
        /* The UID of the organization */
        orgUid: number;
        /* the mode of the license */
        licenseMode: LicenseMode;
    };
    /** Informs parent when skip has changed */
    updateSkip?: (skip: number) => void;
    /** If true include selection count */
    includeSelectionCount?: boolean;
}

/**
 * Component for displaying a list of users based on desired search parameters
 *
 * @param props IUserListComponentProps
 * @returns JSX.Element
 */
export const UserListComponent = (props: IUserListComponentProps) => {
    if (props.showEnrollmentCommandsForGroup && props.showLicenseCommands) {
        throw new Error(
            "Cannot show license and enrollment commands. Please select only one or the other."
        );
    }
    const { userContext } = useHapAuth();
    const navigate = useNavigate();
    const { t } = useTranslation(["NCC", "HuxComponents"]);
    const { settings: huxFeatureSettings } = useContext(HuxFeatureContext);
    const [isUnenrollDialogHidden, { toggle: toggleIsUnenrollDialogHidden }] = useBoolean(true);
    const [isGrantLicensesPanelOpen, { toggle: toggleIsGrantLicensesPanelOpen }] =
        useBoolean(false);
    const [
        isRevokeLicensesConfirmDialogHidden,
        { toggle: toggleIsRevokeLicensesConfirmDialogHidden }
    ] = useBoolean(true);
    const hashService = HashSvc.getInstance();
    const [isEnrollmentPanelOpen, { toggle: toggleIsEnrollmentPanelOpen }] = useBoolean(false);
    const [isAddUserPanelOpen, { toggle: toggleIsAddUserPanelOpen }] = useBoolean(false);
    const [isAddUserConfirmDialog, { toggle: toggleIsAddUserConfirmDialog }] = useBoolean(false);
    const [newUserAdded, setNewUserAdded] = useState<NewUserAdded>();
    const [userToAssignLicense, setUserToAssignLicense] = useState<IUser>();
    const [
        isAddAnotherUser,
        { setTrue: setTrueIsAddAnotherUser, setFalse: setFalseIsAddAnotherUser }
    ] = useBoolean(false);
    const [isAssignLicensesPanelOpen, { toggle: toggleIsAssignLicensesPanelOpen }] =
        useBoolean(false);
    const queryClient = useQueryClient();

    const isOrgGroup =
        props.showEnrollmentCommandsForGroup &&
        instanceOfIOrgGroup(props.showEnrollmentCommandsForGroup);

    /** Create state variable for skip value from props */
    const [skip, setSkip] = useState(props.userRequestParameters?.skip ?? 0);
    /** Create variable for take from props */
    const take = props.userRequestParameters?.take ?? 100;

    const [selectedUsersIndex, setSelectedUsersIndex] = useState<number[]>([]);

    const createSelection = (): Selection => {
        return new Selection({
            onSelectionChanged: () => {
                const selectedRows = selection.getSelectedIndices();
                setSelectedUsersIndex(selectedRows);
            }
        });
    };

    const [selection] = useState(createSelection());

    const userManagementSvc = new UserManagementSvc(userContext);
    const getUsersReq = userManagementSvc.getUsers(
        {
            ...props.userRequestParameters,
            skip: skip,
            take: take
        },
        props.include
    );

    /** API call to get users based on the provided parameters */
    const {
        isInitialLoading,
        isFetching,
        error,
        data: users
    } = useApiRequest(getUsersReq, {
        enabled: props.userRequestParameters !== undefined
    });

    const emptySearchMsg = {
        type: NoItemsType.NoResults,
        heading: t("Search.Error.No_Results_Heading"),
        subHeading: t("Search.Error.No_Results_Text")
    };

    const startSearchMsg = {
        type: NoItemsType.StartSearch,
        heading: t("Search.Start_Search_Heading")
    };

    const emptyStateSearchProps = props.userRequestParameters ? emptySearchMsg : startSearchMsg;

    /** useEffect for handling error message and isLoading state to parent */
    useEffect(() => {
        if (error) {
            const msgProps: IHuxMessageProps = {
                message: t("Error.Unable_to_Load"),
                messageBarType: MessageBarType.error
            };
            postMessage(msgProps);
        }

        if (!isInitialLoading) {
            props.onSearchComplete && props.onSearchComplete();
        }
    }, [error, isInitialLoading]);

    useEffect(() => {
        if (props.userRequestParameters?.skip !== undefined)
            setSkip(props.userRequestParameters.skip);
    }, [props.userRequestParameters]);

    /** Mapping returned data to fit into details list */
    const items = users?.map((user, index) => {
        const hashedOrgUID = hashService.HashEncode(user.OrganizationUID);
        return {
            id: formatUserName(user, UserNameFormat.LFM),
            index,
            login: user.Login,
            email: user.EMailAddress,
            department: user.Department,
            primarySpecialty: user.PrimarySpecialty,
            userType: getUserTypeString(user.UserType, t),
            href: `/users/${user.NuanceUserGuid}/orgs/${hashedOrgUID}/overview`,
            groupCount: user.GroupEnrollmentCount ?? 0,
            grantedOn: formatDateGrantedOn(user.LicenseGrantedOn),
            organization: user.AccountName,
            canModify: user.CanModify,
            status: getUserStatus(user.Disabled, user.PasswordLockoutDate),
            key: user.UID
        };
    });

    /**
     * Actions taken place when the user hovers over a specific user in the name column
     * @param item IHuxDetailsListItem
     * @returns JSX.Element
     */
    const onRenderHoverCard = (item: IHuxDetailsListItem) => {
        if (users) {
            const user = users[item.index];
            return <UserHoverCard user={user} />;
        }
        return <></>;
    };

    // render user hovercard on first column of custom columns
    // cannot pass this in since userList manages users
    if (props.columns && props.columns.length > 0) {
        props.columns[0].onRenderHoverCard = onRenderHoverCard;
    }

    /** Columns to be shown in the details list */
    const columns: IHuxDetailsListColumn[] = props.columns ?? [
        {
            key: "nameCol",
            name: t("Groups.MembersTable.Name_Head"),
            fieldName: "id",
            minWidth: 200,
            isResizable: true,
            onRenderHoverCard: onRenderHoverCard
        },
        {
            key: "usernameCol",
            name: t("Groups.MembersTable.Username_Head"),
            fieldName: "login",
            minWidth: 200,
            isResizable: true
        },
        {
            key: "emailCol",
            name: t("Groups.MembersTable.Email_Head"),
            fieldName: "email",
            minWidth: 150,
            isResizable: true
        },
        {
            key: "statusCol",
            name: t("Groups.MembersTable.Status_Head"),
            fieldName: "status",
            minWidth: 120,
            isResizable: true,
            onRender: (item: IHuxDetailsListItem) => renderUserStatus(item, t)
        },
        {
            key: "departmentCol",
            name: t("Groups.MembersTable.Department_Head"),
            fieldName: "department",
            minWidth: 150,
            isResizable: true
        },
        {
            key: "primarySpecialtyCol",
            name: t("Groups.MembersTable.Primary_Specialty_Head"),
            fieldName: "primarySpecialty",
            minWidth: 150,
            isResizable: true
        },
        {
            key: "userTypeCol",
            name: t("Groups.MembersTable.User_Type_Head"),
            fieldName: "userType",
            minWidth: 100,
            isResizable: true
        }
    ];

    /** Actions that take place when a user clicks on a User link */
    const onUserLinkClick = (
        ev?: React.MouseEvent<HTMLElement> | Event,
        item?: IHuxDetailsListItem
    ) => {
        ev?.preventDefault();
        if (item?.href) {
            logger.trackEvent(EventTypes.UserLinkClicked, {
                user: item?.id
            });
            navigate(item.href as string);
        }
    };

    function onUnenrollSuccess(
        eventType: string,
        trackEventData: { [key: string]: string | number }
    ) {
        logger.trackEvent(eventType, trackEventData);
        const msgProps: IHuxMessageProps = {
            message: t("Groups.Unenroll.Unenroll_Members_Success_Message"),
            timeout: 5,
            messageBarType: MessageBarType.success,
            "aria-live": "assertive"
        };
        postMessage(msgProps);
        setSelectedUsersIndex([]);
        // clear cache so user list is updated in group page
        queryClient.invalidateQueries({ queryKey: getUsersReq.cacheKey });
        if (!isOrgGroup) {
            // also update user pages
            queryClient.invalidateQueries({ queryKey: [CacheKey.getUserEnrollment] });
            // and group enrollment panels
            queryClient.invalidateQueries({ queryKey: [CacheKey.getGroupsByUser] });
            // and EHR records
            queryClient.invalidateQueries({ queryKey: [CacheKey.getOrgSiteEhr] });
            queryClient.invalidateQueries({ queryKey: [CacheKey.getUserEhr] });
        }
        if (isOrgGroup) {
            // getOrgGroupsByUserUID
            queryClient.invalidateQueries({ queryKey: [CacheKey.getOrgGroups] });
            // getOrgGroupEnrollment
            queryClient.invalidateQueries({
                queryKey: [(group as IOrgGroup).OrgGuid, CacheKey.getOrgGroup]
            });
            // invalidate org groups on advisorGroup tab as member count will have updated
            queryClient.invalidateQueries({ queryKey: [CacheKey.getOrgGroupsByOrg] });
        }
        return undefined;
    }

    let selectionMode = SelectionMode.none;
    const group = isOrgGroup
        ? (props.showEnrollmentCommandsForGroup as IOrgGroup)
        : (props.showEnrollmentCommandsForGroup as IGroup);

    const unenrollUsersHandler = async () => {
        if (group && selectedUsersIndex && items) {
            let failed = false;
            const userUIDs = selectedUsersIndex.map(index => {
                return items[index].key;
            });
            if (!isOrgGroup) {
                // normal group unenrollment flow
                const { UID: groupUid, Name: groupName } = group as IGroup;
                await userManagementSvc
                    .unenrollUsersFromGroup(groupUid, userUIDs)
                    .execute()
                    .then(() => {
                        return onUnenrollSuccess(EventTypes.UsersRemovedFromGroup, {
                            groupUid,
                            groupName,
                            userCount: selectedUsersIndex.length
                        });
                    })
                    .catch((e: Error) => {
                        failed = true;
                        logger.logError("Error unenrolling users from group", {
                            message: e.message
                        });
                    });
            } else {
                // org group unenrollment flow
                const { OrgGroupId, OrgGroupName } =
                    props.showEnrollmentCommandsForGroup as IOrgGroup;
                await userManagementSvc
                    .unenrollUsersFromOrgGroup(OrgGroupId, userUIDs)
                    .execute()
                    .then(() => {
                        return onUnenrollSuccess(EventTypes.UsersRemovedFromOrgGroup, {
                            OrgGroupId,
                            OrgGroupName,
                            userCount: selectedUsersIndex.length
                        });
                    })
                    .catch((e: Error) => {
                        failed = true;
                        logger.logError("Error unenrolling users from org group", {
                            message: e.message
                        });
                    });
            }

            if (failed) {
                return t("Groups.Unenroll.Unenroll_Members_Error_Message");
            }
        }
        return undefined;
    };

    const revokeHandler = async (): Promise<string | undefined> => {
        if (props.showLicenseCommands && selectedUsersIndex && items) {
            const licenseData = props.showLicenseCommands;
            const userUIDs = selectedUsersIndex.map(index => {
                return items[index].key;
            });
            await Promise.allSettled(
                userUIDs.map(userUid => {
                    return userManagementSvc
                        .revokeUserLicense(
                            userUid,
                            licenseData.licenseTypeGuid,
                            licenseData.partnerUid
                        )
                        .execute();
                })
            )
                .then(() => {
                    logger.trackEvent(EventTypes.UsersLicenseRevoked, {
                        users: userUIDs.length,
                        licenseTypeGuid: licenseData.licenseTypeGuid,
                        partnerGuid: licenseData.partnerGuid
                    });
                    const msgProps: IHuxMessageProps = {
                        message: t("ProductDetails.Licenses.Revoked_Success_Message"),
                        timeout: 5,
                        messageBarType: MessageBarType.success,
                        "aria-live": "assertive"
                    };
                    postMessage(msgProps);
                    setSelectedUsersIndex([]);
                    return t("ProductDetails.Licenses.Revoked_Success_Message");
                })
                .catch(() => {
                    logger.logError("Failed to revoke license");
                    return t("ProductDetails.Licenses.Revoked_Failure_Message");
                })
                .finally(() => {
                    queryClient.invalidateQueries({ queryKey: [CacheKey.getUsers] });
                    queryClient.refetchQueries({
                        queryKey: [CacheKey.getLicenseSummaries, licenseData.orgUid]
                    });
                });
        } else {
            return undefined;
        }
    };

    const commandBarActions: IHuxDetailsListActionsProps = { left: [] };

    if (
        group &&
        (group.CanModify === NmsBoolean.True || group.CanModify === true) &&
        huxFeatureSettings.isAllowed(NccFeatures.Group_UserEnrollment)
    ) {
        const canUnenroll = () => {
            if (items) {
                return selectedUsersIndex.every(index => {
                    const row = items[index];
                    return isOrgGroup ? true : row.groupCount > 1;
                });
            }
            return false;
        };
        const enrollButton: ICommandBarItemProps = {
            canCheck: false,
            key: "enroll",
            name: t("Groups.MembersTable.Enroll_Button"),
            ariaLabel: t("Groups.MembersTable.Enroll_Button"),
            onClick: () => {
                toggleIsEnrollmentPanelOpen();
            },
            iconProps: { iconName: "Add" },
            role: "button",
            disabled: selectedUsersIndex.length > 0
        };
        commandBarActions.left.push(enrollButton);
        const unenrollButton = useCallback((): JSX.Element => {
            return (
                <ActionButtonWithTooltip
                    ariaLabel={t("Groups.MembersTable.Unenroll_Button")}
                    iconProps={{ iconName: "Cancel" }}
                    onClick={toggleIsUnenrollDialogHidden}
                    text={t("Groups.MembersTable.Unenroll_Button")}
                    role={"button"}
                    disabled={selectedUsersIndex.length === 0 || !canUnenroll()}
                    tooltipContent={
                        selectedUsersIndex.length === 0 && canUnenroll()
                            ? t("Groups.Unenroll.Unenroll_Users_Tooltip")
                            : selectedUsersIndex.length === 0 || canUnenroll()
                              ? ""
                              : t("Groups.Unenroll.Unenroll_OnlyGroup_Tooltip")
                    }
                ></ActionButtonWithTooltip>
            );
        }, [selectedUsersIndex]);
        if (group.CanCreateUser === NmsBoolean.True || group.CanCreateUser === true) {
            commandBarActions.left.push({
                key: "unenrollButton",
                commandBarButtonAs: unenrollButton
            });
        }
    }
    if (
        props.showLicenseCommands &&
        (userContext.isSuperUser() || userContext.hasPrivilege(NmsPrivilege.ManageLicenses))
    ) {
        const showLicenseCommands = props.showLicenseCommands;
        const canRevoke = () => {
            if (selectedUsersIndex.length > 0 && items) {
                return selectedUsersIndex.every(index => {
                    const row = items[index];
                    return row.canModify;
                });
            }
            return false;
        };
        /**
         * Grant licenses command button wrapped in tooltip
         */
        const grantLicensesButton = useCallback((): JSX.Element => {
            return (
                <ActionButtonWithTooltip
                    tooltipContent={
                        showLicenseCommands.availableLicenses > 0
                            ? ""
                            : t("ProductDetails.Licenses.No_Licenses_Left_Text")
                    }
                    ariaLabel={t("Licenses.Grant_Button")}
                    iconProps={{ iconName: "UserFollowed" }}
                    onClick={() => {
                        toggleIsGrantLicensesPanelOpen();
                    }}
                    text={t("Licenses.Grant_Button")}
                    role={"button"}
                    disabled={
                        showLicenseCommands.availableLicenses <= 0 ||
                        showLicenseCommands?.licenseMode != LicenseMode.Account ||
                        isGrantLicensesPanelOpen ||
                        selectedUsersIndex.length > 0
                    }
                ></ActionButtonWithTooltip>
            );
        }, [showLicenseCommands, isGrantLicensesPanelOpen, selectedUsersIndex]);

        // can only grant licenses in account mode
        if (showLicenseCommands.licenseMode === LicenseMode.Account) {
            commandBarActions.left.push({
                key: "grant",
                commandBarButtonAs: grantLicensesButton
            });
        }
        commandBarActions.left.push({
            canCheck: false,
            key: "revoke",
            name: t("Licenses.Revoke_Button"),
            ariaLabel: t("Licenses.Revoke_Button"),
            onClick: () => {
                toggleIsRevokeLicensesConfirmDialogHidden();
            },
            iconProps: { iconName: "UserRemove" },
            disabled: !canRevoke()
        });
    }
    if (props.showAddUserCommandForGroup?.CanCreateUser === NmsBoolean.True) {
        commandBarActions.right = [
            {
                canCheck: false,
                key: "addUser",
                name: t("Users.Add.Add_User_Button"),
                ariaLabel: t("Users.Add.Add_User_Button"),
                onClick: () => {
                    logger.trackEvent(EventTypes.GroupDetailsPageAddUserAccountButtonClicked);
                    toggleIsAddUserPanelOpen();
                    setFalseIsAddAnotherUser();
                },
                iconProps: { iconName: "AddFriend" },
                role: "button"
            }
        ];
    }
    if (commandBarActions.left.length > 0) {
        selectionMode = SelectionMode.multiple;
    }
    const addAnotherUser = isAddAnotherUser && newUserAdded !== undefined;
    const addUserPanelProps: IAddUserPanelProps = {
        organizationUid: addAnotherUser
            ? newUserAdded.organizationUID
            : props.showAddUserCommandForGroup?.OrganizationUID,
        onClosePanel: () => {
            setFalseIsAddAnotherUser();
            toggleIsAddUserPanelOpen();
        },
        groups: addAnotherUser
            ? newUserAdded.selectedGroups
            : [
                  {
                      groupUID: props.showAddUserCommandForGroup?.UID as number,
                      name: props.showAddUserCommandForGroup?.Name as string,
                      siteName: props.showAddUserCommandForGroup?.SiteName as string,
                      roleType: props.showAddUserCommandForGroup?.RoleType as number
                  }
              ],
        onUserAdded: (newUser: NewUserAdded) => {
            toggleIsAddUserConfirmDialog();
            setNewUserAdded(newUser);
        },
        userType: addAnotherUser ? newUserAdded.userType : undefined,
        primarySpecialty: addAnotherUser ? newUserAdded.primarySpecialty : undefined,
        secondarySpecialty: addAnotherUser ? newUserAdded.secondarySpecialty : undefined
    };

    /**
     * Add item count to command bar if items selected
     */
    if (props.includeSelectionCount && selectedUsersIndex.length) {
        const selectionCountButton = {
            canCheck: false,
            key: "selectedRows",
            name: t("Action.Selected_Rows_Button", {
                number: selectedUsersIndex.length
            }),
            ariaLabel: t("Action.Selected_Rows_Button", {
                number: selectedUsersIndex.length
            }),
            iconProps: { iconName: "Cancel" },
            role: "button",
            onClick: () => {
                selection.setAllSelected(false);
            }
        };
        if (commandBarActions.right && commandBarActions.right?.length > 0) {
            commandBarActions.right.unshift(selectionCountButton);
        } else commandBarActions.right = [selectionCountButton];
    }

    return (
        <>
            {!error && (
                <>
                    <HuxDetailsList
                        id={"usersTable"}
                        isLoading={isInitialLoading}
                        columns={columns}
                        items={items ?? []}
                        actions={commandBarActions}
                        selectionMode={selectionMode}
                        selection={selection}
                        onShouldVirtualize={() => {
                            // true by default, but turn off for tests, otherwise only renders 10 rows
                            return process.env.NODE_ENV !== "test";
                        }}
                        ariaLabelForGrid={props.label}
                        onLinkClick={onUserLinkClick}
                        emptyStateProps={props.emptyStateProps ?? emptyStateSearchProps}
                    ></HuxDetailsList>
                    <HuxSimplePagination
                        isLoading={isFetching}
                        prefixLabel={props.label}
                        startingIndex={skip}
                        itemCount={items?.length ?? 0}
                        isFirstPage={skip === 0}
                        isLastPage={items && items.length < take ? true : false}
                        onNextPageClick={() => {
                            setSkip(skip + take);
                            if (props.updateSkip) props.updateSkip(skip + take);
                        }}
                        onPreviousPageClick={() => {
                            setSkip(skip - take);
                            if (props.updateSkip) props.updateSkip(skip - take);
                        }}
                    ></HuxSimplePagination>
                    <HuxConfirmDialog
                        hidden={isUnenrollDialogHidden}
                        title={t("Groups.Unenroll.Unenroll_Members_Title")}
                        closeDialog={() => {
                            toggleIsUnenrollDialogHidden();
                        }}
                        children={
                            <Stack tokens={{ childrenGap: 16 }}>
                                <Text>{t("Groups.Unenroll.Unenroll_Members_Text")}</Text>
                            </Stack>
                        }
                        maxWidth={500}
                        action={{
                            description: t("Progress.Spinner_Unenrolling"),
                            onAction: unenrollUsersHandler
                        }}
                        confirmButtonText={t("Form.YesUnenroll_Button")}
                    />
                    <HuxConfirmDialog
                        hidden={isRevokeLicensesConfirmDialogHidden}
                        title={t("ProductDetails.Licenses.Revoke_License_Title")}
                        closeDialog={() => {
                            toggleIsRevokeLicensesConfirmDialogHidden();
                        }}
                        children={
                            <Stack tokens={{ childrenGap: 16 }}>
                                <Text>{t("ProductDetails.Licenses.Revoke_License_Text")}</Text>
                            </Stack>
                        }
                        maxWidth={500}
                        action={{
                            description: t("Progress.Spinner_Revoking"),
                            onAction: revokeHandler
                        }}
                        confirmButtonText={t("Form.YesRevoke_Button")}
                    />
                </>
            )}
            {isEnrollmentPanelOpen && props.showEnrollmentCommandsForGroup && (
                <GroupUserEnrollmentPanel
                    group={props.showEnrollmentCommandsForGroup}
                    currentUsers={users ?? []}
                    onDismiss={toggleIsEnrollmentPanelOpen}
                />
            )}
            {isGrantLicensesPanelOpen && props.showLicenseCommands && (
                <GrantLicensesPanel
                    availableLicenses={props.showLicenseCommands.availableLicenses}
                    orgUid={props.showLicenseCommands.orgUid}
                    licenseTypeGuid={props.showLicenseCommands.licenseTypeGuid}
                    partnerUid={props.showLicenseCommands.partnerUid}
                    partnerGuid={props.showLicenseCommands.partnerGuid}
                    onDismiss={toggleIsGrantLicensesPanelOpen}
                />
            )}
            {isAddUserPanelOpen && props.showAddUserCommandForGroup && (
                <AddUserPanel {...addUserPanelProps} />
            )}
            {isAssignLicensesPanelOpen && userToAssignLicense && (
                <UserGrantLicensesPanel
                    user={userToAssignLicense}
                    existingLicenses={[]}
                    isNewUser={true}
                    onDismiss={toggleIsAssignLicensesPanelOpen}
                ></UserGrantLicensesPanel>
            )}
            {isAddUserConfirmDialog && newUserAdded && (
                <AddUserConfirmDialog
                    isOpen={isAddUserConfirmDialog}
                    userAdded={newUserAdded}
                    onDismiss={toggleIsAddUserConfirmDialog}
                    onAddAnotherUser={() => {
                        toggleIsAddUserConfirmDialog();
                        setTrueIsAddAnotherUser();
                        toggleIsAddUserPanelOpen();
                    }}
                    onAssignLicensesToUser={(user: IUser) => {
                        toggleIsAddUserConfirmDialog();
                        toggleIsAssignLicensesPanelOpen();
                        setUserToAssignLicense(user);
                    }}
                />
            )}
        </>
    );
};

function formatDateGrantedOn(date: string): string {
    return convertToLocaleDate(parseNMSDate(date));
}
