/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */
import {
    CacheKey,
    ILicenseGrantUnformatted,
    IUser,
    LicenseMode,
    UserManagementSvc,
    parseNMSDate,
    useApiRequest,
    useHapAuth
} from "@nuance/hap-components";
import { useTranslation } from "react-i18next";
import { Field, FieldTypes, FormPanel } from "../components/FormPanel";
import { IHuxMessageProps, UserNameFormat, formatUserName } from "@nuance/hux-components";
import { MessageBarButton, MessageBarType, Stack, Text } from "@fluentui/react";
import { useQueryClient } from "@tanstack/react-query";
import { EventTypes } from "../EventTypes";
import { getLogger } from "@nuance/hux-diagnostics";
import { HashSvc } from "../services/HashSvc";
import { useNavigate } from "react-router-dom";
import { TAKE } from "../NCCConstants";
import {
    LicenseSelection,
    getAvailableLicenses,
    getEnterpriseLicenses,
    getLicensesUserHasPrivilege,
    sortLicenses
} from "../common/LicenseFunctions";
import { AvailableLicensesTable } from "../components/license/AvailableLicensesTable";
import { OtherLicensesList } from "../components/license/OtherLicenseList";

const logger = getLogger();

/**
 * Interface for Grant License Panel
 */
export interface IUserGrantLicensesPanelProps {
    /**
     * The user to whom license to be granted.
     */
    user: IUser;
    /**
     * The existing licenses for the user
     */
    existingLicenses: ILicenseGrantUnformatted[];
    /**
     * Boolean flag to indicate whether license assigned is for new user.
     */
    isNewUser?: boolean;
    /**
     * Callback on dismiss.
     */
    onDismiss: () => void;
}

/**
 * Panel to grant licenses to a user
 * @param props
 * @returns
 */
export const UserGrantLicensesPanel = (props: IUserGrantLicensesPanelProps): JSX.Element => {
    const { t } = useTranslation();
    const { userContext } = useHapAuth();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const userManagementSvc = new UserManagementSvc(userContext);
    const { user, existingLicenses } = props;

    // get licenseSummaries
    const { data: licenseSummaries, isLoading: licenseSummariesLoading } = useApiRequest(
        userManagementSvc.getLicenseSummaries({ orgUid: user.OrganizationUID, skip: 0, take: TAKE })
    );

    // get privileges
    const { data: privileges, isLoading: privilegesLoading } = useApiRequest(
        userManagementSvc.getLicenseTypePrivilegeMaps()
    );

    const isLoading = licenseSummariesLoading || privilegesLoading;
    const hashService = HashSvc.getInstance();

    let newLicenses =
        existingLicenses.length > 0
            ? licenseSummaries
                  ?.filter(license => !isExpired(license.ExpiresOn)) // filter out expired licenses
                  .filter(license => {
                      // filter out licenses that the user already has
                      return !existingLicenses.some(existingLicense => {
                          return (
                              existingLicense.LicenseTypeGuid === license.LicenseTypeGuid &&
                              existingLicense.PartnerUID === license.PartnerUID
                          );
                      });
                  })
            : licenseSummaries;

    newLicenses = sortLicenses(newLicenses);

    // if not superuser, filter out licenses that the user does not have privilege to grant
    newLicenses = getLicensesUserHasPrivilege(userContext, privileges, newLicenses);

    const enterpriseLicenses = getEnterpriseLicenses(newLicenses);

    const availableLicenses: LicenseSelection[] = getAvailableLicenses(
        newLicenses,
        enterpriseLicenses
    );

    const insufficientLicenses = newLicenses?.filter(license => {
        // only account licenses with insufficient count
        const availableCount = license.TotalLicenses - license.UsedLicenses;
        return availableCount <= 0 && license.EntityMode === LicenseMode.Account;
    });

    const fields: Field<{ availableLicenses: never[] }>[] = [];
    if (props.isNewUser) {
        fields.push({
            id: "userName",
            type: FieldTypes.Custom,
            render: () => (
                <Stack style={{ marginTop: 10, marginBottom: 10 }}>
                    <Text>
                        {t("Users.Licenses.Select_Licenses_For_User_Text", {
                            userName: formatUserName(
                                {
                                    FirstName: user.FirstName as string,
                                    LastName: user.LastName as string
                                },
                                UserNameFormat.LFM
                            )
                        })}
                    </Text>
                </Stack>
            )
        });
    }
    fields.push(
        {
            id: "separator1",
            label: t("Users.Licenses.Available_Licenses_Section"),
            type: FieldTypes.Separator
        },
        {
            id: "availableLicenses",
            type: FieldTypes.Custom,
            required: true,
            render: () => (
                <AvailableLicensesTable isLoading={isLoading} licenses={availableLicenses} />
            )
        }
    );

    if (insufficientLicenses && insufficientLicenses?.length > 0) {
        fields.push(
            {
                id: "separator2",
                label: t("Users.Licenses.Insufficient_Licenses_Section"),
                type: FieldTypes.Separator
            },
            {
                id: "insufficientLicenses",
                type: FieldTypes.Custom,
                required: true,
                render: () => {
                    return OtherLicensesList(insufficientLicenses, t);
                }
            }
        );
    }

    if (enterpriseLicenses && enterpriseLicenses?.length > 0) {
        fields.push(
            {
                id: "separator3",
                label: t("Users.Licenses.Enterprise_Licenses_Section"),
                type: FieldTypes.Separator
            },
            {
                id: "enterpriseLicenses",
                type: FieldTypes.Custom,
                required: true,
                render: () => {
                    return OtherLicensesList(enterpriseLicenses, t);
                }
            }
        );
    }

    const successAction = props.isNewUser ? (
        <MessageBarButton
            ariaLabel={t("Users.View_User_Account_Button")}
            onClick={() => {
                logger.trackEvent(EventTypes.AssignLicenseToNewUserUserDetailsLinkClicked, {
                    newUserUID: user.UID,
                    newUserOrganizationUID: user.OrganizationUID
                });
                const hashedOrgUID = hashService.HashEncode(user.OrganizationUID);
                navigate(`/users/${user.NuanceUserGuid}/orgs/${hashedOrgUID}`);
            }}
        >
            {t("Users.View_User_Account_Button")}
        </MessageBarButton>
    ) : undefined;

    const defaultMsg: IHuxMessageProps = {
        message: props.isNewUser
            ? t("Users.Licenses.Licenses_Granted_To_User_Success", {
                  userName: formatUserName(
                      {
                          FirstName: user.FirstName as string,
                          LastName: user.LastName as string
                      },
                      UserNameFormat.LFM
                  )
              })
            : t("Users.Licenses.Licenses_Granted_Success"),
        timeout: 5,
        messageBarType: MessageBarType.success,
        "aria-live": "assertive",
        actions: successAction,
        isMultiline: !props.isNewUser
    };
    let successMessage = defaultMsg;

    const saveLicenses = (data: { availableLicenses: LicenseSelection[] }) => {
        const selectedLicenses = data.availableLicenses?.filter(license => license.selected);
        const promises: Promise<boolean>[] = [];
        selectedLicenses?.forEach(license => {
            const request = userManagementSvc.grantUserLicense(
                user.UID,
                license.LicenseTypeGuid,
                license.PartnerUID
            );
            promises.push(request.execute());
        });

        // returns number of licenses granted
        const returnPromise = new Promise<number>((resolve, reject) => {
            // when all licenses requests have returned, check for any rejected licenses
            Promise.allSettled(promises).then(results => {
                const rejectedLicenses: LicenseSelection[] = [];
                const rejected = results.filter((result, index) => {
                    if (result.status === "rejected") {
                        rejectedLicenses.push(selectedLicenses[index]);
                        return true;
                    }
                    return false;
                });
                const totalLicenses = selectedLicenses.length - rejected.length;
                // all failed, show error in panel
                if (rejected.length === selectedLicenses?.length) {
                    reject(new Error("unable to add any licenses"));
                } else if (rejected.length > 0) {
                    //partial success, clone panel and show warning
                    createPartialSuccessMsg(rejectedLicenses);
                    resolve(totalLicenses);
                } else {
                    // all succeeded
                    successMessage = defaultMsg;
                    resolve(totalLicenses);
                }
            });
        });
        return returnPromise;
    };

    const createPartialSuccessMsg = (rejectedLicenses: LicenseSelection[]) => {
        // bulleted string list of licenses that were not granted
        const list = rejectedLicenses
            .map(
                license =>
                    `• ${license.LicenseName} (${license.ProductName}/${license.PartnerName})`
            )
            .join("\n");
        const body = `${t("Users.Licenses.Licenses_Partially_Granted_Warning")}\n\n${t(
            "Users.Licenses.License_Grant_Error_Text"
        )}\n\n${list}`;

        const msg: IHuxMessageProps = {
            message: body,
            messageBarType: MessageBarType.warning,
            "aria-live": "assertive",
            isMultiline: true,
            actions: successAction
        };
        successMessage = msg;
    };

    const getMessage = (): IHuxMessageProps => {
        return successMessage;
    };

    return (
        <>
            <FormPanel
                initialValues={{
                    availableLicenses: []
                }}
                validationSchema={undefined}
                header={t("Users.Licenses.Grant_Licenses_Title")}
                fields={fields}
                saveButtonText={t("Licenses.Grant_Button")}
                inProgressText={t("Progress.Spinner_Granting")}
                saveAction={saveLicenses}
                onDismiss={props.onDismiss}
                customWidth={"550px"}
                getMessage={getMessage}
                onSuccess={async data => {
                    logger.trackEvent(EventTypes.LicensesAddedToUser, {
                        userUID: user.UID,
                        organizationUID: user.OrganizationUID,
                        numberLicenses: data
                    });

                    // clear cache to refresh user license list
                    queryClient.removeQueries({ queryKey: [CacheKey.getUserLicenses, user.UID] });
                    // clear cache to refresh counts on Product Details page and license detail page
                    queryClient.removeQueries({
                        queryKey: [CacheKey.getLicenseSummaries, user.OrganizationUID]
                    });
                    // clear cache to refresh user list in license details
                    queryClient.removeQueries({ queryKey: [CacheKey.getUsers] });
                }}
            ></FormPanel>
        </>
    );
};

// check expiration date if expired.
const isExpired = (expString: string): boolean => {
    if (expString) {
        const expirationDate = parseNMSDate(expString);
        if (expirationDate) return expirationDate < new Date();
    }
    return true;
};

export default UserGrantLicensesPanel;
