/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */

import React, { useRef, useState } from "react";
import {
    CacheKey,
    IGetUsersSearchParameters,
    IUser,
    useHapAuth,
    UserManagementSvc
} from "@nuance/hap-components";
import { useTranslation } from "react-i18next";
import { AvailableUsersList } from "../components/AvailableUsersList";
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IButton,
    mergeStyles,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Text
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { getLogger } from "@nuance/hux-diagnostics";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    HuxSearchWithOptions,
    IHuxQueryItem,
    StringTokenizerData,
    HuxSummaryBarChart
} from "@nuance/hux-components";
import { EventTypes } from "../EventTypes";
import { useBoolean } from "@fluentui/react-hooks";
import { namespaces } from "../common/translationNamespaces";
import { panelStyles } from "../CommonStyles";

export interface IGrantLicensesPanelProps {
    /* The number of licenses available to be granted */
    availableLicenses: number;
    /* The guid of the license type being granted */
    licenseTypeGuid: string;
    /* The partner UID of the license */
    partnerUid: number;
    /* The partner guid */
    partnerGuid: string;
    /* the Org UID */
    orgUid: number;
    /* callback to dismiss the panel */
    onDismiss: () => void;
}

export const GrantLicensesPanel = (props: IGrantLicensesPanelProps) => {
    const { t } = useTranslation([namespaces.NCC, namespaces.HuxComponents]);
    const { userContext } = useHapAuth();
    const logger = getLogger();
    const userManagementSvc = new UserManagementSvc(userContext);
    const queryClient = useQueryClient();
    const errorMessageId = useId("errorMessage");
    const confirmMessageId = useId("confirmMessage");

    /** Error message for if enrolling fails */
    const [errorMsg, setErrorMsg] = useState<string | undefined>();
    /** Confirmation message for if enrolling succeeds */
    const [confirmMsg, setConfirmMsg] = useState<string | undefined>();
    /** All the currently selected users */
    const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
    /** is the search loading or not */
    const [loading, setLoading] = useState(false);
    /** If Search button is disabled */
    const [searchDisabled, setSearchDisabled] = useState(true);
    /** Search input val */
    const [searchTokensVal, setSearchTokensVal] = useState<StringTokenizerData>();
    /** Ref for search button */
    const searchButtonRef = useRef<IButton>(null);
    /** State for the search parameters */
    const [userSearchParameters, setUserSearchParameters] = useState<IGetUsersSearchParameters>();
    /** State for if the dialog is open */
    const [isDialogOpen, { toggle: toggleIsDialogOpen }] = useBoolean(true);
    /** State for if the clear selection dialog is open */
    const [isClearDialogOpen, { toggle: toggleIsClearDialogOpen }] = useBoolean(true);
    /** Whether to clear the current selection */
    const [clearSelection, setClearSelection] = useState(false);
    /** State for if there are no results from a search (for screen reader) */
    const [numResults, setNumResults] = useState<number>();
    /* The available licenses */
    const [availableLicenses, setAvailableLicenses] = useState<number>(props.availableLicenses);
    /** Id for search box */
    const searchBoxId = useId("searchBox");
    /** Id for grant button */
    const grantButtonId = useId("grantButton");
    /** Whether to refetch users in the available users list */
    const [refetchUsers, setRefetchUsers] = useState(false);
    /** Timeout Id for confirm message */
    let timeoutId: number;

    /** Mutation for enrolling users */
    const { mutate, isPending: isMutationLoading } = useMutation({
        mutationFn: async () => {
            setErrorMsg(undefined);
            setConfirmMsg(undefined);

            return Promise.allSettled(
                selectedUsers.map(async user => {
                    return userManagementSvc
                        .grantUserLicense(user.UID, props.licenseTypeGuid, props.partnerUid)
                        .execute();
                })
            );
        },
        onSuccess: () => {
            logger.trackEvent(EventTypes.UsersGrantedLicenses, {
                users: selectedUsers.length,
                licenseType: props.licenseTypeGuid,
                partnerUid: props.partnerUid
            });

            queryClient.invalidateQueries({ queryKey: [CacheKey.getUsers] });
            queryClient.invalidateQueries({ queryKey: [CacheKey.getUserLicenses] });
            queryClient.refetchQueries({ queryKey: [CacheKey.getLicenseSummaries, props.orgUid] });

            // Set confirmation message
            setConfirmMsg(t("ProductDetails.Licenses.Granted_Success_Message"));
            clearTimeout(timeoutId);

            // Clear confirmation after 5 seconds
            timeoutId = window.setTimeout(() => {
                setConfirmMsg(undefined);
            }, 5000);

            setRefetchUsers(true);
        },
        onError: (e: Error) => {
            logger.logError(e.message);
            setErrorMsg(t("ProductDetails.Licenses.Granted_Failure_Message"));
            const errorElement = document.getElementById(errorMessageId);
            errorElement?.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest"
            });
        }
    });

    /**
     * Panel Footer to be rendered when the mutation is not loading
     * @returns JSX.Element
     */
    const footerNotLoading = () => (
        <div>
            <PrimaryButton
                id={grantButtonId}
                disabled={selectedUsers.length === 0 || availableLicenses < 0 ? true : false}
                type="submit"
                style={{ marginRight: "16px" }}
                onClick={() => {
                    mutate();
                    refetchUsers && setRefetchUsers(false);
                }}
            >
                {t("Licenses.Grant_Licenses_Button", {
                    selectedUsersLength: selectedUsers.length
                })}
            </PrimaryButton>
            <DefaultButton
                onClick={() => {
                    if (selectedUsers.length > 0) {
                        toggleIsDialogOpen();
                    } else props.onDismiss();
                }}
            >
                {t("Form.Done_Button")}
            </DefaultButton>
        </div>
    );

    /**
     * Panel Footer to be rendered when the mutation is loading
     * @returns JSX.Element
     */
    const footerLoading = () => {
        const label = t("Progress.Spinner_Granting");
        return (
            <Spinner
                size={SpinnerSize.large}
                styles={{ root: { justifyContent: "left" } }}
                ariaLive={"assertive"}
                ariaLabel={label}
                label={label}
                labelPosition="right"
            ></Spinner>
        );
    };

    /** Styling for user search components */
    const horizontalStackStyle = {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        gap: 16,
        flexWrap: "wrap"
    };

    /** Style for search components container */
    const usersSearchContainerStyles = mergeStyles(horizontalStackStyle, {
        marginTop: 24,
        marginBottom: 24
    });

    /** User search key options */
    const queryOptions: IHuxQueryItem[] = [
        {
            key: "login",
            prefix: t("Users.Search.Filter.Login_Key"),
            description: t("Users.Search.Filter.Login_Text")
        },
        {
            key: "emailAddress",
            prefix: t("Users.Search.Filter.Email_Key"),
            description: t("Users.Search.Filter.Email_Text")
        },
        {
            key: "lastName",
            prefix: t("Users.Search.Filter.Last_Key"),
            description: t("Users.Search.Filter.Last_Text")
        },
        {
            key: "firstname",
            prefix: t("Users.Search.Filter.First_Key"),
            description: t("Users.Search.Filter.First_Text")
        },
        {
            key: "npi",
            prefix: t("Users.Search.Filter.NPI_Key"),
            description: t("Users.Search.Filter.NPI_Text")
        },
        {
            key: "siteName",
            prefix: t("Users.Search.Filter.Site_Key"),
            description: t("Users.Search.Filter.Site_Text")
        },
        {
            key: "groupName",
            prefix: t("Users.Search.Filter.Group_Key"),
            description: t("Users.Search.Filter.Group_Text")
        }
    ];

    /** Actions that take place when a user Searches */
    const onSearch = () => {
        /** remove focus from search box (if using enter key) */
        searchButtonRef.current?.focus();
        /** Disabled search button */
        setSearchDisabled(true);
        refetchUsers && setRefetchUsers(false);

        /** Set the search parameters */
        const getUsersSearchParameters = {
            ...searchTokensVal,
            licenseCriteria: {
                hasLicense: false,
                partnerGuid: props.partnerGuid,
                licenseTypeGuid: props.licenseTypeGuid
            },
            organizationUID: props.orgUid,
            skip: 0 /** Initial value, UserListComponent will handle pagination */,
            take: 1000
        };

        /** If the desired data is already cached the spinner won't show */
        if (
            queryClient.getQueriesData({ queryKey: [CacheKey.getUsers, getUsersSearchParameters] })
                .length === 0
        ) {
            setLoading(true);
        }

        /** Set the search parameters */
        setUserSearchParameters(getUsersSearchParameters);
        if (userSearchParameters) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { licenseCriteria, ...restOfParams } = userSearchParameters;
            logger.trackEvent(EventTypes.UserSearchInitiated, {
                ...restOfParams
            });
        }
    };

    /** Dialog props */
    const dialogContentProps = {
        type: DialogType.normal,
        title: t("Dialog.Unsaved_Changes.Title2"),
        subText: t("Dialog.Unsaved_Changes.Text_Grant_License")
    };

    /** Clear selection dialog props */
    const clearSelectionDialogContentProps = {
        type: DialogType.normal,
        title: t("Dialog.Clear_Selection.Title"),
        subText: t("Dialog.Clear_Selection.Text")
    };

    return (
        <Panel
            styles={panelStyles}
            isOpen={true}
            headerText={t("ProductDetails.Licenses.Grant_License_Title")}
            isFooterAtBottom={true}
            type={PanelType.extraLarge}
            onDismiss={selectedUsers.length > 0 ? toggleIsDialogOpen : props.onDismiss}
            onRenderFooterContent={() => {
                return isMutationLoading ? footerLoading() : footerNotLoading();
            }}
            closeButtonAriaLabel={t("Action.Close_Button")}
        >
            {numResults !== undefined &&
                (numResults === 0 ? (
                    <div aria-live="assertive" className="screenReaderOnly">
                        {t(`${namespaces.HuxComponents}:NoItemsAvailable.No_Results_Heading`)}
                    </div>
                ) : (
                    <div aria-live="assertive" className="screenReaderOnly">
                        {t(`${namespaces.HuxComponents}:HUXDetailsList.Items_Found`, {
                            numberOfItems: numResults
                        })}
                    </div>
                ))}
            {errorMsg ? (
                <MessageBar id={errorMessageId} messageBarType={MessageBarType.error}>
                    {errorMsg}
                </MessageBar>
            ) : (
                <></>
            )}
            {confirmMsg && !errorMsg ? (
                <MessageBar
                    id={confirmMessageId}
                    messageBarType={MessageBarType.success}
                    aria-live="assertive"
                    onDismiss={() => undefined}
                    dismissButtonAriaLabel={t(
                        `${namespaces.HuxComponents}:HuxMessageBar.MessageBar_Dismiss_button`
                    )}
                >
                    {confirmMsg}
                </MessageBar>
            ) : (
                <></>
            )}
            {availableLicenses === 0 && (
                <div aria-live="assertive" className="screenReaderOnly">
                    {t("ProductDetails.Licenses.No_Licenses_Left_Text")}
                </div>
            )}
            {availableLicenses < 0 && (
                <div aria-live="assertive" className="screenReaderOnly">
                    {t("ProductDetails.Licenses.No_Licenses_Left_Text") +
                        " " +
                        t("ProductDetails.Licenses.Users_Exceeding_Licenses", {
                            extraUsers: Math.abs(availableLicenses)
                        })}
                </div>
            )}
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ width: 500, marginTop: "24px" }}>
                    {
                        <HuxSummaryBarChart
                            totalItems={props.availableLicenses}
                            usedItems={Math.min(availableLicenses, props.availableLicenses)}
                            title={t("Users.Licenses.Available_Licenses_Section")}
                            noUsedItemsMsg={t("ProductDetails.Licenses.No_Licenses_Left_Text")}
                            negativeUsedItemsMsg={t(
                                "ProductDetails.Licenses.Users_Exceeding_Licenses",
                                {
                                    extraUsers: Math.abs(availableLicenses)
                                }
                            )}
                        />
                    }
                </div>
            </div>
            <div className={usersSearchContainerStyles}>
                <div style={{ flexBasis: 500 }}>
                    <HuxSearchWithOptions
                        searchBoxId={searchBoxId}
                        description={t("Users.Search.Filter.Text")}
                        example={t("Users.Search.Filter.Tip")}
                        queryOptions={queryOptions}
                        defaultQuery={queryOptions[2]}
                        ariaLabelForSearch={t("Groups.Enroll.Search_Users_Label")}
                        onChange={searchString => {
                            if (searchString) {
                                if (searchString.error != undefined) {
                                    setSearchDisabled(true);
                                } else if (searchString.data != null) {
                                    setSearchDisabled(false);
                                    setSearchTokensVal(searchString.data);
                                }
                            } else {
                                setSearchDisabled(true);
                            }
                        }}
                        onSearch={() => {
                            if (!searchDisabled) {
                                onSearch();
                            }
                        }}
                        onFocus={() => {
                            clearSelection && setClearSelection(false);
                            if (selectedUsers.length > 0) {
                                toggleIsClearDialogOpen();
                                document.getElementById(searchBoxId)?.blur();
                                document.getElementById(grantButtonId)?.focus();
                            }
                        }}
                        labelText={t("Groups.Enroll.Search_Users_Label")}
                        labelToolTipText={t("ProductDetails.Licenses.Grant_Users_Search_Tip")}
                        suppressCalloutFocus={selectedUsers.length > 0}
                        suppressOnClear={selectedUsers.length > 0}
                    />
                </div>
                <div>
                    {!loading && (
                        <PrimaryButton
                            componentRef={searchButtonRef}
                            styles={{ root: { flexBasis: 100 } }}
                            disabled={searchDisabled}
                            onClick={() => {
                                onSearch();
                            }}
                            ariaLabel={t("Users.Search.Search_Button")}
                        >
                            {t("Users.Search.Search_Button")}
                        </PrimaryButton>
                    )}
                    {loading && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >
                            <Spinner
                                size={SpinnerSize.large}
                                style={{ height: "80%", justifySelf: "left", marginRight: 8 }}
                                ariaLive={"assertive"}
                                ariaLabel={t("Progress.Spinner_Searching")}
                            ></Spinner>
                            <Text>{t("Progress.Spinner_Searching")}</Text>
                        </div>
                    )}
                </div>
            </div>
            {
                <AvailableUsersList
                    currentUIDs={[]}
                    selectedUIDs={selectedUsers.map(user => user.UID)}
                    searchCriteria={userSearchParameters}
                    onSearchCompleted={resultsCount => {
                        setLoading(false);
                        setNumResults(resultsCount);
                    }}
                    onUserSelected={newSelectedUsers => {
                        clearSelection && setClearSelection(false);
                        setSelectedUsers(newSelectedUsers);
                        setAvailableLicenses(props.availableLicenses - newSelectedUsers.length);
                    }}
                    clearSelection={clearSelection}
                    refetchUsers={refetchUsers}
                />
            }
            <Dialog
                hidden={isDialogOpen}
                onDismiss={toggleIsDialogOpen}
                dialogContentProps={dialogContentProps}
                modalProps={{
                    isBlocking: true
                }}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            toggleIsDialogOpen();
                            props.onDismiss();
                        }}
                        text={t("Dialog.Unsaved_Changes.Done_Button")}
                    />
                    <DefaultButton onClick={toggleIsDialogOpen} text={t("Form.Cancel_Button")} />
                </DialogFooter>
            </Dialog>
            {
                <Dialog
                    hidden={isClearDialogOpen}
                    onDismiss={toggleIsClearDialogOpen}
                    dialogContentProps={clearSelectionDialogContentProps}
                    modalProps={{
                        isBlocking: true
                    }}
                    maxWidth={500}
                >
                    <DialogFooter>
                        <PrimaryButton
                            onClick={() => {
                                toggleIsClearDialogOpen();
                            }}
                            text={t("Form.Cancel_Button")}
                        />
                        <DefaultButton
                            onClick={() => {
                                logger.trackEvent(EventTypes.ClearedSelectedUsers, {
                                    usersSelected: selectedUsers.length,
                                    licenseType: props.licenseTypeGuid,
                                    partnerUid: props.partnerUid
                                });
                                setSelectedUsers([]);
                                setClearSelection(true);
                                toggleIsClearDialogOpen();
                            }}
                            text={t("Dialog.Clear_Selection.Clear_Selection_Button")}
                        />
                    </DialogFooter>
                </Dialog>
            }
        </Panel>
    );
};
