/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */
import React, { useState } from "react";

import {
    CacheKey,
    ExceptionErrorCode,
    HapApiError,
    IOrganization,
    IPasswordRules,
    IUserInsert,
    UserManagementSvc,
    useHapAuth
} from "@nuance/hap-components";
import { Field, FieldTypes, FormPanel } from "../../components/FormPanel";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { getUserTypeOptions } from "../../users/UserFunctions";
import { IUserPassword, UserPassword, getPasswordValidationSchema } from "../../users/UserPassword";
import { SelectedGroup } from "../../components/SelectGroupsPanel";
import { useQueryClient } from "@tanstack/react-query";
import { EventTypes } from "../../EventTypes";
import { getLogger } from "@nuance/hux-diagnostics";
import {
    IOrganizationDataItem,
    OrganizationFormEntry,
    getOrganizationValidationSchema
} from "../../components/OrganizationFormEntry";
import {
    GroupsFormEntry,
    IGroupsListFormData,
    getGroupsValidationSchema
} from "../../components/GroupsFormEntry";
import { getSpecialtiesOptions } from "../../users/Specialty";
import { emailFormat } from "../../common/emailFormat";
import { NewUserAdded } from "./AddUserConfirmDialog";

const logger = getLogger();

/**
 * Form data for new user
 */
export interface IUserDataItem extends IOrganizationDataItem, IGroupsListFormData, IUserPassword {
    /**
     * The user type
     */
    UserType: number;
    /**
     * The first name
     */
    FirstName: string;
    /**
     * The middle name
     */
    MiddleName: string;
    /**
     * The last name
     */
    LastName: string;
    /**
     * The email address.
     */
    Email: string;
    /**
     * The primary specialty
     */
    PrimarySpecialty: string;
    /**
     * The secondary specialty
     */
    SecondarySpecialty: string;
    /**
     * The password must change on next login.
     */
    PasswordMustChangeOnNextLogin: boolean;
}

/**
 * Props for add user panel
 */
export interface IAddUserPanelProps {
    /**
     * The groups into which new user is enrolled.
     */
    groups: SelectedGroup[];
    /**
     * The Uid of organization to be selected by default.
     */
    organizationUid?: number;
    /**
     * The optional user type to be selected.
     */
    userType?: number;
    /**
     * The optional primary specialty to be selected.
     */
    primarySpecialty?: string;
    /**
     * The optional secondary specialty to be selected.
     */
    secondarySpecialty?: string;
    /**
     * The callback on successful save of user.
     */
    onUserAdded: (newUser: NewUserAdded) => void;
    /**
     * The callback on closing of panel.
     */
    onClosePanel: () => void;
}

/**
 * @description AddUserPanel component is form panel component to add new user.
 * @param props {IAddUserPanelProps} - The props containing properties for AddUserPanel component.
 * @returns The AddUserPanel component.
 */
export const AddUserPanel = (props: IAddUserPanelProps): JSX.Element => {
    const { t } = useTranslation();
    const { userContext } = useHapAuth();
    const queryClient = useQueryClient();
    const [organization, setOrganization] = useState<IOrganization>();
    const [passwordRules, setPasswordRules] = useState<IPasswordRules>();
    const [errorMessageWhileLoading, setErrorMessageWhileLoading] = useState<string>();
    const userManagementSvc = new UserManagementSvc(userContext);
    /**
     * Validation Schema for formik
     */
    const validationSchema = Yup.object()
        .shape({
            FirstName: Yup.string()
                .required(t("Error.Validation.Field_Required"))
                .max(
                    50,
                    t("Error.Validation.Exceed_Max_Length_Characters", {
                        maxCharacters: 50
                    })
                )
                .matches(
                    /^[^\s]+(\s+[^\s]+)*$/,
                    t("Error.Validation.Has_Leading_Or_Trailing_Spaces", {
                        inputName: t("Users.BasicInfo.First_Name.Label")
                    })
                )
                .matches(/^((?!\u200b).)*$/, t("Users.UserAuth.Login_No_Zero_Width_Space")),
            MiddleName: Yup.string()
                .max(
                    50,
                    t("Error.Validation.Exceed_Max_Length_Characters", {
                        maxCharacters: 50
                    })
                )
                .matches(
                    /^[^\s]+(\s+[^\s]+)*$/,
                    t("Error.Validation.Has_Leading_Or_Trailing_Spaces", {
                        inputName: t("Users.BasicInfo.Middle_Name.Label")
                    })
                )
                .matches(/^((?!\u200b).)*$/, t("Users.UserAuth.Login_No_Zero_Width_Space")),
            LastName: Yup.string()
                .required(t("Error.Validation.Field_Required"))
                .max(
                    50,
                    t("Error.Validation.Exceed_Max_Length_Characters", {
                        maxCharacters: 50
                    })
                )
                .matches(
                    /^[^\s]+(\s+[^\s]+)*$/,
                    t("Error.Validation.Has_Leading_Or_Trailing_Spaces", {
                        inputName: t("Users.BasicInfo.Last_Name.Label")
                    })
                )
                .matches(/^((?!\u200b).)*$/, t("Users.UserAuth.Login_No_Zero_Width_Space")),
            Email: Yup.string().when([], {
                is: () => {
                    return organization?.IsEmailRequired;
                },
                then: schema =>
                    schema
                        .required(t("Error.Validation.Field_Required"))
                        .matches(emailFormat, t("Error.Validation.Invalid_Email"))
                        .email(t("Error.Validation.Invalid_Email"))
                        .max(
                            50,
                            t("Error.Validation.Exceed_Max_Length_Characters", {
                                maxCharacters: 50
                            })
                        ),
                otherwise: schema =>
                    schema
                        .notRequired()
                        .matches(emailFormat, t("Error.Validation.Invalid_Email"))
                        .email(t("Error.Validation.Invalid_Email"))
                        .max(
                            50,
                            t("Error.Validation.Exceed_Max_Length_Characters", {
                                maxCharacters: 50
                            })
                        )
            }),
            UserName: Yup.string()
                .required(t("Error.Validation.Field_Required"))
                .matches(/^[^\\:]*$/, t("Users.UserAuth.Login_Colon_Or_Backslash"))
                .max(
                    80,
                    t("Error.Validation.Exceed_Max_Length_Characters", {
                        maxCharacters: 80
                    })
                )
                .matches(
                    /^[^\s]+(\s+[^\s]+)*$/,
                    t("Error.Validation.Has_Leading_Or_Trailing_Spaces", {
                        inputName: t("Users.UserAuth.Login_ID")
                    })
                )
                .matches(/^((?!\u200b).)*$/, t("Users.UserAuth.Login_No_Zero_Width_Space"))
        })
        .concat(getOrganizationValidationSchema())
        .concat(getPasswordValidationSchema(t, passwordRules))
        .concat(getGroupsValidationSchema());

    /**
     * Fields for add user panel
     */
    const fields: Field<IUserDataItem>[] = [
        {
            id: "Organization",
            type: FieldTypes.Custom,
            required: true,
            render: () => {
                return (
                    <OrganizationFormEntry
                        organizationUid={props.organizationUid}
                        onOrganizationChange={(organization: IOrganization) => {
                            setOrganization(organization);
                        }}
                        onLoadingError={errorMessage => {
                            setErrorMessageWhileLoading(errorMessage);
                        }}
                        changOrgLinkClickedEventType={EventTypes.AddUserPanelChangeOrgClicked}
                    ></OrganizationFormEntry>
                );
            }
        },
        {
            id: "UserType",
            label: t("Users.BasicInfo.UserType.Label"),
            type: FieldTypes.DropDown,
            options: getUserTypeOptions(t)
        },
        {
            id: "FirstName",
            label: t("Users.BasicInfo.First_Name.Label"),
            type: FieldTypes.Text,
            required: true
        },
        {
            id: "MiddleName",
            label: t("Users.BasicInfo.Middle_Name.Label"),
            type: FieldTypes.Text
        },
        {
            id: "LastName",
            label: t("Users.BasicInfo.Last_Name.Label"),
            type: FieldTypes.Text,
            required: true
        },
        {
            id: "Email",
            label: t("Users.BasicInfo.Email.Label"),
            type: FieldTypes.Text,
            required: organization?.IsEmailRequired
        },
        {
            id: "PrimarySpecialty",
            label: t("Users.BasicInfo.Primary_Specialty.Label"),
            type: FieldTypes.DropDown,
            options: getSpecialtiesOptions(t)
        },
        {
            id: "SecondarySpecialty",
            label: t("Users.BasicInfo.Secondary_Specialty.Label"),
            type: FieldTypes.DropDown,
            options: getSpecialtiesOptions(t)
        },
        {
            id: "Authentication",
            label: t("Users.Add.Authentication_Section"),
            type: FieldTypes.Separator
        },
        {
            id: "UserName",
            label: t("Users.UserAuth.Login_ID"),
            type: FieldTypes.Text,
            required: true
        },
        {
            id: "PasswordComponent",
            type: FieldTypes.Custom,
            render: () => {
                return (
                    <UserPassword
                        organizationUid={organization ? organization.UID : undefined}
                        showPasswordComplexityAsToolTip={true}
                        onLoadingError={errorMessage => {
                            setErrorMessageWhileLoading(errorMessage);
                        }}
                        onPasswordRulesChange={(rules: IPasswordRules) => {
                            setPasswordRules(rules);
                        }}
                        disableRequiredAsteriskKey={true}
                    ></UserPassword>
                );
            }
        },
        {
            id: "PasswordMustChangeOnNextLogin",
            label: t("Password.RequireNMS_Password_Change_Label"),
            type: FieldTypes.Checkbox
        },
        {
            id: "GroupsSeparator",
            label: t("Users.Add.Groups_Section"),
            type: FieldTypes.Separator
        },
        {
            id: "GroupsComponent",
            type: FieldTypes.Custom,
            render: () => {
                return (
                    <GroupsFormEntry
                        organizationUid={organization ? organization.UID : undefined}
                        groups={props.groups}
                        addGroupsButtonClickedEventType={EventTypes.AddUserPanelAddGroupsClicked}
                        removeGroupsButtonClickedEventType={
                            EventTypes.AddUserPanelRemoveGroupsClicked
                        }
                    ></GroupsFormEntry>
                );
            }
        }
    ];
    const saveAction = async (user: IUserDataItem) => {
        const newUser: IUserInsert = {
            OrganizationUID: user.OrganizationUid as number,
            UserType: user.UserType,
            FirstName: user.FirstName,
            MiddleName: user.MiddleName,
            LastName: user.LastName,
            EMailAddress: user.Email,
            PrimarySpecialty: user.PrimarySpecialty,
            PrimarySubSpecialty: "",
            SecondarySubSpecialty: "",
            SecondarySpecialty: user.SecondarySpecialty,
            Specialty: "",
            Login: user.UserName,
            Password: user.Password,
            PasswordMustChangeOnNextLogin: user.PasswordMustChangeOnNextLogin,
            GroupUIDs: user.Groups.map(g => g.groupUID),
            Address1: "",
            Address2: "",
            Address3: "",
            City: "",
            CountryCode: "",
            Department: "",
            Disabled: false,
            InformMeViaEmail: false,
            InformMeViaSMS: false,
            MobilePhoneNumber: "",
            Location: "",
            NPICode: "",
            Prefix: "",
            Suffix: "",
            State: "",
            ZipCode: ""
        };
        const addGrantsRequest = userManagementSvc.insertUser(newUser);
        return addGrantsRequest.execute2();
    };

    /**
     * @description An error handler for the FormPanel. If we get an NMS
     * error code of "duplicate object", render a custom error message.
     * @param e - The error object returned from the ApiRequest module.
     * @param defaultHandler - A default error handler. Call with undefined
     * for default behavior, or pass a custom error message to be displayed
     * on the top of the panel.
     */
    const onError = (e: Error, defaultHandler: (message?: string) => void) => {
        if (
            e instanceof HapApiError &&
            e.nmsErrorDetails.ErrorCode === ExceptionErrorCode.ObjectAlreadyExists &&
            e.nmsErrorDetails.Description === "Login"
        ) {
            defaultHandler(t("Error.NMSResponse.User.LoginExists"));
        } else if (
            e instanceof HapApiError &&
            e.nmsErrorDetails.ErrorCode === ExceptionErrorCode.ObjectAlreadyExists &&
            e.nmsErrorDetails.Description === "EMail"
        ) {
            defaultHandler(t("Error.NMSResponse.User.EmailExists"));
        } else {
            defaultHandler(undefined);
        }
    };
    return (
        <>
            <FormPanel
                saveAction={saveAction}
                onSuccess={async (data, variables) => {
                    if (data && variables) {
                        await queryClient.refetchQueries({
                            queryKey: [CacheKey.getUsers]
                        });
                        logger.trackEvent(EventTypes.UserSavedSuccessfully, {
                            newUserUid: data,
                            userName: variables.UserName
                        });
                        props.onUserAdded({
                            firstName: variables.FirstName,
                            lastName: variables.LastName,
                            userUID: data,
                            organizationUID: variables.OrganizationUid as number,
                            selectedGroups: variables.Groups,
                            userType: variables.UserType,
                            primarySpecialty: variables.PrimarySpecialty,
                            secondarySpecialty: variables.SecondarySpecialty
                        });
                    }
                }}
                onDismiss={props.onClosePanel}
                initialValues={{
                    OrganizationUid: props.organizationUid,
                    FirstName: "",
                    MiddleName: "",
                    LastName: "",
                    UserType: props.userType ? props.userType : 1,
                    Email: "",
                    PrimarySpecialty: props.primarySpecialty ? props.primarySpecialty : "",
                    SecondarySpecialty: props.secondarySpecialty ? props.secondarySpecialty : "",
                    UserName: "",
                    Password: "",
                    ConfirmPassword: "",
                    PasswordMustChangeOnNextLogin: true,
                    Groups: props.groups
                }}
                validationSchema={validationSchema}
                header={t("Users.Add.Add_User_Title")}
                fields={fields}
                errorOnLoadingMessage={errorMessageWhileLoading}
                saveButtonText={t("Users.Add.Add_User_Button")}
                inProgressText={t("Progress.Spinner_Adding_User")}
                onError={onError}
                showSuccessMessage={false}
            ></FormPanel>
        </>
    );
};
