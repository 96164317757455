/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isValidGuid } from "@nuance/hux-diagnostics";
import {
    IOrganization,
    useApiRequest,
    useHapAuth,
    UserManagementSvc
} from "@nuance/hap-components";
import { IBreadcrumbItem, Spinner, SpinnerSize } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import {
    HuxPageHeader,
    IHuxPivotWithRoutingProps,
    HuxPivotWithRouting
} from "@nuance/hux-components";
import { getLogger, GuardAgainst } from "@nuance/hux-diagnostics";
import { HashSvc } from "../services/HashSvc";
import { EventTypes } from "../EventTypes";
import { UseQueryResult } from "@tanstack/react-query";
import { FailedToLoadPage } from "../components/FailedToLoadPage";
import { OrgGroupOverviewTab } from "./OrgGroupOverviewTab";

const logger = getLogger();

/**
 *
 * @returns JSX.Element
 */
export const OrgGroupPage = () => {
    const [refetch, setRefetch] = useState(true);
    try {
        const { orgGuidOrOrgUidHash, orgGroupHash } = useParams();

        if (!orgGuidOrOrgUidHash) throw Error("org Guid or HashedUID not found");
        GuardAgainst.Falsy(orgGroupHash, "Invalid OrgGroup Hash Id");

        const hashService = HashSvc.getInstance();
        const orgGroupUID = hashService.HashDecode(orgGroupHash as string);

        const { userContext } = useHapAuth();
        const { t } = useTranslation();
        const navigate = useNavigate();

        const userManagementSvc = new UserManagementSvc(userContext);

        const orgGroupReq = useApiRequest(userManagementSvc.getOrgGroupByUid(orgGroupUID), {
            refetchOnWindowFocus: refetch
        });
        let orgReq: UseQueryResult<IOrganization>;

        if (isValidGuid(orgGuidOrOrgUidHash)) {
            orgReq = useApiRequest(
                userManagementSvc.selectOrganizationByGuid(orgGuidOrOrgUidHash),
                {
                    refetchOnWindowFocus: refetch
                }
            );
        } else {
            const hashService = HashSvc.getInstance();
            const orgUID = hashService.HashDecode(orgGuidOrOrgUidHash as string);

            orgReq = useApiRequest(userManagementSvc.getOrganizationByUID(orgUID), {
                refetchOnWindowFocus: refetch
            });
        }

        const isError = orgGroupReq.isError || orgReq.isError;
        const isLoading = orgGroupReq.isLoading || orgReq.isLoading;

        /** if isError */
        if (isError) {
            throw Error("Error: Failed to load selected organization group");
        }

        /** Return spinner if isLoading */
        if (isLoading) {
            return (
                <Spinner
                    size={SpinnerSize.large}
                    style={{ height: "80%" }}
                    ariaLabel={t("Progress.Spinner_Loading")}
                    label={t("Progress.Spinner_Loading")}
                />
            );
        }

        /** If no data in both requisitions, return failed to load */
        if (!orgGroupReq.data || !orgReq.data) {
            throw Error("Error: Failed to load selected organization group");
        }

        /** Checks to see if the orgGroup belongs to the provided org */
        if (
            orgReq.data &&
            orgGroupReq.data &&
            orgReq.data.NuanceHCAccountGuid !== orgGroupReq.data.OrgGuid
        ) {
            throw Error("orgGroup does not belong to the provided org");
        }

        /** Action for when breadcrumb link is clicked */
        const onBreadCrumbItemClicked = (
            ev?: React.MouseEvent<HTMLElement>,
            item?: IBreadcrumbItem
        ) => {
            ev?.preventDefault();
            if (item?.href) {
                logger.trackEvent(EventTypes.OrgGroupPageBreadcrumbLinkClicked, {
                    orgGroupName: orgGroupReq.data.OrgGroupName,
                    orgName: orgReq.data?.Name
                });
                navigate(item?.href);
            }
        };

        const pivotProps: IHuxPivotWithRoutingProps = {
            pivotItemProps: [
                {
                    to: "overview",
                    headerText: t("Groups.Overview_Tab"),
                    element: <OrgGroupOverviewTab orgGroup={orgGroupReq.data} org={orgReq.data} />,
                    pageTitle: t("Groups.Advisor_Group_Page_Title", {
                        GroupName: orgGroupReq.data?.OrgGroupName
                    })
                }
            ]
        };

        return (
            <main tabIndex={-1}>
                {!isLoading && !isError && (
                    <>
                        <HuxPageHeader
                            breadCrumb={{
                                items: [
                                    {
                                        text: t("Groups.PageHeader_Organization"),
                                        key: "Organization",
                                        href: `/orgs/${orgReq.data.NuanceHCAccountGuid}`,
                                        onClick: onBreadCrumbItemClicked
                                    }
                                ]
                            }}
                            titleInfo={{
                                parentTitle: orgReq.data.Name,
                                title: orgGroupReq.data.OrgGroupName,
                                subTitle: t("Groups.Advisor_Group_Subtitle")
                            }}
                        ></HuxPageHeader>
                        <HuxPivotWithRouting {...pivotProps} />
                    </>
                )}
            </main>
        );
    } catch (error) {
        if (refetch) setRefetch(false);
        logger.logError(error instanceof Error ? error.message : "unknown error");
        return <FailedToLoadPage />;
    }
};
