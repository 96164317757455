/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

export const namespaces = {
    NCC: "NCC",
    HapComponents: "HapComponents",
    HuxComponents: "HuxComponents"
};
