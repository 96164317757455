/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

import { IGroup, IOrgGroup } from "@nuance/hap-components";

/**
 *
 * @param object
 * @returns true if object is an Organization group (aka advisor group)
 */
export const instanceOfIOrgGroup = (object: IGroup | IOrgGroup): object is IOrgGroup => {
    return "OrgGroupName" in object;
};

export const getGroupId = (object: IGroup | IOrgGroup): number => {
    if (instanceOfIOrgGroup(object)) {
        const orgGroup = object as IOrgGroup;
        return orgGroup.OrgGroupId;
    }
    const group = object as IGroup;
    return group.UID;
};
