/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import {
    Dialog,
    DialogFooter,
    DialogType,
    IDialogContentProps,
    Text,
    PrimaryButton,
    FontWeights,
    FontSizes
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";

import { useTimer } from "../hooks/useTimer";
import { useTranslation } from "react-i18next";

/**
 * Inactivity Timeout Dialog properties
 */
export interface InactivityTimeoutDialogProps {
    /**
     * Is dialog open
     */
    isOpen: boolean;
    /**
     * Inactivity Timeout Warning Duration
     */
    inactivityTimeoutWarningDuration: number;

    /**
     * Callback on keep working
     */
    onKeepWorking: () => void;
}
/**
 * Inactivity Timeout Dialog component
 * @param props Inactivity Timeout Dialog properties
 * @returns InactivityTimeoutDialog dialog
 */
const InactivityTimeoutDialog = (props: InactivityTimeoutDialogProps): JSX.Element => {
    const messageStyle = {
        fontSize: FontSizes.size14,
        fontWeight: FontWeights.regular,
        fontFamily: "Open Sans",
        lineHeight: "20px",
        color: NeutralColors.gray130
    };
    /**
     * Handles properly on keep working
     */
    const onKeepWorking = () => {
        props.onKeepWorking();
    };

    const { t } = useTranslation();
    const [minutes, seconds] = useTimer(props.isOpen, props.inactivityTimeoutWarningDuration);

    const dialogContentProps: IDialogContentProps = {
        type: DialogType.normal,
        title: t("LoggedOut.Inactivity_Prompt_Header"),
        showCloseButton: true
    };

    return (
        <Dialog
            hidden={!props.isOpen}
            dialogContentProps={dialogContentProps}
            minWidth={600}
            onDismiss={onKeepWorking}
        >
            <Text style={messageStyle}>{`${t("LoggedOut.Inactivity_Prompt_Message")} ${(
                "0" + minutes
            ).slice(-2)}:${("0" + seconds).slice(-2)}`}</Text>
            <div>
                <DialogFooter>
                    <PrimaryButton onClick={onKeepWorking}>
                        {t("LoggedOut.Inactivity_Prompt_Keep_Working_Button")}
                    </PrimaryButton>
                </DialogFooter>
            </div>
        </Dialog>
    );
};

export default InactivityTimeoutDialog;
