/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

import { Field, FieldTypes, FormPanel } from "../components/FormPanel";
import {
    OrganizationFormEntry,
    getOrganizationValidationSchema
} from "../components/OrganizationFormEntry";
import {
    AadNmsConnectorSvc,
    CacheKey,
    IOrganization,
    ServicePrincipalResponse,
    useApiRequest,
    useHapAuth
} from "@nuance/hap-components";
import { EventTypes } from "../EventTypes";
import { useState } from "react";
import { ChangeGroup } from "../components/ChangeGroupFormEntry";
import { ITreeNode } from "@nuance/hux-components";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import { useAppConfig } from "../services/AppConfigSvc";
import { NewUserAdded } from "../groups/users/AddUserConfirmDialog";
import { getLogger } from "@nuance/hux-diagnostics";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { isValidGuid } from "@nuance/hux-diagnostics";

const logger = getLogger();

/**
 * Form data for new service principal / managed identity
 */
export interface IServicePrincipalDataItem {
    /**  The organization for the new service principal. */
    organization?: IOrganization;
    /** name of the service principal, will be used as user's last name */
    displayName: string;
    /** Tenant id associated with the service principal */
    tenantId: string;
    /** The type of service principal, will be uses as user's first name */
    type: ServicePrincipalType;
    /** The service principal id */
    servicePrincipalId: string;
    /** The group to which the service principal will be added */
    group?: ITreeNode;
}

/**
 * Props for the AddServicePrincipalPanel component
 */
export interface IAddServicePrincipalPanelProps {
    /** the default organization UID to for the org selector */
    orgUID?: number;
    /** the selected group */
    group?: ITreeNode;
    /** The callback on closing of panel. */
    onDismiss: () => void;
    /** The callback on successful save of user. */
    onUserAdded: (newUser: NewUserAdded) => void;
}

export enum ServicePrincipalType {
    ServicePrincipal = "Application",
    ManagedIdentity = "ManagedIdentity"
}

/**
 * Form panel component to add a service principal / managed identity
 * @param props {IAddServicePrincipalPanelProps} - The props containing properties for AddServicePrincipalPanel component.
 * @returns The AddServicePrincipalPanel component.
 */
export const AddServicePrincipalPanel = (props: IAddServicePrincipalPanelProps): JSX.Element => {
    const { t } = useTranslation();
    const { orgUID, group, onDismiss } = props;
    const [organization, setOrganization] = useState<IOrganization>();
    const [errorMessageWhileLoading, setErrorMessageWhileLoading] = useState<string>();
    const queryClient = useQueryClient();
    const { userContext } = useHapAuth();
    const aadNmsConnectorSvc = new AadNmsConnectorSvc(userContext);
    const { data: appConfig } = useAppConfig();
    const tenantsReq = aadNmsConnectorSvc.getAllAadTenantIDs();
    const { isInitialLoading, isFetching, error, data: tenantIDs } = useApiRequest(tenantsReq);

    if (error) {
        logger.logError("Error while getting tenant IDs", { message: error.message });
        setErrorMessageWhileLoading(t("Error.Unable_to_Load"));
    }

    const validationSchema = Yup.object()
        .shape({
            displayName: Yup.string()
                .required(t("Error.Validation.Field_Required"))
                .max(
                    50,
                    t("Error.Validation.Exceed_Max_Length_Characters", {
                        maxCharacters: 50
                    })
                )
                .matches(
                    /^[^\s]+(\s+[^\s]+)*$/,
                    t("Error.Validation.Has_Leading_Or_Trailing_Spaces", {
                        inputName: t("Users.BasicInfo.First_Name.Label")
                    })
                )
                .matches(/^((?!\u200b).)*$/, t("Users.UserAuth.Login_No_Zero_Width_Space")),
            tenantId: Yup.string()
                .required(t("Error.Validation.Field_Required"))
                .test("valid GUID", t("Error.Validation.Invalid_Guid"), value =>
                    isValidGuid(value || "")
                )
                .test(
                    "existing tenantID",
                    t("Users.Add.Service_Principal.Tenant_ID_Error"),
                    value => tenantIDs?.some(tenantID => value === tenantID)
                ),
            servicePrincipalId: Yup.string()
                .required(t("Error.Validation.Field_Required"))
                .test("valid GUID", t("Error.Validation.Invalid_Guid"), value =>
                    isValidGuid(value || "")
                ),
            group: Yup.object().required(t("Error.Validation.Field_Required"))
        })
        .concat(getOrganizationValidationSchema());

    const fields: Field<IServicePrincipalDataItem>[] = [
        {
            id: "Organization",
            type: FieldTypes.Custom,
            required: true,
            render: () => {
                return (
                    <OrganizationFormEntry
                        organizationUid={orgUID}
                        onOrganizationChange={(organization: IOrganization) => {
                            setOrganization(organization);
                        }}
                        onLoadingError={errorMessage => {
                            setErrorMessageWhileLoading(errorMessage);
                        }}
                        changOrgLinkClickedEventType={EventTypes.AddUserPanelChangeOrgClicked}
                    ></OrganizationFormEntry>
                );
            }
        },
        {
            id: "displayName", // will be used for user last name
            label: t("Users.Add.Service_Principal.Display_Name_Label"),
            type: FieldTypes.Text,
            required: true,
            tooltip: t("Users.Add.Service_Principal.Display_Name_Tooltip")
        },
        {
            id: "type",
            label: t("Users.Add.Service_Principal.Type_Label"),
            type: FieldTypes.Radio,
            options: [
                {
                    key: ServicePrincipalType.ServicePrincipal,
                    text: t("Users.Add.Service_Principal.Type_Value_Service_Principal")
                },
                {
                    key: ServicePrincipalType.ManagedIdentity,
                    text: t("Users.Add.Service_Principal.Type_Value_Managed_Identity")
                }
            ],
            tooltip: t("Users.Add.Service_Principal.Type_Label_Tooltip")
        },
        {
            id: "servicePrincipalId",
            label: t("Users.Add.Service_Principal.Service_Principal_ID_Label"),
            type: FieldTypes.Text,
            required: true,
            tooltip: t("Users.Add.Service_Principal.Service_Principal_ID_Tooltip")
        },
        {
            id: "tenantId",
            label: t("Users.Add.Service_Principal.Tenant_ID_Label"),
            type: FieldTypes.Text,
            required: true,
            tooltip: t("Users.Add.Service_Principal.Tenant_ID_Tooltip")
        },
        {
            id: "groupUid",
            type: FieldTypes.Custom,
            required: true,
            render: () => {
                return (
                    <>
                        {organization && (
                            <ChangeGroup organization={organization} group={group}></ChangeGroup>
                        )}
                    </>
                );
            }
        }
    ];

    const saveAction = async (data: IServicePrincipalDataItem) => {
        const correlationId = uuid();
        const svcPrincipal = {
            displayName: data.displayName,
            tenantId: data.tenantId,
            servicePrincipalType: data.type,
            servicePrincipalId: data.servicePrincipalId,
            organizationGuid:
                data.organization?.NuanceHCAccountGuid ?? organization?.NuanceHCAccountGuid ?? "",
            groupGuid: data.group?.guid ?? group?.guid ?? "",
            productGuid: appConfig?.aadConfig?.productGuid ?? "",
            correlationId
        };
        const request = aadNmsConnectorSvc.createUserForServicePrincipal(svcPrincipal);
        logger.logInformation("Creating service principal", { correlationId, type: data.type });
        return request.execute();
    };

    return (
        <FormPanel
            saveAction={saveAction}
            onSuccess={async (data?: ServicePrincipalResponse, variables?) => {
                if (data && organization) {
                    await queryClient.refetchQueries({
                        queryKey: [CacheKey.getUsers]
                    });
                    logger.trackEvent(EventTypes.ServicePrincipalSavedSuccessfully, {
                        guid: data.nuanceUserGuid,
                        type: variables?.type
                    });
                    props.onUserAdded({
                        firstName: data.firstName,
                        lastName: data.lastName,
                        userUID: data.userUID,
                        organizationUID: organization?.UID,
                        selectedGroups: []
                    });
                }
            }}
            onDismiss={onDismiss}
            initialValues={{
                organization,
                displayName: "",
                tenantId: "",
                type: ServicePrincipalType.ServicePrincipal,
                servicePrincipalId: "",
                group
            }}
            validationSchema={validationSchema}
            header={t("Users.Add.Add_Service_Principal_Title")}
            fields={fields}
            showSuccessMessage={false}
            errorOnLoadingMessage={errorMessageWhileLoading}
            isLoading={isInitialLoading || isFetching}
            saveButtonText={t("Users.Add.Add_Service_Principal_Button")}
        ></FormPanel>
    );
};
