/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import React, { useMemo } from "react";

import { initializeLogging } from "@nuance/hux-diagnostics";
import { LIB_VERSION } from "./version";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { HapAuthConfig, HapAuthProvider, BaseSvc, HapAuthType } from "@nuance/hap-components";
import Main from "./Main";
import { useAppConfig } from "./services/AppConfigSvc";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Spinner, ThemeProvider } from "@fluentui/react";
import { getAndUpdateAuthConnection, AuthConnectionPage } from "./AuthConnectionPage";
import Logout from "./logout/Logout";
import { ISvcOptions } from "@nuance/hap-components/lib/domain/Services/Types/ISvcOptions";
import { useTranslation } from "react-i18next";
import { NCCTitleBar } from "./NCCTitleBar/NCCTitleBar";
import { GeneralErrorPage } from "./components/GeneralErrorPage";
import { ITelemetryItem, PageView } from "@microsoft/applicationinsights-web";

export const adjustTelemetry = (item: ITelemetryItem) => {
    // Update AppInsight's page event name to remove PII
    if (item.name === PageView.envelopeType) {
        if (item.baseData) {
            const pageTitle = item.baseData.name;
            // parse title by "|" and keep last 2 parts since first part may contain PII
            const titleParts = pageTitle.split("|");
            if (titleParts.length > 2) {
                const lastTwoParts = titleParts.slice(-2);
                //set last two parts as the new page name as string
                item.baseData.name = lastTwoParts.join("|").trim();
            }
        }
    }
};

function App() {
    const { isError, data: appConfig } = useAppConfig();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const reactPlugin = useMemo(() => {
        if (appConfig?.appInsightsConnectionString) {
            return initializeLogging(
                appConfig?.appInsightsConnectionString,
                "ncc",
                LIB_VERSION,
                adjustTelemetry,
                true
            );
        }
    }, [appConfig]);

    let authType = HapAuthType.auth0;

    const authConfig = useMemo(() => {
        let authConfig: HapAuthConfig = { clientId: "", productGuid: "", nmsCallerId: "" };
        if (appConfig && (appConfig.auth0Config || appConfig.aadConfig || appConfig.ADFSConfig)) {
            const connection = getAndUpdateAuthConnection(document.location.href);
            if (
                appConfig.aadConfig &&
                (connection.toLowerCase() === "entraid" ||
                    connection.toLowerCase().startsWith("entraid/"))
            ) {
                authType = HapAuthType.entraid;
                authConfig = { ...appConfig.aadConfig, connection };
                if (connection.toLowerCase().startsWith("entraid/")) {
                    authConfig.tenant = connection.substring(8); //get everything after "entraid/"
                }
            } else if (appConfig.ADFSConfig && connection.toLowerCase() === "adfs") {
                authType = HapAuthType.ADFS;
                authConfig = { ...appConfig.ADFSConfig, connection };
            } else if (appConfig.auth0Config) {
                authConfig = { ...appConfig.auth0Config, connection };
                authConfig.onRedirectCallback = (url: string) => {
                    navigate(url);
                };
            }

            const defaultOptions: ISvcOptions = {
                headers: {
                    NMSProduct: `${authConfig.productGuid} NCCv2`
                }
            };
            BaseSvc.setSvcDefaultOptions(defaultOptions);
            return authConfig;
        }
    }, [appConfig, location]);

    if (isError) {
        return <div>Error loading configuration</div>;
    }

    if (!reactPlugin || !appConfig) {
        return (
            <>
                <NCCTitleBar />
                <Spinner label={t("Progress.Spinner_Loading")} style={{ height: "80%" }} />
            </>
        );
    }

    if (!authConfig?.connection) {
        return <AuthConnectionPage />;
    }

    return (
        <AppInsightsErrorBoundary appInsights={reactPlugin} onError={() => <GeneralErrorPage />}>
            <Routes>
                <Route path="/logout" element={<Logout />} />
                <Route
                    path="*"
                    element={
                        <HapAuthProvider config={authConfig} type={authType}>
                            <ThemeProvider style={{ height: "100%" }} /*theme={appTheme}*/>
                                <Main appConfig={appConfig} />
                            </ThemeProvider>
                        </HapAuthProvider>
                    }
                />
            </Routes>
        </AppInsightsErrorBoundary>
    );
}

export default App;
