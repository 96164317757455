/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import React, { useEffect } from "react";
import {
    DialogType,
    Panel,
    PanelType,
    MessageBar,
    MessageBarType,
    Stack,
    Separator,
    Spinner,
    SpinnerSize,
    PrimaryButton,
    DefaultButton,
    Dialog,
    DialogFooter,
    IDropdownOption,
    Text,
    getTheme,
    mergeStyles,
    IChoiceGroupOption,
    Overlay,
    IRenderFunction,
    IPanelProps,
    MessageBarButton
} from "@fluentui/react";
import {
    HuxTextBox,
    HuxDropdown,
    IHuxMessageProps,
    HuxSpinButton,
    postMessage,
    HuxCheckbox,
    HuxToggle,
    HuxChoiceGroup,
    IHuxConfirmDialogProps,
    HuxConfirmDialog
} from "@nuance/hux-components";
import { getLogger } from "@nuance/hux-diagnostics";
import { FormikProvider, FormikValues, getIn, useFormik } from "formik";
import { useState } from "react";
import { panelStyles, separatorStyles } from "../CommonStyles";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { InfoItem } from "./InfoItem";
import { RequiredAsteriskKey } from "./RequiredAsteriskKey";

export enum FieldTypes {
    Text,
    Number,
    DropDown,
    Radio,
    Checkbox,
    Separator,
    Link,
    Custom,
    Toggle
}

export enum DisplayType {
    STANDARD = 1,
    READONLY = 2,
    DISABLED = 3
}

/** Field properties */
export interface Field<TFormData extends FormikValues> {
    /** the field name */
    id: string;
    /** the label to display with the field */
    label?: string;
    /** tooltip text */
    tooltip?: string;
    /** the list of items to display if it's a dropdown or radio group */
    options?: IDropdownOption[] | IChoiceGroupOption[];
    /** Type of field */
    type: FieldTypes;
    /** enum to specify how field should be displayed. Standard for regular input, readonly for text without textbox, disabled for greyed out text box. */
    displayType?: DisplayType;
    /** true if this field is required; false by default */
    required?: boolean;
    /** optional function for custom rendering a field */
    render?: () => JSX.Element;
    /** current value for this field */
    value?: string | number | boolean;
    /** onClick handler for link fields */
    onClick?: () => void;
    /** desired input type i.e. "number" */
    inputType?: string;
    /** optional callback for whether to hide the field */
    shouldHide?: (data: TFormData) => boolean;
    /** optional on text for toggle fields */
    onText?: string;
    /** optional off text for toggle fields */
    offText?: string;
}
/**
 * Type for the confirmation dialog props specifically for the form panel.
 * We can omit "action" as the save action is passed in as a FormPanelProp.
 * We omit "hidden" and "closeDialog" as the visibility of the dialog will be controlled by the formPanel.
 */
export type IFormPanelConfirmDialogProps = Omit<
    IHuxConfirmDialogProps,
    "action" | "hidden" | "closeDialog"
>;

/**
 * Form panel properties
 */
export interface IFormPanelProps<TFormData extends FormikValues, TSaveActionReturn> {
    /** function that handles saving the changes (ex: calling update on Org API) */
    saveAction: (data: TFormData) => Promise<TSaveActionReturn>;
    /** success handler to be called when save operation succeeds. The FormPanel component handles closing the panel.  This callback is for anything extra */
    onSuccess?: (data?: TSaveActionReturn, variables?: TFormData) => void;
    /** optional error callback */
    onError?: (e: Error, defaultHandler: (message?: string) => void) => void;
    /** callback function when panel is closed */
    onDismiss: () => void;
    /** Error message for when data needed for the panel fails to be fetched */
    errorOnLoadingMessage?: string;
    /** by default success message will be show, this allows users to turn it off */
    showSuccessMessage?: boolean;
    /** Optional custom message for when the API call made by the panel is successful. Defaults to "Your changes have been saved." */
    onSuccessMessage?: string;
    /** Optional props for action button to be shown on message bar that is shown on successful call made by the panel.  */
    onSuccessAction?: IFormPanelOnSuccessActionProps;
    /** Optional custom message string, type, timeout */
    getMessage?: () => IHuxMessageProps;
    /** Optional string to populate on save button of panel. Defaults to "Save changes"  */
    saveButtonText?: string;
    /** Optional string to display after primary button clicked. Defaults to "Saving" */
    inProgressText?: string;
    /** initial values for each field */
    initialValues: TFormData;
    /** Formik validation rules for each field, to disable save button and show errors */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validationSchema: any;
    /** The text to display at the top of the panel */
    header: string;
    /** fields to show in the form panel */
    fields: Field<TFormData>[];
    /** width of formPanel, ex: "505px" */
    customWidth?: string;
    /** If the panel should be in a loading state */
    isLoading?: boolean;
    /** If the formik should re initialize if there is change in initial values  */
    enableReinitialize?: boolean;
    /**
     * Whether the required key should be included.
     * Required key will be included by default if the form panel has required
     * text fields.
     */
    includeRequiredAsteriskKey?: boolean;
    /**
     * Props for an optional confirmation dialog to show on click of save button
     */
    confirmDialogProps?:
        | IFormPanelConfirmDialogProps
        | ((variables: TFormData) => IFormPanelConfirmDialogProps | undefined);
    /** If the footer should render in readOnly mode */
    readOnlyFooter?: boolean;
}

/**
 * Properties for action on success of save action on message bar.
 */
export interface IFormPanelOnSuccessActionProps {
    /**
     * Text for action button.
     */
    text: string;
    /**
     * On click handler for action button
     */
    onClick: () => void;
}

/**
 * Form panel to add/edit field using Formik library
 * @param props Form panel properties
 */
export const FormPanel = <TFormData extends FormikValues, TSaveActionResult>(
    props: IFormPanelProps<TFormData, TSaveActionResult>
) => {
    const { t } = useTranslation();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [isConfirmationDialogVisible, { toggle: toggleConfirmationDialogVisible }] =
        useBoolean(false);
    const [errorMsg, setErrorMsg] = useState<string | undefined>();
    const errorMessageId = useId("errorMessage");
    const formId = useId("form");
    const logger = getLogger();
    const theme = getTheme();

    const { mutate, isPending: isMutationLoading } = useMutation({
        mutationFn: async (data: TFormData) => {
            setErrorMsg(undefined);
            return props.saveAction(data);
        },
        onSuccess: (data, variables) => {
            if (props.onSuccess) {
                props.onSuccess(data, variables);
            }
            props.onDismiss();
            if (props.showSuccessMessage !== false) {
                let msgProps: IHuxMessageProps = {
                    message: props.onSuccessMessage ?? t("Messages.Success.Changes_Saved"),
                    timeout: 5,
                    messageBarType: MessageBarType.success,
                    "aria-live": "assertive",
                    actions: props.onSuccessAction ? (
                        <MessageBarButton
                            ariaLabel={props.onSuccessAction.text}
                            onClick={() => {
                                props.onSuccessAction?.onClick();
                            }}
                        >
                            {props.onSuccessAction.text}
                        </MessageBarButton>
                    ) : undefined,
                    isMultiline: !props.onSuccessAction
                };

                // consumers can override the message props, for more complex scenarios like partial success
                if (props.getMessage) {
                    msgProps = props.getMessage();
                }
                postMessage(msgProps);
            }
        },
        /**
         * @description The error handler for the mutation of the panel.
         * If onError is passed in from the consumer, it will be called and
         * passed a reference of the error and default handler.
         * @param e - The Error object returned from the ApiRequest module.
         */
        onError: (e: Error) => {
            logger.logError(e.message);
            function defaultHandler(message: string = t("Messages.Error.Changes_Not_Saved")) {
                setErrorMsg(message);
                const elem = document.getElementsByClassName("ms-Panel-scrollableContent");
                elem[0].scrollTo({ top: 0, behavior: "smooth" });
            }
            if (props.onError) {
                props.onError(e, defaultHandler);
            } else {
                defaultHandler();
            }
        }
    });
    const { initialValues, validationSchema, header, fields: allFields } = props;
    let hasRequiredTextFields = false;

    const formik = useFormik({
        initialValues,
        enableReinitialize: props.enableReinitialize,
        validationSchema,
        onSubmit: async values => {
            mutate(values);
        }
    });
    /**ValidateForm is added so that the form is validated on first
     * render and not just on field change */
    useEffect(() => {
        if (formik) {
            formik.validateForm();
        }
    }, []);

    // filter props based on shouldHide
    const fields: Field<TFormData>[] = [];
    allFields.forEach(field => {
        if (!field.shouldHide || !field.shouldHide(formik.values)) {
            fields.push(field);
            if (
                field.required &&
                field.type === FieldTypes.Text &&
                field.displayType != DisplayType.READONLY
            )
                hasRequiredTextFields = true;
        }
    });

    const dialogContentProps = {
        type: DialogType.normal,
        title: t("Dialog.Unsaved_Changes.Title"),
        subText: t("Dialog.Unsaved_Changes.Text")
    };

    const modalProps = {
        isBlocking: true
    };

    // generates className
    const formStyles = mergeStyles({
        display: "flex",
        flexDirection: "column",
        "> .ms-Stack": { marginBottom: 16 } // spacing between fields
    });

    const disabledStyle = { backgroundColor: theme.palette.neutralLight };

    const getErrorMessage = (name: string) => {
        const error = getIn(formik.errors, name);
        return error;
    };
    /**
     * @description Change handler for a dropdown or radio group field.
     * @param name {string} - The name of the formik field to edit.
     * @param item - The dropdown option or radio button that has been selected.
     * @returns {void}
     */
    const onChange = (name: string, item?: IDropdownOption | IChoiceGroupOption) => {
        formik.setFieldValue(name, item?.key);
    };

    /**
     * Footer to be rendered when the mutation is not loading
     * @param handleSubmit formik submit function
     * @returns JSX.Element
     */
    const footerNotLoading = (handleSubmit: () => void) => {
        const primaryButtonProps = {
            disabled: Object.keys(formik.errors).length > 0 || !formik.dirty,
            type: props.confirmDialogProps ? undefined : "submit",
            form: props.confirmDialogProps ? undefined : formId,
            style: { marginRight: "16px" },
            onClick: handleSubmit
        };
        return (
            <div>
                <PrimaryButton {...primaryButtonProps}>
                    {props.saveButtonText ?? t("Form.SaveChanges_Button")}
                </PrimaryButton>
                <DefaultButton
                    onClick={() => {
                        formik.dirty ? toggleHideDialog() : props.onDismiss();
                    }}
                >
                    {t("Form.Cancel_Button")}
                </DefaultButton>
            </div>
        );
    };

    /**
     * Footer to be rendered when the mutation is loading
     * @returns JSX.Element
     */
    const footerLoading: IRenderFunction<IPanelProps> = () => {
        const label = props.inProgressText ?? t("Progress.Spinner_Saving");
        return (
            <Spinner
                size={SpinnerSize.large}
                styles={{ root: { justifyContent: "left" } }}
                ariaLive={"assertive"}
                ariaLabel={label}
                label={label}
                labelPosition="right"
            ></Spinner>
        );
    };

    const footerReadOnly: IRenderFunction<IPanelProps> = () => {
        return (
            <DefaultButton
                onClick={() => {
                    props.onDismiss();
                }}
            >
                {t("Form.Done_Button")}
            </DefaultButton>
        );
    };

    const [confirmDialogProps, setConfirmDialogProps] = useState<
        IFormPanelConfirmDialogProps | undefined
    >();

    useEffect(() => {
        if (props.confirmDialogProps && typeof props.confirmDialogProps === "function") {
            setConfirmDialogProps(props.confirmDialogProps(formik.values));
        } else {
            setConfirmDialogProps(props.confirmDialogProps);
        }
    }, [props.confirmDialogProps, formik.values]);

    return (
        <>
            <Panel
                styles={panelStyles}
                isOpen={true}
                onDismiss={() => (formik.dirty ? toggleHideDialog() : props.onDismiss())}
                headerText={header}
                closeButtonAriaLabel={t("Action.Close_Button")}
                type={PanelType.custom}
                customWidth={props.customWidth ?? "505px"}
                onRenderFooterContent={() => {
                    if (props.readOnlyFooter) {
                        return footerReadOnly();
                    } else
                        return isMutationLoading
                            ? footerLoading()
                            : footerNotLoading(
                                  confirmDialogProps
                                      ? toggleConfirmationDialogVisible
                                      : formik.handleSubmit
                              );
                }}
                isFooterAtBottom={true}
                isLightDismiss={true}
            >
                <>
                    {props.isLoading && (
                        <Spinner
                            size={SpinnerSize.large}
                            ariaLive="assertive"
                            styles={{
                                root: {
                                    position: "relative",
                                    top: "50%"
                                }
                            }}
                            ariaLabel={t("Progress.Spinner_Loading")}
                            label={t("Progress.Spinner_Loading")}
                        />
                    )}
                    {!props.isLoading && (
                        <>
                            {errorMsg || props.errorOnLoadingMessage ? (
                                <MessageBar
                                    id={errorMessageId}
                                    messageBarType={MessageBarType.error}
                                >
                                    {props.errorOnLoadingMessage
                                        ? props.errorOnLoadingMessage
                                        : errorMsg}
                                </MessageBar>
                            ) : (
                                <></>
                            )}
                            {(hasRequiredTextFields || props.includeRequiredAsteriskKey) && (
                                <RequiredAsteriskKey />
                            )}
                            <form id={formId}>
                                <FormikProvider value={formik}>
                                    <div className={formStyles}>
                                        {fields.map((field, index) => {
                                            if (!field.displayType) {
                                                field.displayType = DisplayType.STANDARD;
                                            }
                                            const {
                                                type,
                                                id,
                                                label,
                                                required,
                                                displayType,
                                                inputType,
                                                tooltip
                                            } = field;
                                            if (
                                                type === FieldTypes.Text &&
                                                displayType != DisplayType.READONLY
                                            ) {
                                                return (
                                                    <HuxTextBox
                                                        key={index}
                                                        label={label}
                                                        infoText={tooltip}
                                                        value={formik.values[id]}
                                                        name={id}
                                                        onChange={(e, newVal) => {
                                                            if (
                                                                newVal !== "" &&
                                                                getIn(formik.touched, id) ===
                                                                    undefined
                                                            ) {
                                                                formik.setFieldTouched(id, true);
                                                            }
                                                            formik.handleChange(e);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        errorMessage={
                                                            getIn(formik.touched, id)
                                                                ? getErrorMessage(id)
                                                                : undefined
                                                        }
                                                        required={required}
                                                        readOnly={
                                                            displayType === DisplayType.DISABLED
                                                        }
                                                        style={
                                                            displayType === DisplayType.DISABLED
                                                                ? disabledStyle
                                                                : {}
                                                        }
                                                        type={inputType}
                                                    ></HuxTextBox>
                                                );
                                            } else if (
                                                type === FieldTypes.Text &&
                                                displayType === DisplayType.READONLY
                                            ) {
                                                return (
                                                    <InfoItem
                                                        name={label ?? ""}
                                                        value={formik.values[id]}
                                                        key={index}
                                                    ></InfoItem>
                                                );
                                            } else if (type === FieldTypes.Number) {
                                                return (
                                                    <HuxSpinButton
                                                        key={index}
                                                        title={tooltip}
                                                        label={label}
                                                        value={formik.values[id]}
                                                        onChange={formik.handleChange}
                                                    ></HuxSpinButton>
                                                );
                                            } else if (type === FieldTypes.Radio) {
                                                return (
                                                    <Stack key={index}>
                                                        <HuxChoiceGroup
                                                            label={label}
                                                            infoText={tooltip}
                                                            defaultSelectedKey={formik.values[id]}
                                                            options={
                                                                (field.options as IChoiceGroupOption[]) ??
                                                                []
                                                            }
                                                            onChange={(_, item) =>
                                                                onChange(id, item)
                                                            }
                                                        ></HuxChoiceGroup>
                                                    </Stack>
                                                );
                                            } else if (type === FieldTypes.DropDown) {
                                                return (
                                                    <Stack key={index}>
                                                        <HuxDropdown
                                                            label={label}
                                                            infoText={tooltip}
                                                            defaultSelectedKey={formik.values[id]}
                                                            options={
                                                                (field.options as IDropdownOption[]) ??
                                                                []
                                                            }
                                                            onChange={(_, item) =>
                                                                onChange(id, item)
                                                            }
                                                            calloutProps={{ calloutMaxHeight: 400 }}
                                                        ></HuxDropdown>
                                                    </Stack>
                                                );
                                            } else if (type === FieldTypes.Checkbox) {
                                                return (
                                                    <Stack key={index}>
                                                        <HuxCheckbox
                                                            label={label}
                                                            infoText={tooltip}
                                                            defaultChecked={formik.values[id]}
                                                            onChange={(_, checked) =>
                                                                formik.setFieldValue(id, checked)
                                                            }
                                                        ></HuxCheckbox>
                                                    </Stack>
                                                );
                                            } else if (type === FieldTypes.Separator) {
                                                return (
                                                    <Separator
                                                        key={index}
                                                        alignContent="start"
                                                        styles={separatorStyles}
                                                    >
                                                        <Text>{label}</Text>
                                                    </Separator>
                                                );
                                            } else if (type === FieldTypes.Toggle) {
                                                return (
                                                    <HuxToggle
                                                        key={index}
                                                        label={label}
                                                        title={tooltip}
                                                        checked={formik.values[id]}
                                                        onText={field.onText}
                                                        offText={field.offText}
                                                        onChange={(e, checked) => {
                                                            formik.handleChange;
                                                            formik.setFieldValue(id, checked);
                                                        }}
                                                    />
                                                );
                                            } else if (type === FieldTypes.Custom && field.render) {
                                                return <div key={index}>{field.render()}</div>;
                                            }
                                        })}
                                        {/* disables editing fields while saving */}
                                        {isMutationLoading && <Overlay />}
                                    </div>
                                </FormikProvider>
                            </form>
                            <Dialog
                                hidden={hideDialog}
                                onDismiss={toggleHideDialog}
                                dialogContentProps={dialogContentProps}
                                modalProps={modalProps}
                            >
                                <DialogFooter>
                                    <PrimaryButton
                                        onClick={() => {
                                            toggleHideDialog;
                                            props.onDismiss();
                                        }}
                                        text={t("Dialog.Unsaved_Changes.YesLeave_Button")}
                                    />
                                    <DefaultButton
                                        onClick={toggleHideDialog}
                                        text={t("Dialog.Unsaved_Changes.NoStay_Button")}
                                    />
                                </DialogFooter>
                            </Dialog>
                            {confirmDialogProps && (
                                <HuxConfirmDialog
                                    {...confirmDialogProps}
                                    hidden={!isConfirmationDialogVisible}
                                    action={{
                                        onAction: async () => {
                                            await formik.handleSubmit();
                                            return undefined;
                                        }
                                    }}
                                    closeDialog={toggleConfirmationDialogVisible}
                                />
                            )}
                        </>
                    )}
                </>
            </Panel>
        </>
    );
};
