/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */

import { GuardAgainst } from "@nuance/hux-diagnostics";
import Hashids from "hashids";
import { AppConfig, getAppConfig } from "./AppConfigSvc";
export class HashSvc {
    /**
     * Instance of Hash Service
     */
    static instance: HashSvc;
    /**
     * Instance of Hash Ids
     */
    private hashIds: Hashids;

    /**
     * Constructor
     */
    private constructor() {
        let salt;
        getAppConfig().then((resp: AppConfig) => {
            salt = resp.salt;
        });
        this.hashIds = new Hashids(salt, 12, "abcdefgjhijklmnonqrstvuwxyz");
    }
    /**
     * Return the static instance of Hash Service. Create if not exists.
     */
    public static getInstance(): HashSvc {
        if (!HashSvc.instance) {
            HashSvc.instance = new HashSvc();
        }
        return HashSvc.instance;
    }
    /**
     * Hash the input number
     * @param inputId The number to hash
     * @returns Hashed the string
     */
    HashEncode = (inputId: number): string => {
        GuardAgainst.NegativeOrZero(inputId, "Input Id is zero or negative");
        return this.hashIds.encode(inputId);
    };

    /**
     * Decode the hash string
     * @param hashedId The hashed string
     * @returns decoded number
     */
    HashDecode = (hashedId: string): number => {
        GuardAgainst.Falsy(hashedId, "Hashed Id is empty");
        const decodedValues = this.hashIds.decode(hashedId) as number[];
        return decodedValues[0];
    };
}
