/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import { HuxFeatureSet } from "@nuance/hux-components";

export const NccFeatures = {
    Analytics: "Analytics",
    Organization: "Organization",
    OrganizationAccess: "OrganizationAccess",
    Site: "Site",
    User: "User",
    Group: "Group",
    ProductSettings: "ProductSettings",
    Group_Grants: "Group_Grants",
    Group_UserEnrollment: "Group_UserEnrollment",
    OrgGroup: "OrgGroup",
    LicenseDetails: "LicenseDetails",
    ImportLicenseKey: "ImportLicenseKey",
    Provisioning: "Provisioning",
    Help: "Help",
    AutoTextView: "AutoTextView",
    SpeechRecognition: "SpeechRecognition",
    SupportTools: "SupportTools",
    UserSpeechRec: "UserSpeechRec",
    ManageKeywords: "ManageKeywords",
    UserContactInformation: "UserContactInformation",
    EntraIdLogin: "EntraIdLogin",
    OrganizationEHR: "OrganizationEHR",
    OrganizationEntraIdTenants: "OrganizationEntraIdTenants",
    AddServicePrincipal: "AddServicePrincipal",
    UserEHR: "UserEHR",
    EncounterFeedback: "EncounterFeedback",
    UserImport: "UserImport",
    VocabularyView: "VocabularyView",
    UserImportEnhancements: "UserImportEnhancements",
    ManageUserEntraId: "ManageUserEntraId"
};

type Values<T> = T[keyof T];

type NccFeatures = Values<typeof NccFeatures>;

export const NccFeatureMap: Record<NccFeatures, HuxFeatureSet> = {
    Analytics: HuxFeatureSet.GeneralUser,
    Organization: HuxFeatureSet.EarlyAdopter,
    Site: HuxFeatureSet.EarlyAdopter,
    User: HuxFeatureSet.EarlyAdopter,
    Group: HuxFeatureSet.EarlyAdopter,
    ProductSettings: HuxFeatureSet.EarlyAdopter,
    Group_Grants: HuxFeatureSet.EarlyAdopter,
    Group_UserEnrollment: HuxFeatureSet.EarlyAdopter,
    OrgGroup: HuxFeatureSet.BetaTester,
    LicenseDetails: HuxFeatureSet.EarlyAdopter,
    ImportLicenseKey: HuxFeatureSet.EarlyAdopter,
    Provisioning: HuxFeatureSet.EarlyAdopter,
    Help: HuxFeatureSet.EarlyAdopter,
    AutoTextView: HuxFeatureSet.EarlyAdopter,
    SpeechRecognition: HuxFeatureSet.EarlyAdopter,
    OrganizationAccess: HuxFeatureSet.EarlyAdopter,
    SupportTools: HuxFeatureSet.BetaTester,
    UserSpeechRec: HuxFeatureSet.EarlyAdopter,
    ManageKeywords: HuxFeatureSet.EarlyAdopter,
    UserContactInformation: HuxFeatureSet.EarlyAdopter,
    OrganizationEHR: HuxFeatureSet.EarlyAdopter,
    OrganizationEntraIdTenants: HuxFeatureSet.BetaTester,
    AddServicePrincipal: HuxFeatureSet.BetaTester,
    UserEHR: HuxFeatureSet.EarlyAdopter,
    EncounterFeedback: HuxFeatureSet.Developer, // Used by DAX Copilot support tool.
    UserImport: HuxFeatureSet.EarlyAdopter,
    VocabularyView: HuxFeatureSet.EarlyAdopter,
    UserImportEnhancements: HuxFeatureSet.EarlyAdopter,
    ManageUserEntraId: HuxFeatureSet.BetaTester
};
