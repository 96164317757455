/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    useApiRequest,
    useHapAuth,
    UserManagementSvc,
    IGetGroupsSearchParameters,
    IRole
} from "@nuance/hap-components";
import { SelectionMode, MessageBarType, IColumn } from "@fluentui/react";
import {
    HuxDetailsList,
    IHuxDetailsListItem,
    HuxSimplePagination,
    IHuxMessageProps,
    postMessage,
    INoItemsAvailableProps,
    NoItemsType
} from "@nuance/hux-components";
import { useTranslation } from "react-i18next";
import { getLogger } from "@nuance/hux-diagnostics";
import { EventTypes } from "../EventTypes";
import { HashSvc } from "../services/HashSvc";
import { getRoleTypeTranslationText } from "../common/getRoleTypeTranslationText";
import { namespaces } from "../common/translationNamespaces";

const logger = getLogger();

/**
 * Props for GroupListComponent
 */
export interface IGroupListComponentProps {
    /** Parameters controlling the search results */
    groupRequestParameters?: IGetGroupsSearchParameters;
    /** What parameters are desired in the return data */
    include?: string[];
    /** Callback for parent component to know when loading has finished */
    onSearchComplete?: () => void;
    /** Label for pagination footer and list ariaGridLabel */
    label: string;
    /** list of roles for returned groups */
    roles: IRole[] | undefined;
    /** what to display when there are no items on the list */
    emptyStateProps?: INoItemsAvailableProps;
    /** Callback for parent component to know when the skip value has changed */
    updateSkip?: (skip: number) => void;
}

/**
 * Component for displaying a list of groups based on desired search parameters
 *
 * @param props IGroupListComponentProps
 * @returns JSX.Element
 */
export const GroupListComponent = (props: IGroupListComponentProps) => {
    const { userContext } = useHapAuth();
    const navigate = useNavigate();
    const { t } = useTranslation(["NCC", "HuxComponents"]);
    const hashService = HashSvc.getInstance();

    /** Create state variable for skip value from props */
    const [skip, setSkip] = useState(props.groupRequestParameters?.skip ?? 0);
    /** Create variable for take from props */
    const take = props.groupRequestParameters?.take ?? 100;

    const userManagementSvc = new UserManagementSvc(userContext);

    /** API call to get Groups based on the provided parameters */
    const {
        isInitialLoading,
        isFetching,
        error,
        data: groups
    } = useApiRequest(
        userManagementSvc.getGroups(
            {
                ...props.groupRequestParameters,
                skip: skip,
                take: take
            },
            props.include ? props.include : []
        ),
        {
            enabled: props.groupRequestParameters !== undefined
        }
    );

    /** useEffect for handling error message and isLoading state to parent */
    useEffect(() => {
        if (error) {
            const msgProps: IHuxMessageProps = {
                message: t("Error.Unable_to_Load"),
                messageBarType: MessageBarType.error
            };
            postMessage(msgProps);
        }

        if (!isInitialLoading && props.onSearchComplete) {
            props.onSearchComplete();
        }
    }, [error, isInitialLoading]);

    useEffect(() => {
        if (props.groupRequestParameters?.skip !== undefined)
            setSkip(props.groupRequestParameters.skip);
    }, [props.groupRequestParameters]);

    /** Mapping returned data to fit into details list */
    const items = groups?.map((group, index) => {
        const hashedOrgUID = hashService.HashEncode(group.OrganizationUID);
        const roleTypeTranslation = getRoleTypeTranslationText(group.RoleType);
        return {
            id: group.Name,
            index: index,
            siteName: group.SiteName,
            orgName: group.OrganizationName,
            roleType: roleTypeTranslation ? t(roleTypeTranslation) : "",
            href: `/orgs/${hashedOrgUID}/groups/${hashService.HashEncode(group.UID)}/overview`
        };
    });

    /** Columns to be shown in the details list */
    const columns: IColumn[] = [
        {
            key: "nameCol",
            name: t("Groups.Search.Name_Head"),
            fieldName: "id",
            minWidth: 200,
            maxWidth: 500,
            isResizable: true
        },
        {
            key: "roleTypeCol",
            name: t("Groups.Search.Role_Head"),
            fieldName: "roleType",
            minWidth: 200,
            isResizable: true
        },
        {
            key: "siteNameCol",
            name: t("Groups.Search.Site_Name_Head"),
            fieldName: "siteName",
            minWidth: 200,
            isResizable: true
        }
    ];

    if (userContext.multiOrg) {
        columns.push({
            key: "orgNameCol",
            name: t("Groups.Search.Organization_Head"),
            fieldName: "orgName",
            minWidth: 200,
            isResizable: true
        });
    }

    /** Actions that take place when a user clicks on a Group link */
    const onGroupLinkClick = (
        ev?: React.MouseEvent<HTMLElement> | Event,
        item?: IHuxDetailsListItem
    ) => {
        ev?.preventDefault();
        if (item?.href) {
            logger.trackEvent(EventTypes.GroupSearchLinkClicked, {
                groupName: item.id,
                orgName: item.orgName,
                siteName: item.siteName
            });
            navigate(item.href as string);
        }
    };

    const emptySearchMsg = {
        type: NoItemsType.NoResults,
        heading: t("Search.Error.No_Results_Heading"),
        subHeading: t("Search.Error.No_Results_Text")
    };

    const startSearchMsg = {
        type: NoItemsType.StartSearch,
        heading: t("Search.Start_Search_Heading")
    };

    const emptyStateSearchProps = props.groupRequestParameters ? emptySearchMsg : startSearchMsg;

    return (
        <>
            {items?.length === 0 && (
                <div aria-live="assertive" className="screenReaderOnly">
                    {t(`${namespaces.HuxComponents}:NoItemsAvailable.No_Results_Heading`)}
                </div>
            )}
            {!error && (
                <>
                    <HuxDetailsList
                        id={"usersTable"}
                        isLoading={isInitialLoading}
                        columns={columns}
                        items={items ?? []}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => {
                            // true by default, but turn off for tests, otherwise only renders 10 rows
                            return process.env.NODE_ENV !== "test";
                        }}
                        ariaLabelForGrid={props.label}
                        onLinkClick={onGroupLinkClick}
                        emptyStateProps={props.emptyStateProps ?? emptyStateSearchProps}
                    ></HuxDetailsList>
                    <HuxSimplePagination
                        isLoading={isFetching}
                        prefixLabel={props.label}
                        startingIndex={skip}
                        itemCount={items?.length ?? 0}
                        isFirstPage={skip === 0}
                        isLastPage={items && items.length < take ? true : false}
                        onNextPageClick={() => {
                            setSkip(skip + take);
                            if (props.updateSkip) props.updateSkip(skip + take);
                        }}
                        onPreviousPageClick={() => {
                            setSkip(skip - take);
                            if (props.updateSkip) props.updateSkip(skip - take);
                        }}
                    ></HuxSimplePagination>
                </>
            )}
        </>
    );
};
