/**
 * @copyright 2022 Nuance Communications Inc.
 * All Rights Reserved.
 */
import React from "react";
import {
    HuxSection,
    HuxInfoItemList,
    HuxField,
    HuxFieldType,
    HuxControlLabel
} from "@nuance/hux-components";
import { useTranslation } from "react-i18next";
import { IOrganization, IOrgGroup } from "@nuance/hap-components";
import { EventTypes } from "../EventTypes";
import { useBoolean } from "@fluentui/react-hooks";
import { EditOrgGroupBasicInfoPanel } from "./EditOrgGroupBasicInfoPanel";
import { OrgNameWithIcon } from "../components/name_with_icon/OrgNameWithIcon";

/**
 * Interface for OrgGroupBasicInfoProps
 */
export interface IOrgGroupBasicInfoProps {
    orgGroup: IOrgGroup;
    org: IOrganization;
}

/**
 *
 * @param props IOrgGroupBasicInfoProps
 * @returns JSX.Element
 */
export const OrgGroupBasicInfo = (props: IOrgGroupBasicInfoProps) => {
    const { t } = useTranslation();

    const [isPanelOpen, { toggle: toggleIsPanelOpen }] = useBoolean(false);

    /** OrgGroup basic info fields */
    const fields: HuxField[] = [
        {
            label: t("Groups.BasicInfo.Organization.Label"),
            type: HuxFieldType.Custom,
            render: () => (
                <>
                    <HuxControlLabel labelText={t("Groups.BasicInfo.Organization.Label")} />
                    <OrgNameWithIcon
                        orgInfo={props.org}
                        trackEventInfo={{
                            eventType: EventTypes.OrgGroupBasicInfoOrgLinkClicked,
                            customProperties: { groupName: props.orgGroup.OrgGroupName }
                        }}
                    />
                </>
            )
        }
    ];
    return (
        <HuxSection
            onEdit={props.orgGroup.CanModify ? toggleIsPanelOpen : undefined}
            heading={t("Groups.BasicInfo.Basic_Info_Title")}
            editButtonAriaLabel={t("Action.Edit_Button")}
        >
            <HuxInfoItemList fields={fields} header={{ label: props.orgGroup.OrgGroupName }} />
            {isPanelOpen && (
                <EditOrgGroupBasicInfoPanel
                    orgGroup={props.orgGroup}
                    onDismiss={() => {
                        toggleIsPanelOpen();
                    }}
                />
            )}
        </HuxSection>
    );
};
