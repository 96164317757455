/**
 * @copyright 2023 Nuance Communications Inc.
 * All Rights Reserved.
 */

import { IColumn, SelectionMode } from "@fluentui/react";
import { HuxDetailsList, IHuxDetailsListActionsProps, NoItemsType } from "@nuance/hux-components";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBoolean } from "@fluentui/react-hooks";
import { getRoleTypeTranslationText } from "../common/getRoleTypeTranslationText";
import {
    ISelectGroupsPanelProps,
    SelectGroupsPanel,
    SelectedGroup
} from "../components/SelectGroupsPanel";
import * as Yup from "yup";
import { getLogger } from "@nuance/hux-diagnostics";

const logger = getLogger();

/**
 * The form data for Groups form control.
 */
export interface IGroupsListFormData {
    /**
     * The selected groups.
     */
    Groups: SelectedGroup[];
}

/**
 * The properties for Groups form entry component
 */
export interface IGroupsFormEntryProps {
    /**
     * The UID of the organization for groups.
     */
    organizationUid?: number;
    /**
     * The groups selected by default.
     */
    groups: SelectedGroup[];
    /**
     * The event type for add groups button click.
     */
    addGroupsButtonClickedEventType: string;
    /**
     * The event type for remove groups button click.
     */
    removeGroupsButtonClickedEventType: string;
}

/**
 * @description Groups validation schema used by itself or to concatenate to another validation schema and then initialize formik
 * @returns Groups Validation schema to use with formik
 */
export const getGroupsValidationSchema = () => {
    return Yup.object({
        Groups: Yup.array().required().min(1)
    });
};

/**
 * @description GroupsFormEntry component is form component for groups.
 * @param props {IGroupsFormEntryProps} - The props containing properties for GroupsFormEntry component.
 * @returns The GroupsFormEntry component.
 */
export function GroupsFormEntry(props: IGroupsFormEntryProps): JSX.Element {
    const { t } = useTranslation();
    const [isGroupsPanelOpen, { toggle: toggleIsGroupsPanelOpen }] = useBoolean(false);
    const [organizationUid, setOrganizationUid] = useState<number>();
    const [groups, setGroups] = useState<SelectedGroup[]>(props.groups);
    const [selectedGroupsIndex, setSelectedGroupsIndex] = useState<number[]>([]);

    useEffect(() => {
        if (organizationUid && organizationUid !== props.organizationUid) {
            setGroups([]);
            formik.setFieldValue(formIdGroups, []);
        }
        if (props.organizationUid) {
            setOrganizationUid(props.organizationUid);
        }
    }, [props.organizationUid]);

    useEffect(() => {
        formik.setFieldValue(formIdGroups, props.groups);
    }, [props.groups]);

    const formik = useFormikContext<IGroupsListFormData>();

    if (formik === undefined)
        throw new Error("You must wrap GroupsFormEntry component in <FormikProvider>");

    const formIdGroups: keyof IGroupsListFormData = "Groups";

    const groupColumns: IColumn[] = [
        {
            isRowHeader: true,
            key: "groupName",
            name: t("SelectObject.Group_Name_Head"),
            fieldName: "id",
            minWidth: 150,
            isResizable: true
        },
        {
            key: "roleType",
            name: t("SelectObject.Group_Role_Head"),
            fieldName: "roleType",
            minWidth: 150,
            isResizable: true
        }
    ];

    const selectGroupsPanelProps: ISelectGroupsPanelProps = {
        onDismiss: toggleIsGroupsPanelOpen,
        onAdd: (selectedGroups: SelectedGroup[]) => {
            formik.setFieldValue(formIdGroups, selectedGroups);
            setGroups(selectedGroups);
            toggleIsGroupsPanelOpen();
        },
        selected: groups,
        orgUID: organizationUid ? organizationUid : 0,
        headerText: t("SelectObject.Add_Groups_Title"),
        submitButtonText: t("Form.Add_Button"),
        showOnlyGroupsWhichUserCanEnroll: true
    };

    const groupActions: IHuxDetailsListActionsProps = {
        left: [
            {
                key: "addGroups",
                iconProps: { iconName: "Add" },
                text: t("SelectObject.Add_Groups_Button"),
                ariaLabel: t("SelectObject.Add_Groups_Button"),
                disabled: !organizationUid || selectedGroupsIndex.length > 0,
                onClick: () => {
                    toggleIsGroupsPanelOpen();
                    logger.trackEvent(props.addGroupsButtonClickedEventType);
                },
                role: "button"
            },
            {
                key: "removeGroups",
                iconProps: { iconName: "Cancel" },
                text: t("Form.Remove_Button"),
                ariaLabel: t("Form.Remove_Button"),
                disabled: selectedGroupsIndex.length === 0,
                role: "button",
                onClick: () => {
                    logger.trackEvent(props.removeGroupsButtonClickedEventType);

                    const updatedGroupsList = groups.filter(
                        (s, index) => !selectedGroupsIndex.includes(index)
                    );
                    formik.setFieldValue(formIdGroups, updatedGroupsList);
                    setGroups(updatedGroupsList);
                }
            }
        ]
    };
    const items = groups.map((group, index) => {
        const roleTypeTranslationId = getRoleTypeTranslationText(group.roleType as number);
        const roleType = roleTypeTranslationId ? t(roleTypeTranslationId) : "";
        return {
            id: group.name,
            subtext: group.siteName,
            roleType: roleType,
            index: index,
            key: group.groupUID
        };
    });

    return (
        <>
            <HuxDetailsList
                id={"groupsFormEntryTable"}
                actions={groupActions}
                columns={groupColumns}
                items={items}
                selectionMode={SelectionMode.multiple}
                ariaLabelForGrid={t("Users.Add.Groups_Section")}
                onSelectRow={selectedIndexes => {
                    setSelectedGroupsIndex(selectedIndexes);
                }}
                emptyStateProps={{
                    type: NoItemsType.NoIcon,
                    heading: t("Users.Add.No_Groups_Title"),
                    subHeading: t("Users.Add.No_Groups_Text")
                }}
            ></HuxDetailsList>
            {isGroupsPanelOpen && (
                <SelectGroupsPanel {...selectGroupsPanelProps}></SelectGroupsPanel>
            )}
        </>
    );
}
